const compose = require('../../../../lib/compose');
const connect = require('../../../../store/connect');
const jsonToProps = require('../../../../components/gift-registry/jsonToProps');
const actions = require('../../../../actions/vip');

const WISHLIST = 'wishlist_save_button';
const BOOKMARK = 'bookmark';

const mapStateToProps = state => {
  const {
    components: { [WISHLIST]: wishlist, [BOOKMARK]: bookmark },
    id,
  } = state;

  return jsonToProps({
    id,
    wishlist,
    isBookmarked: bookmark?.is_bookmarked,
  });
};

const mapDispatchToProps = dispatch => ({
  updateGiftRegistry: check => dispatch(actions.fetchUpdatedWishlists(check)),
  removeElementFromList: element => dispatch(actions.removeElementFromList(element)),
  addElementToList: element => dispatch(actions.addElementToList(element)),
  fetchBookmark: () => dispatch(actions.fetchBookmark()),
  toggleGiftRegistryCheckbox: check => dispatch(actions.toggleGiftRegistryCheckbox(check)),
});

const enhance = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

module.exports = GiftRegistry => enhance(GiftRegistry);
