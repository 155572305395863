const compose = require('../../../../lib/compose');
const connect = require('../../../../store/connect');
const withFeatureFlag = require('../../../../components/feature-flag/with-feature-flag');
const actions = require('../../../../actions');

const FEATURE_NAME = 'iframemodal';

const mapStateToProps = ({ components: { [FEATURE_NAME]: mainActions } }) => mainActions;

const mapDispatchToProps = dispatch => ({
  updateIframeModal: params => {
    dispatch(actions.updateIframeModal(params));
  },
  bottomSheetClose: () => dispatch(actions.bottomSheetClose()),
  onCreateQuestionFromAi: ({ itemId, text, snackbar_message, suggestionTrack }) => {
    dispatch(actions.onCreateQuestionFromAi({ itemId, text, snackbar_message, suggestionTrack }));
  },
  showSnackbar: snackbar => dispatch(actions.showSnackbar(snackbar)),
  updateComponentsBottomSheet: () => dispatch(actions.updateComponentsBottomSheet()),
  saveFrontendStatsd: data => dispatch(actions.saveFrontendStatsd(data)),
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

module.exports = IFrameModal => enhance(IFrameModal);
exports = module.exports;
exports.mapDispatchToProps = mapDispatchToProps;
