const compose = require('../../../../lib/compose');
const connect = require('../../../../store/connect');
const withFeatureFlag = require('../../../../components/feature-flag/with-feature-flag');
const withRow = require('../../../../components/grid/row/with-row');

const FEATURE_NAME = 'cpg_summary';

const CPG_PROPS = {
  id: FEATURE_NAME,
};

const mapStateToProps = ({ components: { [FEATURE_NAME]: cpgSummary }, isFetching }, ownProps) => ({
  ...cpgSummary,
  ...ownProps,
  ...CPG_PROPS,
  isFetching,
});

const serializeOwnProps = ({ isFetching }, ownProps) => ({
  ...ownProps,
  ...CPG_PROPS,
  isFetching,
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  withRow(FEATURE_NAME, 'mb-20'),
  connect(mapStateToProps),
);

const noFeatureFlagConnect = connect(serializeOwnProps);

module.exports = CpgSummary => enhance(CpgSummary);
module.exports.noFeatureFlag = CpgSummary => noFeatureFlagConnect(CpgSummary);
