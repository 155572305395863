const compose = require('../../../../../lib/compose');
const connect = require('../../../../../store/connect');
const withFeatureFlag = require('../../../../../components/feature-flag/with-feature-flag');
const actions = require('../../../../../actions');

const FEATURE_NAME = 'available_quantity';

const mapStateToProps = ({ components: { [FEATURE_NAME]: availableQuantity }, buttonFocus }, ownProps) => ({
  ...availableQuantity,
  ...ownProps,
  buttonFocus,
});

const mapDispatchToProps = dispatch => ({
  onSetQuantity: quantity => dispatch(actions.fetchOnSetQuantity(quantity)),
  onShowInput: show => dispatch(actions.onQuantityShowInput(show)),
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

module.exports = Component => enhance(Component);
