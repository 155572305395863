const get = require('lodash/get');
const connect = require('../../../../store/connect');
const { TrackPageVariation } = require('../../../../components/tracking');

const FEATURE_NAME = 'track';

const mapStateToProps = ({ id, components }) => ({
  melidata_event: get(components, `${FEATURE_NAME}.melidata_event`, null),
  analytics_event: get(components, `${FEATURE_NAME}.analytics_event`, null),
  id,
});

module.exports = connect(mapStateToProps)(TrackPageVariation);
