const compose = require('../../../../../lib/compose');
const connect = require('../../../../../store/connect');
const withFeatureFlag = require('../../../../../components/feature-flag/with-feature-flag');
const ShippingSummary = require('../../../../../components/buybox/shipping');
const actions = require('../../../../../actions');

const FEATURE_NAME = 'shipping_summary';

const SHIPPING_PROPS = {
  id: FEATURE_NAME,
};

const mapStateToProps = ({ components: { [FEATURE_NAME]: shippingSummary } }, ownProps) => ({
  ...shippingSummary,
  ...ownProps,
  ...SHIPPING_PROPS,
});

const serializeOwnProps = (state, ownProps) => ({
  ...ownProps,
  ...SHIPPING_PROPS,
});

const mapDispatchToProps = dispatch => ({
  onShippingPromiseUpdate: () => dispatch(actions.fetchShippingPromise()),
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

const noFeatureFlagConnect = connect(
  serializeOwnProps,
  mapDispatchToProps,
);

module.exports = enhance(ShippingSummary);
module.exports.noFeatureFlag = noFeatureFlagConnect(ShippingSummary);
