/**
 * Module dependencies
 */
const React = require('react');
const { StoreConsumer } = require('./context');
const ErrorBoundary = require('./error');
const getDisplayName = require('../lib/get-display-name');

const noop = () => {};

/**
 * connect
 */
const connect = (mapStateToProps = noop, mapDispatchToProps = noop) => WrappedComponent => {
  const ConnectedComponent = ownProps => (
    <StoreConsumer>
      {({ history, state, dispatch }) => (
        <ErrorBoundary>
          <>
            <WrappedComponent
              history={history}
              {...mapStateToProps(state, ownProps)}
              {...mapDispatchToProps(dispatch, ownProps)}
            />
          </>
        </ErrorBoundary>
      )}
    </StoreConsumer>
  );
  ConnectedComponent.displayName = `Connect(${getDisplayName(WrappedComponent)})`;
  return ConnectedComponent;
};

/**
 * Expose connect
 */
module.exports = connect;
