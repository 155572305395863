const componentsIds = {
  QUICK_ACTIONS: 'QUICK_ACTIONS',
  SEARCH_BAR: 'SEARCH_BAR',
  MAKE_QUESTION_MODAL: 'MAKE_QUESTION_MODAL',
  MAKE_QUESTION: 'MAKE_QUESTION',
  MAKE_QUESTION_SA: 'MAKE_QUESTION_SA',
  ADDITIONAL_PARAMETERS: 'ADDITIONAL_PARAMETERS',
  SMART_ANSWER_MODAL: 'SMART_ANSWER_MODAL',
  NO_LAST_QUESTIONS: 'NO_LAST_QUESTIONS',
  RESULTS: 'RESULTS',
  CARD_ANSWER: 'CARD_ANSWER',
  FEEDBACK: 'FEEDBACK',
  OWNER_QUESTIONS: 'OWNER_QUESTIONS',
  QUESTIONS: 'QUESTIONS',
  SMART_ANSWER: 'SMART_ANSWER',
  CALL_TO_ACTION: 'CALL_TO_ACTION',
  PUSH_NOTIFICATION_QUESTION_MODAL: 'PUSH_NOTIFICATION_QUESTION_MODAL',
};

module.exports = { ...componentsIds };
