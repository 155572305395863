const compose = require('../../lib/compose');
const connect = require('../../store/connect');
const withFeatureFlag = require('../feature-flag/with-feature-flag');
const withRow = require('../grid/row/with-row');

const FEATURE_NAME = 'cbt_taxes_fc_us_summary';

const CBT_TAXES_FC_US_PROPS = {
  id: FEATURE_NAME,
};

const mapStateToProps = ({ components: { [FEATURE_NAME]: cbtTaxesFcUsSummary } }, ownProps) => ({
  ...cbtTaxesFcUsSummary,
  ...ownProps,
  ...CBT_TAXES_FC_US_PROPS,
});

const serializeOwnProps = (state, ownProps) => ({
  ...ownProps,
  ...CBT_TAXES_FC_US_PROPS,
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  withRow(FEATURE_NAME),
  connect(mapStateToProps),
);

const noFeatureFlagConnect = connect(serializeOwnProps);

module.exports = CbtTaxesFcUsSummary => enhance(CbtTaxesFcUsSummary);
module.exports.noFeatureFlag = CbtTaxesFcUsSummary => noFeatureFlagConnect(CbtTaxesFcUsSummary);
