const React = require('react');

const { useContext } = React;
const ChevronRight20 = require('@andes/icons/ChevronRight20');
const StaticPropsContext = require('../context/static-props');
const classNames = require('classnames');
const { shape, func, string, bool } = require('prop-types');
const Share = require('../share/share.mobile');
const Bookmark = require('../bookmark/bookmark');
const jsonToProps = require('./jsonToProps');
const { UserConsumer } = require('../context/user');
const { trackEvent } = require('../../lib/tracking');
const useTrackView = require('../../hooks/use-track-view');

const GroupedShareBookmark = ({
  share,
  bookmark,
  onClickBookmark,
  wishlist,
  openWishlistModalBS,
  redirectToLogin,
  showOnlyForWishlistVariant,
}) => {
  const addToListNamespace = `ui-pdp-add-to-list`;
  const { deviceType } = useContext(StaticPropsContext);
  const userContext = useContext(UserConsumer);
  const namespace = userContext?.nickname ? 'ui-vpp-grouped-share-bookmark' : 'ui-vpp-grouped-share-bookmark-no-logged';

  const buttonClassname = classNames(`${addToListNamespace}__link`, { 'gift-registry': wishlist?.gift_registry?.id });

  const buttonOnClick = () => {
    if (userContext?.nickname) {
      openWishlistModalBS();
      trackEvent(wishlist.saveInListTrack);
    } else {
      const regex = /item_id=([^&]+)/;
      const match = wishlist?.target?.match(regex);
      const itemId = match ? match[1] : null;
      redirectToLogin(itemId);
    }
  };

  const isDesktop = deviceType === 'desktop';
  const shouldHideComponent =
    (!wishlist?.wishlistVariantVisible && showOnlyForWishlistVariant && isDesktop) ||
    (!showOnlyForWishlistVariant && (!wishlist || !wishlist.wishlistVariantVisible) && isDesktop);

  if (shouldHideComponent) {
    return null;
  }

  if (wishlist?.label && (wishlist?.wishlistVariantVisible || showOnlyForWishlistVariant)) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const viewRef = useTrackView(wishlist?.track || {}, { threshold: 0.1 });
    return (
      <div className={classNames(`${namespace}-wishlist`, deviceType)} id="gift-registry" ref={viewRef}>
        <button
          type="button"
          className={buttonClassname}
          onClick={() => buttonOnClick()}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              buttonOnClick();
            }
          }}
        >
          <span>
            <span className={`${addToListNamespace}__link--label`}>{wishlist?.label}</span>
          </span>
          <span className={`${addToListNamespace}__link--chevron`}>
            <ChevronRight20 color="#3483FA" />
          </span>
        </button>
      </div>
    );
  }

  return (
    <div className={namespace}>
      {bookmark && bookmark.label && <Bookmark {...bookmark} onClick={onClickBookmark} />}
      {share && share.action ? <Share {...share} /> : null}
    </div>
  );
};

GroupedShareBookmark.propTypes = {
  id: string,
  app: string,
  addElementToList: func,
  removeElementFromList: func,
  share: shape({
    action: shape(),
  }),
  bookmark: shape({}),
  onClickBookmark: func,
  showSnackbar: func,
  openWishlistModalBS: func,
  wishlist: shape({
    label: string,
    target: string,
  }),
  redirectToLogin: func,
  showOnlyForWishlistVariant: bool,
};

GroupedShareBookmark.defaultProps = {
  share: null,
  bookmark: null,
  onClickBookmark: null,
  showSnackbar: null,
  wishlist: null,
  redirectToLogin: null,
  showOnlyForWishlistVariant: null,
};

module.exports = GroupedShareBookmark;
exports = module.exports;
exports.MappedGroupedShareBookmark = props => <GroupedShareBookmark {...jsonToProps(props)} />;
