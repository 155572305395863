const actions = require('../actions/index');
const actionsRoot = require('../../../actions');

const mapDispatchToProps = dispatch => ({
  showVariationsError: (deviceType, actionKey) => dispatch(actionsRoot.showVariationsError(deviceType, actionKey)),
  onSearchQuery: ({ query, item_id, product_id }) => {
    dispatch(actions.searchQADB({ query, item_id, product_id }));
  },

  onCancelSearchQuery: ({ item_id, product_id }) => {
    dispatch(actions.searchQADBClose({ item_id, product_id }));
  },

  onSearchBlur: () => {
    dispatch(actions.searchBlur());
  },

  onSearchFocus: () => {
    dispatch(actions.searchFocus());
  },

  onChangeQuestion(question) {
    dispatch(actions.inputValue({ question }));
  },

  onCreateQuestion: ({ item_id, question, failure, success, app = 'pdp', parameters, form }) => {
    dispatch(actions.makeQuestion({ item_id, question, failure, success, app, parameters, form }));
  },

  onLeaveFeedback: track => {
    dispatch(actions.leaveFeedback(track));
  },

  onClickHowToMakeQuestionButton: ({ tooltip }) => {
    dispatch(actions.searchCallToActionFocus({ tooltip }));
  },

  onCloseHowToMakeQuestionTooltip: () => {
    dispatch(actions.closeHowToMakeQuestionTooltip());
  },

  onCloseSmartAnswerModal: () => {
    dispatch(actions.closeSmartAnswerModal());
  },

  onOpenQuestionsFromSmartAnswer: () => {
    dispatch(actions.openQuestionsFromSmartAnswer());
  },
});

module.exports = mapDispatchToProps;
