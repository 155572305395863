const React = require('react');
const CartActions = require('./cart-actions');

const namespace = 'ui-pdp-cart-actions-container';

const CartActionsContainer = props => (
  <div className={namespace}>
    <CartActions {...props} />
  </div>
);

module.exports = CartActionsContainer;
