const React = require('react');
const { string } = require('prop-types');
const classnames = require('classnames');

const ICON_ID = 'close';
const namespace = 'ui-pdp-icon';

const IconClose = ({ className }) => (
  <svg
    className={classnames(className, namespace)}
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
  >
    <use href={`#${ICON_ID}`} />
  </svg>
);

IconClose.propTypes = {
  className: string,
};

IconClose.defaultProps = {
  className: null,
};

module.exports = React.memo(IconClose);
exports = module.exports;
exports.ICON_ID = ICON_ID;
