const React = require('react');
const Grid = require('../../../../../../components/grid');

const VariationsBox = require('../../../../features/variations/variations-box.mobile');

const VariationBoxChunk = () => (
  <>
    <Grid.Row className="mt-15">
      <VariationsBox className="pl-16 pr-16" />
    </Grid.Row>
  </>
);

module.exports = VariationBoxChunk;
