const { loadable } = require('nordic/lazy');

const compose = require('../../../../lib/compose');
const connect = require('../../../../store/connect');
const withFeatureFlag = require('../../../../components/feature-flag/with-feature-flag');
const jsonToProps = require('../../../../components/related-searches/related-searches-mapper');

const RelatedSearches = loadable(() => import('../../../../components/related-searches'));

const FEATURE_NAME = 'related_searches';

const mapStateToProps = ({
  components: {
    [FEATURE_NAME]: { title, related_searches },
  },
}) => jsonToProps({ title, related_searches });

const enhance = compose(
  withFeatureFlag(FEATURE_NAME, { loader: false }),
  connect(mapStateToProps),
);

module.exports = enhance(RelatedSearches);
