const React = require('react');
const { arrayOf, bool, string, shape } = require('prop-types');
const classNames = require('classnames');
const { trackEvent } = require('../../lib/tracking');
const IconFactory = require('../icons/factory-builder')();

const namespace = 'ui-pdp-share__content';

const ShareContent = ({ shareActions, title, showIconLabel, modifier }) => {
  const itemLink = (icon, text, target, track) => (
    <li className={`${namespace}__list__item`} key={`share_item_${icon.id}`}>
      <a
        data-testid="share-on-click"
        className={`${namespace}__list__item__link`}
        rel="nofollow noopener noreferrer"
        href={target}
        target="_blank"
        onClick={() => trackEvent(track)}
      >
        {IconFactory(icon, `${namespace}__list__item__icon`)}
      </a>
      {showIconLabel && <span className={`${namespace}__list__item__label`}>{text}</span>}
    </li>
  );

  return (
    <div className={namespace}>
      {title && <h2 className={`${namespace}__title`}>{title} </h2>}
      <ul className={classNames(`${namespace}__list`, { [`${namespace}__list--${modifier}`]: modifier })}>
        {shareActions.map(({ icon, target, label: { text }, track }) => itemLink(icon, text, target, track))}
      </ul>
    </div>
  );
};

ShareContent.propTypes = {
  modifier: string,
  shareActions: arrayOf(
    shape({
      target: string,
      icon: shape({
        id: string,
      }),
      label: shape({
        text: string,
      }),
    }),
  ),
  showIconLabel: bool,
  title: string,
};

ShareContent.defaultProps = {
  shareActions: [],
  modifier: null,
  showIconLabel: true,
  title: null,
};

module.exports = ShareContent;
