const React = require('react');
const Grid = require('../../../../../../components/grid');

const Description = require('../../../../features/description/description.mobile');
const HtmlDescription = require('../../../../features/html-description/html-description.mobile');

const DescriptionChunk = () => (
  <Grid.Row className="ui-pdp-with--separator">
    <Description className="pl-16 pr-16" />
    <HtmlDescription className="pl-16 pr-16" />
  </Grid.Row>
);

module.exports = DescriptionChunk;
