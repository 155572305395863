/**
 * Module dependencies
 */
const React = require('react');
const { string } = require('prop-types');
const FeatureFlag = require('./feature-flag');
const getDisplayName = require('../../lib/get-display-name');

/**
 * withFeatureFlag
 */
const withFeatureFlag = (name, options = {}) => WrappedComponent => {
  const WithFeatureFlag = ({ featureName, ...props }) => (
    <FeatureFlag name={name || featureName} {...options}>
      <WrappedComponent {...props} featureName={name || featureName} />
    </FeatureFlag>
  );

  WithFeatureFlag.displayName = `WithFeatureFlag(${getDisplayName(WrappedComponent)})`;

  WithFeatureFlag.propTypes = {
    featureName: string,
  };
  WithFeatureFlag.defaultProps = {
    featureName: null,
  };

  return WithFeatureFlag;
};

/**
 * Expose withFeatureFlag
 */
module.exports = withFeatureFlag;
