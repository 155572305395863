const React = require('react');
const Grid = require('../../../../../../components/grid');

const ApparelSizeSpec = require('../../../../features/appareal-size-spec/apparel-size-spec.mobile');

const ApparelSizeSpecChunk = () => (
  <>
    <Grid.Row className="mt-12">
      <ApparelSizeSpec className="pl-16 pr-16" />
    </Grid.Row>
  </>
);

module.exports = ApparelSizeSpecChunk;
