const React = require('react');
const { node, string, shape } = require('prop-types');
const { ImageProvider } = require('nordic/image');
const { I18nProvider } = require('nordic/i18n');
const getDisplayName = require('../../lib/get-display-name');

const withProviders = (WrappedComponent, assetPrefix, i18n) => {
  const withProvidersComponent = props => (
    <ImageProvider prefix={assetPrefix}>
      <I18nProvider i18n={i18n}>
        <WrappedComponent {...props} />
      </I18nProvider>
    </ImageProvider>
  );

  withProvidersComponent.propTypes = {
    asset: shape({}),
  };

  withProvidersComponent.defaultProps = {
    asset: null,
  };

  withProvidersComponent.displayName = `withProvidersComponent(${getDisplayName(WrappedComponent)})`;

  return withProvidersComponent;
};

withProviders.propTypes = {
  WrappedComponent: node.isRequired,
  assetPrefix: string,
};

withProviders.defaultProps = {
  assetPrefix: null,
};

module.exports = withProviders;
