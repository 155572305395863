const React = require('react');
const { LazyHydrate: Hydrator } = require('nordic/lazy');
const { string, node } = require('prop-types');

const Hydrate = ({ componentType, children }) => {
  switch (componentType) {
    case 'seller_recos':
      return children;
    default:
      return <Hydrator whenIdle>{children}</Hydrator>;
  }
};

Hydrate.propTypes = {
  componentType: string.isRequired,
  children: node.isRequired,
};

module.exports = Hydrate;
