const React = require('react');
const { string, shape } = require('prop-types');
const classNames = require('classnames');

const Grid = require('../../../../../../components/grid');

const Buybox = require('../../../../features/buybox');
const PaymentSummary = require('../../../../features/buybox/payment/payment.mobile');
const CouponSummary = require('../../../../features/buybox/coupon-summary/coupon-summary.mobile');
const ShippingSummary = require('../../../../features/buybox/shipping');
const SecondShippingSummary = require('../../../../features/buybox/second-shipping');
const PickUpSummary = require('../../../../features/buybox/pick-up');
const ReturnsSummary = require('../../../../features/buybox/returns-summary/returns-summary.mobile');
const MeliplusLabel = require('../../../../features/buybox/meliplus-label/meliplus-label');
const MeliplusSummary = require('../../../../features/buybox/meliplus');
const UpsellingMeliplusSummary = require('../../../../features/buybox/upselling-meliplus');
const CardWithThumbnails = require('../../../../features/card-with-thumbnails/card-with-thumbnails');
const SellerInfo = require('../../../../features/seller-info/seller-info.mobile');
const StockInformation = require('../../../../features/stock-information/stock-information.mobile');
const FulfillmentInformation = require('../../../../features/fulfillment-information');
const Quantity = require('../../../../features/buybox/quantity/quantity.mobile');
const Actions = require('../../../../features/buybox/actions');
const CartActions = require('../../../../features/buybox/cart-actions/cart-actions.mobile');
const CreditsTooltip = require('../../../../features/credits-tooltip');
const PaypalTooltip = require('../../../../features/paypal-tooltip');
const MainActions = require('../../../../../vip/features/main-actions/main-actions.mobile');
const CpgSummary = require('../../../../features/cpg/summary.mobile');
const ShippingMessage = require('../../../../features/shipping-message');
const MoqInformation = require('../../../../features/moq-information/moq-information.mobile');
const CbtSummary = require('../../../../../../components/cbt/cbt-summary.mobile');
const CbtTaxesSummary = require('../../../../../../components/cbt-taxes/cbt-taxes-summary.mobile');
const ThirdPartyFirstSection = require('../../../../../vip/features/third_party_first_section');
const GenericBanner = require('../../../../features/buybox/generic-banner/generic-banner.mobile');
const GenericBannerPrice = require('../../../../features/buybox/generic-banner-price/generic-banner.mobile');
const GenericBannerInstallments = require('../../../../features/buybox/generic-banner-installments/generic-banner.mobile');

const DynamicComponentWrapper = require('../../../../../vip/features/dynamic-component-wrapper');
const renderSwapComponent = require('../../../../../vip/features/dynamic-component-wrapper/render-swap-component');

const MeliDeliveryDaySummary = () => (
  <>
    <MeliplusSummary />
    <CardWithThumbnails className="mb-20 mt-4" />
  </>
);

// Dynamic Shipping Wrapper (for order swap)
const renderTopShipping = renderSwapComponent(
  'meliplus_summary',
  <MeliDeliveryDaySummary />,
  <ShippingSummary className="mb-12" />,
);

const BuyBoxChunk = ({ baseURL, site, components }) => {
  const { moq_information } = components || {};
  const isMshops = site === 'MS';
  const hasMoqInfo = moq_information && moq_information !== 'HIDDEN';

  return (
    <Grid.Row>
      <Buybox className="pl-16 pr-16">
        <CouponSummary className="mb-24" />
        <PaymentSummary className="mb-15" />
        <GenericBannerInstallments className="mb-24" />
        <GenericBannerPrice className="mb-24" />
        <CpgSummary />
        <CbtSummary className="mb-20" />
        <MeliplusLabel className="ui-pdp-meliplus-label mb-12" />
        <ShippingMessage className="mb-14" />
        <DynamicComponentWrapper renderDynamicComponent={renderTopShipping} spot="top_shipping" />
        <CbtTaxesSummary />
        <SecondShippingSummary className="mb-12" />
        <GenericBanner className="mb-16" />
        <UpsellingMeliplusSummary className="mb-12" />
        <PickUpSummary className="mt-20 mb-12" />
        <ReturnsSummary className="mb-12" />
        <SellerInfo className="mb-25" />
        <div className="ui-pdp-stock-and-full mt-24 mb-24">
          <StockInformation />
          <FulfillmentInformation />
        </div>
        <ThirdPartyFirstSection />
        <Quantity className={classNames('mb-25', { 'ui-quantity--with-moq': hasMoqInfo })} />
        <MoqInformation className="mb-16" />
        <PaypalTooltip className="mb-16" />
        {isMshops ? <MainActions /> : <Actions className="mt-30" parentUrl={baseURL} />}
        <CartActions />
        <CreditsTooltip className="mt-16" />
      </Buybox>
    </Grid.Row>
  );
};

BuyBoxChunk.propTypes = {
  baseURL: string.isRequired,
  site: string,
  components: shape({}),
};

BuyBoxChunk.defaultProps = {
  site: 'ML',
  components: null,
};

module.exports = BuyBoxChunk;
