const APIService = require('../services/api');

const feStatsdHelper = ({ key, aditionalTags = {}, aditionalInfo, shouldLogMessage, statsdConfig }) => {
  if (statsdConfig) {
    const { keyPrefix, baseTags } = statsdConfig;

    APIService.saveFrontendStatsd({
      key: `${keyPrefix}.${key}`,
      tags: {
        ...baseTags,
        ...aditionalTags,
      },
      info: shouldLogMessage && aditionalInfo,
    });
  }
};

module.exports = { feStatsdHelper };
