const React = require('react');
const { arrayOf, shape, string, bool } = require('prop-types');
const classNames = require('classnames');
const ActionRow = require('../action-row');

const { trackEvent } = require('../../lib/tracking');
const { generateKeyToComponent } = require('../../utils/validators');

const namespace = 'ui-pdp-products';

const OtherProducts = ({ className, products, isBBWAlternativesVisible }) => {
  // @TODO: This belongs to BE & to hell.
  if (!isBBWAlternativesVisible) {
    return (
      <div className={classNames(namespace, className)}>
        <ul>
          {products.map(product => (
            <li key={generateKeyToComponent(product)} className={`${namespace}__item`}>
              <ActionRow
                subtitle={product.title}
                url={product.action.target}
                onClick={() => {
                  trackEvent(product.action.track);
                }}
                modifier="product"
              />
            </li>
          ))}
        </ul>
      </div>
    );
  }
  return null;
};

OtherProducts.propTypes = {
  className: string,
  products: arrayOf(
    shape({
      title: shape({
        text: string.isRequired,
        color: string,
      }).isRequired,
      action: shape({
        target: string.isRequired,
        track: shape({}),
      }),
    }),
  ).isRequired,
  isBBWAlternativesVisible: bool.isRequired,
};

OtherProducts.defaultProps = {
  className: '',
};

module.exports = OtherProducts;
