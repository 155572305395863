const React = require('react');
const { bool, shape, string, number } = require('prop-types');
const { ActionCollapsable } = require('../action-collapsable');
const Description = require('./description');
const classnames = require('classnames');

const namespaceRex = 'ui-pdp-description-collapse-rex';
const FEATURE_NAME_REX = 'description_rex';

const DescriptionMobile = ({
  action,
  className,
  content,
  custom_message,
  maxPages,
  minPages,
  title,
  show_track,
  id,
}) => (
  <ActionCollapsable
    action={action}
    max={minPages}
    maxCollapsed={maxPages}
    scrollToTop={false}
    className={classnames({
      [namespaceRex]: FEATURE_NAME_REX === id,
    })}
  >
    <Description
      className={className}
      content={content}
      title={title}
      custom_message={custom_message}
      show_track={show_track}
    />
  </ActionCollapsable>
);

DescriptionMobile.propTypes = {
  action: shape({
    label: shape({
      text: string.isRequired,
      color: string,
    }).isRequired,
    target: string,
  }).isRequired,
  className: string,
  content: string.isRequired,
  custom_message: shape({
    state: string,
    body: shape({ test: string }),
    closeable: bool,
    hierarchy: string,
    message_type: string,
  }),
  id: string,
  maxPages: number,
  minPages: number,
  title: string.isRequired,
  show_track: shape({}),
};

DescriptionMobile.defaultProps = {
  className: '',
  custom_message: null,
  show_track: null,
};

module.exports = DescriptionMobile;
