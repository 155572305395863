const { loadable } = require('nordic/lazy');
const compose = require('../../../../lib/compose');
const connect = require('../../../../store/connect');
const withFeatureFlag = require('../../../../components/feature-flag/with-feature-flag');

const SpecsMobile = loadable(() => import('../../../../components/specs/specs.mobile'));

const FEATURE_NAME = 'all_features';

const mapStateToProps = ({ components: { [FEATURE_NAME]: allFeatures }, pathName }, ownProps) => ({
  ...allFeatures,
  ...ownProps,
  pathName,
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(mapStateToProps),
);

module.exports = enhance(SpecsMobile);
