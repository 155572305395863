const SEARCH_QADB_INIT = 'SEARCH_QADB_INIT';
const SEARCH_QADB_SUCCESS = 'SEARCH_QADB_SUCCESS';
const SEARCH_QADB_ERROR = 'SEARCH_QADB_ERROR';
const SEARCH_QADB_ON_BLUR = 'SEARCH_QADB_ON_BLUR';
const SEARCH_QADB_ON_FOCUS = 'SEARCH_QADB_ON_FOCUS';
const SEARCH_QADB_CLOSE = 'SEARCH_QADB_CLOSE';
const SEARCH_QADB_CLOSE_COMPLETE = 'SEARCH_QADB_CLOSE_COMPLETE';
const SEARCH_QADB_CALL_TO_ACTION_FOCUS = 'SEARCH_QADB_CALL_TO_ACTION_FOCUS';
const CLOSE_HOW_TO_MAKE_QUESTION_TOOLTIP = 'CLOSE_HOW_TO_MAKE_QUESTION_TOOLTIP';
const MAKE_QUESTION = 'MAKE_QUESTION';
const MAKE_QUESTION_SUCCESS = 'MAKE_QUESTION_SUCCESS';
const MAKE_QUESTION_ERROR = 'MAKE_QUESTION_ERROR';
const LEAVE_FEEDBACK_START = 'LEAVE_FEEDBACK_START';
const LEAVE_FEEDBACK_COMPLETE = 'LEAVE_FEEDBACK_COMPLETE';
const CLOSE_SMART_ANSWER_MODAL = 'CLOSE_SMART_ANSWER_MODAL';
const OPEN_QUESTION_FROM_SA_MODAL = 'OPEN_QUESTION_FROM_SA_MODAL';
const SET_SMART_ANSWER = 'SET_SMART_ANSWER';

module.exports = {
  SEARCH_QADB_INIT,
  SEARCH_QADB_SUCCESS,
  SEARCH_QADB_ERROR,
  SEARCH_QADB_ON_BLUR,
  SEARCH_QADB_ON_FOCUS,
  SEARCH_QADB_CLOSE,
  SEARCH_QADB_CLOSE_COMPLETE,
  SEARCH_QADB_CALL_TO_ACTION_FOCUS,
  CLOSE_HOW_TO_MAKE_QUESTION_TOOLTIP,
  MAKE_QUESTION,
  MAKE_QUESTION_SUCCESS,
  MAKE_QUESTION_ERROR,
  LEAVE_FEEDBACK_START,
  LEAVE_FEEDBACK_COMPLETE,
  SET_SMART_ANSWER,
  CLOSE_SMART_ANSWER_MODAL,
  OPEN_QUESTION_FROM_SA_MODAL,
};
