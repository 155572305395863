const compose = require('../../../../../lib/compose');
const connect = require('../../../../../store/connect');
const withFeatureFlag = require('../../../../../components/feature-flag/with-feature-flag');
const actions = require('../../../../../actions');

const FEATURE_NAME = 'preload';

const mapStateToProps = ({ preload }) => ({
  preload,
});

const mapDispatchToProps = dispatch => ({
  onePayForAllShowModal: show => dispatch(actions.onePayForAllShowModal(show)),
  onIframeLoaded: () => dispatch(actions.onIframeLoadedOnePayForAll()),
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME, { pathToComponent: `${FEATURE_NAME}` }),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

module.exports = OnePayForAllComponent => enhance(OnePayForAllComponent);
exports = module.exports;
exports.mapDispatchToProps = mapDispatchToProps;
exports.mapStateToProps = mapStateToProps;
