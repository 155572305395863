const React = require('react');
const Grid = require('../../../../../../components/grid');

const Price = require('../../../../features/price');
const CbtTaxesFcUsSummary = require('../../../../../../components/cbt-taxes-fc-us/cbt-taxes-fc-us-summary.mobile');

const PriceChunk = () => (
  <>
    <Grid.Row>
      <Price meta className="pb-24" size="large" />
      <CbtTaxesFcUsSummary />
    </Grid.Row>
  </>
);

module.exports = PriceChunk;
