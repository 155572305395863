const React = require('react');
const { shape, string, arrayOf, func, number } = require('prop-types');
const classNames = require('classnames');
const ComponentFactory = require('./factory-components');
const useTrackView = require('../../hooks/use-track-view');
const Hydrate = require('./hydrate');

const namespace = 'ui-seller-data';

const SellerData = ({
  id,
  type,
  state,
  components,
  viewport_track,
  onFollowClick,
  recommendations_context,
  globalContext,
  className,
}) => {
  const viewRef = useTrackView(viewport_track);

  return components && components.length > 0 && state === 'VISIBLE' ? (
    <div ref={viewRef} className={classNames(namespace, className)}>
      {components &&
        components.map(component => (
          <Hydrate key={id} componentType={type}>
            <ComponentFactory
              id={id}
              type={type}
              onFollowClick={onFollowClick}
              {...component}
              recommendationsContext={recommendations_context}
              globalContext={globalContext}
            />
          </Hydrate>
        ))}
    </div>
  ) : null;
};

SellerData.propTypes = {
  id: string.isRequired,
  type: string.isRequired,
  state: string.isRequired,
  components: arrayOf(shape({})).isRequired,
  onFollowClick: func,
  recommendations_context: shape({}),
  globalContext: shape({}),
  className: string,
  viewport_track: shape({
    melidata_event: shape({
      path: string,
      event_data: shape({
        seller_type: string,
        variant_id: string,
        seller_id: number,
        reputation: string,
      }),
    }),
  }),
};

SellerData.defaultProps = {
  recommendations_context: null,
  globalContext: null,
  className: null,
  viewport_track: null,
  onFollowClick: null,
};

module.exports = SellerData;
