const APIService = require('../../../services/api');
const { trackEvent } = require('../../../lib/tracking');

const {
  SEARCH_QADB_INIT,
  SEARCH_QADB_SUCCESS,
  SEARCH_QADB_ERROR,
  SEARCH_QADB_CLOSE,
  SEARCH_QADB_CLOSE_COMPLETE,
  SEARCH_QADB_ON_BLUR,
  SEARCH_QADB_ON_FOCUS,
  SEARCH_QADB_CALL_TO_ACTION_FOCUS,
  MAKE_QUESTION,
  MAKE_QUESTION_SUCCESS,
  MAKE_QUESTION_ERROR,
  LEAVE_FEEDBACK_START,
  LEAVE_FEEDBACK_COMPLETE,
  SET_SMART_ANSWER,
  CLOSE_HOW_TO_MAKE_QUESTION_TOOLTIP,
  CLOSE_SMART_ANSWER_MODAL,
  OPEN_QUESTION_FROM_SA_MODAL,
} = require('./actions');

const searchQADB = ({ query, item_id, product_id, site_id }) => dispatch => {
  dispatch({
    type: SEARCH_QADB_INIT,
    payload: {
      query,
      product_id,
      item_id,
      site_id,
    },
  });

  APIService.searchQADB({ query, item_id, product_id, site_id })
    .then(response => {
      dispatch({
        type: SEARCH_QADB_SUCCESS,
        payload: {
          response,
        },
      });
      trackEvent(response.track);
    })
    .catch(() => {
      dispatch({
        type: SEARCH_QADB_ERROR,
        payload: {},
      });
    });
};

const searchBlur = () => dispatch => {
  dispatch({
    type: SEARCH_QADB_ON_BLUR,
  });
};

const searchFocus = () => dispatch => {
  dispatch({
    type: SEARCH_QADB_ON_FOCUS,
  });
};

const inputValue = ({ question }) => dispatch => {
  dispatch({
    type: MAKE_QUESTION,
    payload: {
      question,
    },
  });
};

const searchQADBClose = ({ item_id, product_id }) => dispatch => {
  dispatch({
    type: SEARCH_QADB_CLOSE,
    payload: {
      product_id,
      item_id,
    },
  });

  APIService.getQADB({ item_id, product_id }).then(response => {
    dispatch({
      type: SEARCH_QADB_CLOSE_COMPLETE,
      payload: {
        response,
      },
    });
  });
};

const searchCallToActionFocus = ({ tooltip }) => dispatch => {
  dispatch({
    type: SEARCH_QADB_CALL_TO_ACTION_FOCUS,
    tooltip: { ...tooltip, visible: true },
  });
};

const closeHowToMakeQuestionTooltip = () => dispatch => {
  dispatch({ type: CLOSE_HOW_TO_MAKE_QUESTION_TOOLTIP });
};

const proccessCreateQuestionReponse = (res, success, dispatch, trackEve) => {
  if (res.data && res.data.login) {
    window.location = res.data.login;
  } else if (res.SMART_ANSWER_MODAL && res.SMART_ANSWER_MODAL.state === 'VISIBLE') {
    trackEve(res.track);
    dispatch({
      type: SET_SMART_ANSWER,
      payload: {
        ...res.SMART_ANSWER_MODAL,
      },
    });
  } else {
    trackEve(success.track);
    trackEve(res.track);
    dispatch({
      type: MAKE_QUESTION_SUCCESS,
      payload: {
        message: success.message,
        results: { ...res.result },
      },
    });
  }
};

const makeQuestion = ({ item_id, question, failure, success, app, parameters, form }) => dispatch => {
  const is_qadb = true;

  APIService.createQuestion(item_id, question, { app, is_qadb }, parameters ? parameters.parameters : null, form)
    .then(res => {
      proccessCreateQuestionReponse(res, success, dispatch, trackEvent);
    })
    .catch(() => {
      trackEvent(failure.track);
      dispatch({
        type: MAKE_QUESTION_ERROR,
        payload: {
          message: failure.message,
        },
      });
    });
};

const leaveFeedback = track => dispatch => {
  dispatch({
    type: LEAVE_FEEDBACK_START,
  });
  trackEvent(track);
  dispatch({
    type: LEAVE_FEEDBACK_COMPLETE,
  });
};

const closeSmartAnswerModal = () => dispatch => {
  dispatch({
    type: CLOSE_SMART_ANSWER_MODAL,
  });
};

const openQuestionsFromSmartAnswer = () => dispatch => {
  dispatch({
    type: OPEN_QUESTION_FROM_SA_MODAL,
  });
};

module.exports = {
  searchQADB,
  searchQADBClose,
  searchBlur,
  searchFocus,
  inputValue,
  makeQuestion,
  leaveFeedback,
  searchCallToActionFocus,
  closeHowToMakeQuestionTooltip,
  closeSmartAnswerModal,
  openQuestionsFromSmartAnswer,
  proccessCreateQuestionReponse,
};
