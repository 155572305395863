const compose = require('../../../../lib/compose');
const connect = require('../../../../store/connect');
const withFeatureFlag = require('../../../../components/feature-flag/with-feature-flag');

const FEATURE_NAME = 'description';

const mapStateToProps = ({ components: { [FEATURE_NAME]: description }, pathName }, ownProps) => ({
  ...description,
  maxPages: description.max_pages,
  minPages: description.min_pages,
  ...ownProps,
  pathName,
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(mapStateToProps),
);

module.exports = Component => enhance(Component);
