const React = require('react');
const { useState, useContext, startTransition } = require('react');

const { bool, func, number, shape, string, oneOf, arrayOf } = require('prop-types');
const formatBulkQuantity = require('../../../utils/formatBulkQuantity');
const classnames = require('classnames');
const ActionRow = require('../../action-row');
const BackdropModal = require('../../backdrop-modal');
const StaticPropsContext = require('../../context/static-props');
const QuantitySelector = require('./quantity-selector.mobile');
const QuantityNative = require('./quantity-native');
const ErrorMessage = require('../../variations/components/error-message');
const HistoryService = require('../../../services/history');
const StyledLabel = require('../../styled-label');
const Quantity = require('./quantity');
const { runSchedulerTask } = require('../../../utils/validators');

const namespace = 'ui-pdp-buybox__quantity';

const QuantityMobile = ({
  className,
  quantity_selector,
  picker,
  type,
  label,
  showInput,
  showQuantityInput,
  onSetQuantity,
  onShowInput,
  isFetching,
  closeModalLabel,
}) => {
  const yieldValue = quantity_selector?.selector?.template?.yield;
  const bulkSale = yieldValue != null && yieldValue > 0;
  const [isOpenModal, setOpenModal] = useState(false);
  const { lowEnd } = useContext(StaticPropsContext);
  const [localQuantity, setLocalQuantity] = useState(
    bulkSale ? picker?.selected * yieldValue : Number(picker?.selected) || null,
  );

  const onActionRowClick = e => {
    e.preventDefault();
    setOpenModal(!isFetching);
  };

  const showErrorMessage = bulkSale && picker && picker.error_message && !picker.error_message.is_deferred;
  /* istanbul ignore next */
  const handleOnSetQuantity = quantity => {
    let totalQuantity = quantity;
    if (bulkSale) {
      totalQuantity = Math.ceil(parseFloat((quantity / yieldValue).toFixed(4)));
    }
    setLocalQuantity(totalQuantity);
    startTransition(() => {
      onSetQuantity(totalQuantity);
      setOpenModal(false);
      runSchedulerTask(() => {
        HistoryService.pushParam('quantity', totalQuantity, true);
      }, 'background');
    });
  };

  if (type === 'label_component') {
    return (
      <Quantity className={className} picker={picker}>
        <StyledLabel {...label} />
      </Quantity>
    );
  }

  if (picker) {
    if (lowEnd) {
      return (
        <QuantityNative
          className={`${namespace}__lowend--mobile`}
          message={picker.description}
          quantity={localQuantity}
        />
      );
    }

    return (
      <Quantity className={className} picker={picker}>
        <ActionRow
          className={classnames(namespace)}
          label={picker.title}
          selected={picker.selected_label || !!localQuantity}
          subtitle={bulkSale ? null : picker.description}
          onClick={onActionRowClick}
          disabled={!quantity_selector}
          modifier={picker.border_color ? 'error' : null} // @TODO - Map from border color to modifier, something like colorClassnames for fonts but for actionrows
        />
        {showErrorMessage && (
          <span className={`${namespace}__alert`}>
            {' '}
            <ErrorMessage error={picker.error_message} />
          </span>
        )}
        {quantity_selector ? (
          <BackdropModal
            onClose={() => {
              setOpenModal(false);
              onShowInput(false);
            }}
            visible={isOpenModal}
            closeModalLabel={closeModalLabel}
          >
            <QuantitySelector
              selected={bulkSale ? formatBulkQuantity(localQuantity) : localQuantity}
              showInput={showQuantityInput || showInput}
              setQuantity={handleOnSetQuantity}
              onShowInput={onShowInput}
              quantitySelector={quantity_selector}
              description={bulkSale ? picker.description : null}
            />
          </BackdropModal>
        ) : null}
      </Quantity>
    );
  }
  return null;
};

QuantityMobile.propTypes = {
  className: string,
  closeModalLabel: string,
  isFetching: bool,
  picker: shape({
    title: string.isRequired,
    selected: number.isRequired,
    selected_label: shape({
      text: string,
      color: string,
      font_family: string,
    }),
    description: string,
    messages: arrayOf(
      shape({
        text: string,
        color: string,
        font_size: string,
        font_family: string,
      }),
    ),
  }).isRequired,
  picker_message: shape({
    text: string.isRequired,
    color: string.isRequired,
  }),
  type: oneOf(['label_component', 'available_quantity']).isRequired,
  label: shape({
    text: string.isRequired,
    font_family: string.isRequired,
    font_size: string.isRequired,
    color: string.isRequired,
  }),
  quantity_selector: shape({
    available_quantity: number,
    selector: shape({
      title: shape({
        text: string,
        color: string,
      }),
      subtitles: arrayOf(
        shape({
          text: string,
          color: string,
          font_size: string,
          font_family: string,
        }),
      ),
      template: shape({
        singular: string,
        plural: string,
        more: string,
      }).isRequired,
      rows: number,
    }).isRequired,
    input: shape({
      title: shape({
        text: string,
        color: string,
      }),
      confirm_button: shape({ label: shape({ text: string }) }),
      error_message: shape({
        text: string,
        color: string,
      }),
    }).isRequired,
  }),
  showInput: bool,
  showQuantityInput: bool,
  onSetQuantity: func.isRequired,
  onShowInput: func.isRequired,
};

QuantityMobile.defaultProps = {
  className: null,
  isFetching: false,
  picker_message: null,
  label: null,
  quantity_selector: null,
  showInput: false,
  showQuantityInput: false,
};

module.exports = QuantityMobile;
