const generateUUIDv1 = require('../utils/generateUUIDv1');

/* @TODO move to default/config */
const restclient = require('./client/restclient')({
  baseURL: '/p',
  timeout: 10000,
});
const restclientQuestionsAi = require('./client/restclient')({
  baseURL: '/p',
  timeout: 12000,
});
const transformRemainingCountdowns = require('./transforms/remaining');

const SERVICE_BASE_PATH = '/api';

class Service {
  static getProduct(id, params) {
    return restclient.get(`${SERVICE_BASE_PATH}/products/${id}`, { params }).then(response => response.data);
  }

  static getCompatsProduct(params) {
    return restclient
      .get(`${SERVICE_BASE_PATH}/compats/update/compatibilities`, { params })
      .then(response => response.data);
  }

  static postCompats(selectedInput, params) {
    return restclient
      .post(`${SERVICE_BASE_PATH}/compats/user-selection`, {
        params,
        data: selectedInput,
      })
      .then(response => response.data);
  }

  static deleteCompats(params) {
    return restclient.delete(`${SERVICE_BASE_PATH}/compats/user-selection`, { params }).then(response => response.data);
  }

  static getProductUpdateVariation(id, params) {
    return restclient
      .get(`${SERVICE_BASE_PATH}/products/${id}/update/variation`, { params })
      .then(response => response.data);
  }

  static getProductUpdateCoupon(id, params) {
    return restclient
      .get(`${SERVICE_BASE_PATH}/products/${id}/update/coupon`, { params })
      .then(response => response.data);
  }

  static getUpdateWishlists(id, params) {
    return restclient
      .get(`${SERVICE_BASE_PATH}/bookmarks/${id}/update/wishlists`, { params })
      .then(response => response.data);
  }

  static postProductCouponActivate(itemid, params) {
    return restclient
      .post(`${SERVICE_BASE_PATH}/products/${itemid}/activate/coupon`, { params })
      .then(response => response.data);
  }

  static getProductUpdateMeliplus(id, params) {
    return restclient
      .get(`${SERVICE_BASE_PATH}/products/${id}/update/meliplus`, { params })
      .then(response => response.data);
  }

  static getSellers(id, params) {
    return restclient.get(`${SERVICE_BASE_PATH}/products/${id}/s`, { params }).then(response => response.data);
  }

  static getModelUnits(itemId, params) {
    const baseQuotationPath = `/quotations/${itemId}/`;
    let quotationPath = 'form';

    if (params.deviceType === 'desktop') {
      quotationPath = 'modal';
    }

    return restclient
      .get(`${SERVICE_BASE_PATH + baseQuotationPath + quotationPath}`, { params })
      .then(response => response.data);
  }

  static getUnitsLTR(itemId, params) {
    const baseMultifamilyPath = `/multifamily/${itemId}/`;

    return restclient
      .get(`${SERVICE_BASE_PATH + baseMultifamilyPath}modal`, { params })
      .then(response => response.data);
  }

  static getUnregisteredQuotations(itemId, params) {
    const baseQuotationPath = `/quotations/unregistered/${itemId}/`;
    const quotationPath = params.deviceType === 'desktop' ? 'modal' : 'form';

    return restclient
      .get(`${SERVICE_BASE_PATH + baseQuotationPath + quotationPath}`, { params })
      .then(response => response.data);
  }

  static getSellersOnSetQuantity(id, quantity, params) {
    return restclient
      .get(`${SERVICE_BASE_PATH}/products/${id}/s`, { params: { ...params, quantity } })
      .then(response => {
        if (response.data.components && response.data.components.results && response.data.components.results.items) {
          response.data.components.results.items = response.data.components.results.items.map(item =>
            transformRemainingCountdowns(item),
          );
        }
        return response.data;
      });
  }

  static getSellersOnShippingPromiseChange(id, quantity, params) {
    return restclient
      .get(`${SERVICE_BASE_PATH}/products/${id}/s`, {
        params: {
          ...params,
          quantity,
        },
      })
      .then(response => {
        if (response.data.components && response.data.components.results && response.data.components.results.items) {
          response.data.components.results.items = response.data.components.results.items.map(item =>
            transformRemainingCountdowns(item),
          );
        }
        return response.data;
      });
  }

  static getProductVariations(id, params) {
    return Service.getProduct(id, { ...params, components_ids: 'variations' });
  }

  static getProductVariationsDetails(id, params) {
    return restclient.get(`${SERVICE_BASE_PATH}/products/${id}/variations`, { params }).then(response => response.data);
  }

  static getProductOnSetQuantity(id, quantity, params) {
    return restclient
      .get(`${SERVICE_BASE_PATH}/products/${id}/quantity/${quantity}`, { params })
      .then(response => transformRemainingCountdowns(response.data));
  }

  static getProductDetailsOnShippingPromiseChange(id, quantity, params) {
    return restclient
      .get(`${SERVICE_BASE_PATH}/products/${id}/quantity/${quantity}`, { params })
      .then(response => transformRemainingCountdowns(response.data));
  }

  static likeReview(reviewId) {
    return restclient.put(`${SERVICE_BASE_PATH}/reviews/${reviewId}/like`).then(response => response);
  }

  static likeReviewsCapability(reviewId) {
    return restclient.put(`${SERVICE_BASE_PATH}/reviews/capability/${reviewId}/like`).then(response => response);
  }

  static dislikeReviewsCapability(reviewId) {
    return restclient.put(`${SERVICE_BASE_PATH}/reviews/capability/${reviewId}/dislike`).then(response => response);
  }

  static likeReviewsSummaryCapability(summaryId) {
    return restclient
      .put(`${SERVICE_BASE_PATH}/reviews/capability/${summaryId}/like/summary`)
      .then(response => response);
  }

  static dislikeReviewsSummaryCapability(summaryId) {
    return restclient
      .put(`${SERVICE_BASE_PATH}/reviews/capability/${summaryId}/dislike/summary`)
      .then(response => response);
  }

  static dislikeReview(reviewId) {
    return restclient.put(`${SERVICE_BASE_PATH}/reviews/${reviewId}/dislike`).then(response => response);
  }

  static addBookmark(itemId, csrfToken, candidate_list_id) {
    const bookmarkId = generateUUIDv1();

    return restclient
      .post(`${SERVICE_BASE_PATH}/bookmarks/${itemId}`, {
        candidate_list_id,
        loginParams: {
          noIndex: true,
          loginType: 'FAVORITE',
          item_id: itemId,
          bookmarkId,
          go: `${window.location.origin}/p/api/bookmarks/return?itemId=${itemId}&_csrf=${csrfToken}&go=${
            window.location.href
          }`,
          parent_url: `${window.location.origin}/p/api/bookmarks/return?itemId=${itemId}`,
        },
      })
      .then(response => response && response.data);
  }

  static removeBookmark(itemId) {
    return restclient.delete(`${SERVICE_BASE_PATH}/bookmarks/${itemId}`).then(response => response.data);
  }

  static addElementToList(element, params) {
    const { entity_id } = element;
    return restclient
      .post(`${SERVICE_BASE_PATH}/bookmarks/list/add`, {
        params,
        data: { ...element },
        loginParams: {
          noIndex: true,
          loginType: 'GIFT_REGISTRY',
          item_id: entity_id,
          go: `${window.location.origin}/p/api/return?&itemId=${entity_id}&go=${window.location.href}`,
        },
      })
      .then(response => response && response.data);
  }

  static removeElementFromList(element) {
    return restclient
      .delete(`${SERVICE_BASE_PATH}/bookmarks/list/remove`, {
        params: { ...element },
      })
      .then(response => response && response.data);
  }

  static followSeller(sellerId, app, siteId) {
    return restclient
      .post(`${SERVICE_BASE_PATH}/followers/${sellerId}`, {
        app,
        site_id: siteId,
      })
      .then(response => response.data);
  }

  static unfollowSeller(sellerId, app, siteId) {
    return restclient
      .delete(`${SERVICE_BASE_PATH}/followers/${sellerId}`, {
        app,
        site_id: siteId,
      })
      .then(response => response.data);
  }

  static createQuestion(itemId, text, params, parameters, form) {
    const questionId = generateUUIDv1();
    const isQadb = params.is_qadb;
    const app = params.app || 'pdp';
    const url = isQadb
      ? `${SERVICE_BASE_PATH}/products/qadb/questions/${itemId}`
      : `${SERVICE_BASE_PATH}/questions/${itemId}`;
    const type = isQadb ? 'QADB_QUESTION' : 'QUESTION';

    const data = { text, questionId };
    const escapedEncodedQuery = encodeURIComponent(text).replace(/\?/g, '%3F');

    if (parameters) {
      data.parameters = parameters;
    }
    if (form) {
      data.form = form;
    }

    return restclient
      .post(url, {
        params,
        data,
        loginParams: {
          noIndex: true,
          loginType: type,
          item_id: itemId,
          question: escapedEncodedQuery,
          questionId,
          go: `${
            window.location.origin
          }/p/api/questions/return?app=${app}&question=${escapedEncodedQuery}&questionId=${questionId}&itemId=${itemId}&go=${
            window.location.href
          }`,
          parent_url: `${
            window.location.origin
          }/p/api/questions/return?app=${app}&questionId=${questionId}&itemId=${itemId}`,
        },
      })
      .then(response => response && response.data);
  }

  static createQuestionsUnregistered({ itemId, form, recaptchaTokenv3, repcatchaV2Token, params, action }) {
    params.recaptcha_token = recaptchaTokenv3;
    params.recaptcha_response = repcatchaV2Token;
    params.recaptcha_action = action;
    params.expected_action = action;
    params.unregistered_question_origin = params.origin;
    const questionId = generateUUIDv1();

    return restclient
      .post(`${SERVICE_BASE_PATH}/questions/unregistered/${itemId}`, {
        params,
        data: { form, questionId },
      })
      .then(response => response.data);
  }

  static createBudgetDemand(itemId, text, params) {
    const budgetId = generateUUIDv1();

    return restclient
      .post(`${SERVICE_BASE_PATH}/budget/${itemId}`, {
        params,
        data: { text, budgetId },
        loginParams: {
          noIndex: true,
          loginType: 'BUDGET_DEMAND',
          item_id: itemId,
          go: `${window.location.origin}/p/api/budget/return?budgetId=${budgetId}&itemId=${itemId}&go=${
            window.location.href
          }`,
        },
      })
      .then(response => response.data);
  }

  static createQuotation({ itemId, unitId, recaptchaTokenV2, recaptchaTokenV3, action }, params) {
    params.recaptcha_token = recaptchaTokenV3;
    params.recaptcha_response = recaptchaTokenV2;
    params.recaptcha_action = action;
    params.expected_action = action;
    params.unregistered_question_origin = params.origin;
    const quotationId = generateUUIDv1();

    return restclient
      .post(`${SERVICE_BASE_PATH}/quotations/${itemId}`, {
        params: { ...params, unitId },
        data: { unitId, quotationId },
      })
      .then(response => response.data);
  }

  static createQuotationPrice({ itemId, unitId, recaptchaTokenV2, recaptchaTokenV3, action, quotationType }, params) {
    params.recaptcha_token = recaptchaTokenV3;
    params.recaptcha_response = recaptchaTokenV2;
    params.recaptcha_action = action;
    params.expected_action = action;
    params.unregistered_question_origin = params.origin;
    const quotationId = generateUUIDv1();

    return restclient
      .post(`${SERVICE_BASE_PATH}/quotations/${itemId}`, {
        params: { ...params, unitId, quotationType },
        data: { unitId, quotationId },
      })
      .then(response => response.data);
  }

  static createUnregisteredQuotation({ itemId, unitId, recaptchaTokenV2, recaptchaTokenV3, action, form }, params) {
    params.recaptcha_token = recaptchaTokenV3;
    params.recaptcha_response = recaptchaTokenV2;
    params.recaptcha_action = action;
    params.expected_action = action;
    params.unregistered_question_origin = params.origin;
    const quotationId = generateUUIDv1();

    return restclient
      .post(`${SERVICE_BASE_PATH}/quotations/unregistered/${itemId}`, {
        params: { ...params, unitId },
        data: { unitId, quotationId, form },
      })
      .then(response => response.data);
  }

  static searchQADB({ item_id, query, product_id }) {
    const escapedEncodedQuery = encodeURIComponent(query).replace(/\?/g, '%3F');

    const url = product_id
      ? `${SERVICE_BASE_PATH}/products/qadb/${product_id}/${item_id}/${escapedEncodedQuery}`
      : `${SERVICE_BASE_PATH}/items/qadb/${item_id}/${escapedEncodedQuery}`;

    return restclient.get(url).then(response => response.data);
  }

  static getQADB({ item_id, product_id }) {
    const url = product_id
      ? `${SERVICE_BASE_PATH}/products/qadb/${product_id}/${item_id}`
      : `${SERVICE_BASE_PATH}/items/qadb/${item_id}`;

    return restclient.get(url).then(response => response.data);
  }

  static getItem(params) {
    return restclient.get(`${SERVICE_BASE_PATH}/items`, { params }).then(response => response.data);
  }

  static getItemUpdateVariation(id, params) {
    return restclient
      .get(`${SERVICE_BASE_PATH}/items/${id}/update/variation`, { params })
      .then(response => response.data);
  }

  static getItemVariations(params) {
    return Service.getItem({ ...params, components_ids: 'variations' });
  }

  static getItemQuestions(itemId, params) {
    return restclient.get(`${SERVICE_BASE_PATH}/questions/list/${itemId}`, { params }).then(response => response.data);
  }

  static getSellerContactInfo(
    itemId,
    contactType,
    recaptchaV3Token,
    recaptchav2token,
    ajaxBackendParams,
    action,
    params = {},
  ) {
    params.recaptchaToken = recaptchaV3Token;
    params.recaptcha_response = recaptchav2token;
    params.ajaxBackendParams = ajaxBackendParams;
    params.action = action;
    return restclient
      .get(`${SERVICE_BASE_PATH}/items/${itemId}/contact-info/${contactType}`, { params })
      .then(response => response.data);
  }

  static getModalQuestions(itemId, params) {
    return restclient.get(`${SERVICE_BASE_PATH}/questions/${itemId}/modal`, { params }).then(response => response.data);
  }

  static getModalUnregisteredQuestions(itemId, params) {
    return restclient
      .get(`${SERVICE_BASE_PATH}/questions/unregistered/${itemId}/modal`, { params })
      .then(response => response.data);
  }

  static getDeferredComponent(params) {
    return restclient.get(`${SERVICE_BASE_PATH}/deferred`, { params }).then(response => response.data);
  }

  static getCompatibleValues(domainId, labelId, values = [], params = {}) {
    return restclient
      .post(`${SERVICE_BASE_PATH}/compatibilities/${domainId}/label/${labelId}/values`, {
        params,
        data: values,
      })
      .then(response => response.data);
  }

  static searchCompatibilities(itemId, domainId, values, params) {
    return restclient
      .post(`${SERVICE_BASE_PATH}/compatibilities/${domainId}/items/${itemId}/search`, {
        params,
        data: values,
      })
      .then(response => response.data);
  }

  static redirectToLogin(itemId, loginType, origin, params = {}) {
    params.itemId = itemId;
    const baseUrl = new URL(window.location.href);
    baseUrl.searchParams.set('backtologin', true);
    baseUrl.hash = origin;
    params.itemLink = baseUrl.toString();
    params.loginType = loginType;
    params.origin = origin;
    return restclient.get(`${SERVICE_BASE_PATH}/items/goToLogin`, { params });
  }

  static getShippingCalculatorOptions(params) {
    return restclient.get(`${SERVICE_BASE_PATH}/shipping-calculator`, { params }).then(response => response.data);
  }

  static addToCartUpdate(data, params) {
    return restclient
      .put(`${SERVICE_BASE_PATH}/products/cart/update`, { data, params })
      .then(response => transformRemainingCountdowns(response.data));
  }

  static addToCartModal(data, itemId) {
    return restclient.post(`/${itemId}/add-to-cart`, { data });
  }

  static buyNowOnePayForAll(params) {
    return restclient.get(`${SERVICE_BASE_PATH}/one-pay-for-all`, { params }).then(response => response.data);
  }

  static updateTradeIn(id) {
    return restclient.get(`${SERVICE_BASE_PATH}/products/${id}/update/trade-in`).then(response => response.data);
  }

  static removeTradeIn(id, tradeInId) {
    return restclient
      .delete(`${SERVICE_BASE_PATH}/products/${id}/trade-in/${tradeInId}`)
      .then(response => response.data);
  }

  static postQuestion(itemId, params) {
    const questionId = generateUUIDv1();

    return restclientQuestionsAi
      .get(`${SERVICE_BASE_PATH}/questions-ai/${itemId}`, { params: { questionId, ...params } })
      .then(response => response.data);
  }

  static updateCardOnQuantityChange(itemId, params) {
    const url = `${SERVICE_BASE_PATH}/questions-ai/${itemId}/update`;

    return restclient.get(url, { params }).then(response => response.data);
  }

  static likeDislikeQuestionsAi(itemId, params) {
    return restclient
      .post(`${SERVICE_BASE_PATH}/questions-ai/${itemId}/feedback`, { params })
      .then(response => response.data);
  }

  static getSequencerOnePayForAll(data) {
    return restclient.post(`${SERVICE_BASE_PATH}/one-pay-for-all/sequencer`, { data }).then(response => response.data);
  }

  static getVehicleHistoryModal(itemId) {
    return restclient
      .get(`${SERVICE_BASE_PATH}/products/${itemId}/modal/vehicle-history`)
      .then(response => response.data);
  }

  static saveFrontendStatsd(data) {
    return restclient.post(`${SERVICE_BASE_PATH}/frontend-statsd`, { data }).then(response => response.data);
  }
}

module.exports = Service;
exports = module.exports;
exports.restclient = restclient;
