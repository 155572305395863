/* Questions */
const CREATE_QUESTION_START = 'CREATE_QUESTION_START';
const CREATE_QUESTION_COMPLETE = 'CREATE_QUESTION_COMPLETE';
const CREATE_QUESTION_FROM_AI_COMPLETE = 'CREATE_QUESTION_FROM_AI_COMPLETE';
const CREATE_QUESTION_ERROR = 'CREATE_QUESTION_ERROR';

const FETCH_START = 'FETCH_START';
const FETCH_COMPLETE = 'FETCH_COMPLETE';
const FETCH_END = 'FETCH_END';
const FETCH_ERROR = 'FETCH_ERROR';
const FETCH_ERROR_SNACK = 'FETCH_ERROR_SNACK';
const FETCH_ERROR_SNACK_HIDE = 'FETCH_ERROR_SNACK_HIDE';
const FETCH_DEFERRED_START = 'FETCH_DEFERRED_START';
const FETCH_DEFERRED_SUCCESS = 'FETCH_DEFERRED_SUCCESS';
const FETCH_DEFERRED_ERROR = 'FETCH_DEFERRED_ERROR';

/* Quantity */
const FETCH_ON_SET_QUANTITY_START = 'FETCH_ON_SET_QUANTITY_START';
const FETCH_ON_SET_QUANTITY_COMPLETE = 'FETCH_ON_SET_QUANTITY_COMPLETE';
const QUANTITY_SHOW_INPUT_CHANGE = 'QUANTITY_SHOW_INPUT_CHANGE';

/* A2C */
const ADD_TO_CART_UPDATE_START = 'ADD_TO_CART_UPDATE_START';
const ADD_TO_CART_UPDATE_COMPLETE = 'ADD_TO_CART_UPDATE_COMPLETE';
const ADD_TO_CART_UPDATE_ERROR = 'ADD_TO_CART_UPDATE_ERROR';

/* Shipping */
const FETCH_SHIPPING_PROMISE_START = 'FETCH_SHIPPING_PROMISE_START';
const FETCH_SHIPPING_PROMISE_COMPLETE = 'FETCH_SHIPPING_PROMISE_COMPLETE';

/* Variations */
const FETCH_PRODUCT_VARIATIONS_DETAILS = 'FETCH_PRODUCT_VARIATIONS_DETAILS';
const TOGGLE_BOOKMARK_OPTIMISTICALLY = 'TOGGLE_BOOKMARK_OPTIMISTICALLY';
const SHOW_QUANTITY_ERROR = 'SHOW_QUANTITY_ERROR';
const SHOW_VARIATIONS_ERROR_DESKTOP = 'SHOW_VARIATIONS_ERROR_DESKTOP';
const SHOW_VARIATIONS_ERROR_MOBILE = 'SHOW_VARIATIONS_ERROR_MOBILE';

const FETCH_ON_SET_BOOKMARK_COMPLETE = 'FETCH_ON_SET_BOOKMARK_COMPLETE';
const LIKE_REVIEW_OPTIMISTICALLY = 'LIKE_REVIEW_OPTIMISTICALLY';
const LIKE_REVIEW_ROLLBACK = 'LIKE_REVIEW_ROLLBACK';
const DISLIKE_REVIEW_OPTIMISTICALLY = 'DISLIKE_REVIEW_OPTIMISTICALLY';
const DISLIKE_REVIEW_ROLLBACK = 'DISLIKE_REVIEW_ROLLBACK';

const TOGGLE_BOOKMARKED = 'TOGGLE_BOOKMARKED';

/* Followers */
const TOGGLE_FOLLOW_OPTIMISTICALLY = 'TOGGLE_FOLLOW_OPTIMISTICALLY';
const FETCH_ON_SET_FOLLOW_COMPLETE = 'FETCH_ON_SET_FOLLOW_COMPLETE';

/* Recommendations */
const SET_RECOMMENDATIONS_CONTEXT = 'SET_RECOMMENDATIONS_CONTEXT';

/* Cart Congrats */
const BOTTOMSHEET_MODAL_UPDATE = 'BOTTOMSHEET_MODAL_UPDATE';
const BOTTOMSHEET_MODAL = 'BOTTOMSHEET_MODAL';
const BOTTOMSHEET_MODAL_CLOSE = 'BOTTOMSHEET_MODAL_CLOSE';
const FETCH_BOTTOMSHEET_MODAL = 'FETCH_BOTTOMSHEET_MODAL';

/* Coupons */
const FETCH_ON_COUPON_UPDATE_START = 'FETCH_ON_COUPON_UPDATE_START';
const FETCH_ON_COUPON_UPDATE_COMPLETE = 'FETCH_ON_COUPON_UPDATE_COMPLETE';
const FETCH_ON_COUPON_UPDATE_ERROR = 'FETCH_ON_COUPON_UPDATE_ERROR';
const ON_COUPON_BUYBOX_OFFERS_UPDATE = 'ON_COUPON_BUYBOX_OFFERS_UPDATE';

/* Meliplus */
const FETCH_ON_MELIPLUS_UPDATE_START = 'FETCH_ON_MELIPLUS_UPDATE_START';
const FETCH_ON_MELIPLUS_UPDATE_COMPLETE = 'FETCH_ON_MELIPLUS_UPDATE_COMPLETE';

/* Trade in */
const FETCH_ON_CHANGE_TRADE_IN_START = 'FETCH_ON_CHANGE_TRADE_IN_START';
const FETCH_ON_CHANGE_TRADE_IN_COMPLETE = 'FETCH_ON_CHANGE_TRADE_IN_COMPLETE';

const SHOW_SNACKBAR = 'SHOW_SNACKBAR';
const HIDE_SNACKBAR = 'HIDE_SNACKBAR';

/* 1pay4all */
const FETCH_ONE_PAY_FOR_ALL = 'FETCH_ONE_PAY_FOR_ALL';
const ONE_PAY_FOR_ALL_SHOW_MODAL = 'ONE_PAY_FOR_ALL_SHOW_MODAL';
const ONE_PAY_FOR_ALL_IFRAME_LOADED = 'ONE_PAY_FOR_ALL_IFRAME_LOADED';

/* Wishlist */
const WISHLIST_SHOW_BOTTOMSHEET_MODAL = 'WISHLIST_SHOW_BOTTOMSHEET_MODAL';
const WISHLIST_CLOSE_BOTTOMSHEET_MODAL = 'WISHLIST_CLOSE_BOTTOMSHEET_MODAL';
const UPDATE_GIFT_REGISTRY = 'UPDATE_GIFT_REGISTRY';
const UPDATE_GIFT_REGISTRY_CHECKBOX = 'UPDATE_GIFT_REGISTRY_CHECKBOX';

/* Questions AI */
const FETCH_QUESTION_AI_START = 'FETCH_QUESTION_AI_START';
const FETCH_QUESTION_AI_COMPLETE = 'FETCH_QUESTION_AI_COMPLETE';
const FETCH_UPDATE_QUANTITY = 'FETCH_UPDATE_QUANTITY';
const FETCH_UPDATE_CARD = 'FETCH_UPDATE_CARD';
const FETCH_QUESTION_AI_ERROR = 'FETCH_QUESTION_AI_ERROR';

module.exports = {
  CREATE_QUESTION_START,
  CREATE_QUESTION_COMPLETE,
  CREATE_QUESTION_FROM_AI_COMPLETE,
  CREATE_QUESTION_ERROR,
  FETCH_START,
  FETCH_COMPLETE,
  FETCH_END,
  FETCH_ERROR,
  FETCH_ERROR_SNACK,
  FETCH_ERROR_SNACK_HIDE,
  FETCH_PRODUCT_VARIATIONS_DETAILS,
  FETCH_ON_SET_QUANTITY_START,
  FETCH_ON_SET_QUANTITY_COMPLETE,
  FETCH_SHIPPING_PROMISE_START,
  FETCH_SHIPPING_PROMISE_COMPLETE,
  QUANTITY_SHOW_INPUT_CHANGE,
  ADD_TO_CART_UPDATE_START,
  ADD_TO_CART_UPDATE_COMPLETE,
  ADD_TO_CART_UPDATE_ERROR,
  TOGGLE_BOOKMARK_OPTIMISTICALLY,
  FETCH_ON_SET_BOOKMARK_COMPLETE,
  LIKE_REVIEW_OPTIMISTICALLY,
  LIKE_REVIEW_ROLLBACK,
  DISLIKE_REVIEW_OPTIMISTICALLY,
  DISLIKE_REVIEW_ROLLBACK,
  TOGGLE_BOOKMARKED,
  SET_RECOMMENDATIONS_CONTEXT,
  FETCH_DEFERRED_START,
  FETCH_DEFERRED_SUCCESS,
  FETCH_DEFERRED_ERROR,
  SHOW_QUANTITY_ERROR,
  SHOW_VARIATIONS_ERROR_DESKTOP,
  SHOW_VARIATIONS_ERROR_MOBILE,
  FETCH_ON_COUPON_UPDATE_START,
  FETCH_ON_COUPON_UPDATE_COMPLETE,
  FETCH_ON_COUPON_UPDATE_ERROR,
  ON_COUPON_BUYBOX_OFFERS_UPDATE,
  FETCH_ON_MELIPLUS_UPDATE_START,
  FETCH_ON_MELIPLUS_UPDATE_COMPLETE,
  FETCH_ON_CHANGE_TRADE_IN_START,
  FETCH_ON_CHANGE_TRADE_IN_COMPLETE,
  SHOW_SNACKBAR,
  HIDE_SNACKBAR,
  BOTTOMSHEET_MODAL_UPDATE,
  BOTTOMSHEET_MODAL,
  BOTTOMSHEET_MODAL_CLOSE,
  FETCH_BOTTOMSHEET_MODAL,
  FETCH_ONE_PAY_FOR_ALL,
  ONE_PAY_FOR_ALL_SHOW_MODAL,
  ONE_PAY_FOR_ALL_IFRAME_LOADED,
  TOGGLE_FOLLOW_OPTIMISTICALLY,
  FETCH_ON_SET_FOLLOW_COMPLETE,
  WISHLIST_SHOW_BOTTOMSHEET_MODAL,
  WISHLIST_CLOSE_BOTTOMSHEET_MODAL,
  FETCH_QUESTION_AI_START,
  FETCH_QUESTION_AI_COMPLETE,
  FETCH_UPDATE_QUANTITY,
  FETCH_UPDATE_CARD,
  FETCH_QUESTION_AI_ERROR,
  UPDATE_GIFT_REGISTRY,
  UPDATE_GIFT_REGISTRY_CHECKBOX,
};
