const { loadable } = require('nordic/lazy');
const compose = require('../../../../lib/compose');
const connect = require('../../../../store/connect');
const withFeatureFlag = require('../../../../components/feature-flag/with-feature-flag');
const actions = require('../../../../actions');

const BuyBoxOffers = loadable(() => import('../../../../components/buy-box-offers'));

const FEATURE_NAME = 'buy_box_offers';

const mapStateToProps = ({ components: { [FEATURE_NAME]: buyBoxOffers } }, ownProps) => ({
  ...buyBoxOffers,
  ...ownProps,
});

const mapDispatchToProps = dispatch => ({
  onPaymentMethodSelected: (e, offer_type) => {
    e.preventDefault();
    return dispatch(actions.selectPaymentMethod(offer_type));
  },
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

module.exports = enhance(BuyBoxOffers);
