const { arrayOf, number, string, shape } = require('prop-types');

const Label = shape({
  text: string.isRequired,
  color: string,
  font_size: string,
  font_family: string,
});

const ComposedLabel = shape({
  text: string.isRequired,
  color: string,
  font_size: string,
  font_family: string,
  bg_color: string,
  values: shape({}),
  text_icon: shape({
    id: string.isRequired,
  }),
});

const Action = shape({
  label: Label.isRequired,
  icon: shape({
    id: string,
    color: string,
  }),
  target: string.isRequired,
  track: shape({}),
});

const Remaining = shape({
  initial_time: number.isRequired,
  total_milliseconds: number.isRequired,
  hours: number.isRequired,
  minutes: number.isRequired,
  pay_before_hour: number.isRequired,
  labels: arrayOf(
    shape({
      id: string.isRequired,
      text: string.isRequired,
      color: string.isRequired,
      values: shape({}),
    }),
  ).isRequired,
});

module.exports = {
  Action,
  ComposedLabel,
  Label,
  Remaining,
};
