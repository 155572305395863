const React = require('react');
const Grid = require('../../../../../../components/grid');

const RecommendationsComparator = require('../../../../features/recommendations/comparator.mobile');
const DynamicComponentWrapper = require('../../../../../vip/features/dynamic-component-wrapper');
const { spotMiddleMobileRender } = require('../../../../../../utils/spotMiddleRenders');

const RecommendationsMiddleChunk = () => (
  <>
    <Grid.Row>
      <DynamicComponentWrapper
        renderDynamicComponent={spotMiddleMobileRender({
          firstChildClassName: 'mb-40',
          lastChildClassName: 'ui-pdp-with--separator',
          middleChildClassName: 'mt-40 mb-40',
        })}
        className="ui-pdp-spot-middle"
        spot="spot_middle"
      />
    </Grid.Row>
    <Grid.Row className="ui-pdp-with--separator pdp-mobile-recos--comparator">
      <RecommendationsComparator />
    </Grid.Row>
  </>
);

module.exports = RecommendationsMiddleChunk;
