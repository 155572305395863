const { loadable } = require('nordic/lazy');
const compose = require('../../../../../lib/compose');
const connect = require('../../../../../store/connect');
const withFeatureFlag = require('../../../../../components/feature-flag/with-feature-flag');
const actions = require('../../../../../actions');

const PickUpSummary = loadable(() => import('../../../../../components/buybox/pick-up'));

const FEATURE_NAME = 'pick_up_summary';

const PICK_UP_PROPS = {
  id: FEATURE_NAME,
};

const mapStateToProps = ({ components: { [FEATURE_NAME]: pickUpSummary } }, ownProps) => ({
  ...pickUpSummary,
  ...ownProps,
  ...PICK_UP_PROPS,
});

const serializeOwnProps = (state, ownProps) => ({
  ...ownProps,
  ...PICK_UP_PROPS,
});

const mapDispatchToProps = dispatch => ({
  onShippingPromiseUpdate: () => dispatch(actions.fetchShippingPromise()),
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

const noFeatureFlagConnect = connect(
  serializeOwnProps,
  mapDispatchToProps,
);

module.exports = enhance(PickUpSummary);
module.exports.noFeatureFlag = noFeatureFlagConnect(PickUpSummary);
