const {
  getFormattedTrackingData,
  dispatchSellerPixelsInfo,
  dispatchAddToCardShopsInfo,
} = require('@vpp-frontend-components/common');

module.exports = {
  getFormattedTrackingData,
  dispatchSellerPixelsInfo,
  dispatchAddToCardShopsInfo,
};
