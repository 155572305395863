const { useEffect } = require('react');
const { dispatchSellerPixelsInfo, getFormattedTrackingData } = require('../utils/mercadoShopsTracking');

const useMShopsTrackingInfo = (trackingData, variationId) => {
  useEffect(() => {
    if (trackingData && window.tracking_mediator) {
      const shopsTrackingData = getFormattedTrackingData(trackingData, variationId);
      dispatchSellerPixelsInfo(window.tracking_mediator, shopsTrackingData);
    }
  }, [trackingData, variationId]);
};

module.exports = useMShopsTrackingInfo;
