const React = require('react');
const PropTypes = require('prop-types');
const classnames = require('classnames');
const { PROVIDER } = require('../figure/constants');
const { generateKeyToComponent } = require('../../utils/validators');

const namespace = 'dots-navigation';
const SHAPES = {
  TRIANGLE: 'triangle',
  CIRCLE: 'circle',
};

const getShapes = multimedia =>
  multimedia.map(({ provider }) => (provider === PROVIDER.CLIP ? SHAPES.TRIANGLE : SHAPES.CIRCLE));

const farNeighborLevel = (index, currentIndex, level, exact = true) => {
  const farNeighborAfter = currentIndex + level;
  const farNeighborBefore = currentIndex - level;

  if (exact) {
    return index === farNeighborAfter || index === farNeighborBefore;
  }

  return index >= farNeighborAfter || index <= farNeighborBefore;
};

const dotClassName = ({ currentIndex, index }) => {
  const isActive = index === currentIndex;

  return classnames({
    [`${namespace}--active`]: isActive,
  });
};

const dotWrapperClassName = ({ currentIndex, index, shape, length }) => {
  if (length <= 6) {
    return `${namespace}__${shape}`;
  }

  const isDistantNeighbor1 = farNeighborLevel(index, currentIndex, 2);
  const isDistantNeighbor2 = farNeighborLevel(index, currentIndex, 3);
  const isDistantNeighbor3 = farNeighborLevel(index, currentIndex, 4, false);

  return classnames(`${namespace}__${shape}`, {
    [`${namespace}--distance-1`]: isDistantNeighbor1,
    [`${namespace}--distance-2`]: isDistantNeighbor2,
    [`${namespace}--distance-3`]: isDistantNeighbor3,
  });
};

const dotsGroupClassName = type => classnames(namespace, { [`${namespace}--${type}-theme`]: type });

const DotsNavigation = props => {
  const { index: currentIndex, shapes, theme } = props;

  return (
    <ul className={dotsGroupClassName(theme)}>
      {shapes.map((shape, index, { length }) => (
        <li key={generateKeyToComponent(shape)} className={dotWrapperClassName({ currentIndex, index, shape, length })}>
          <span className={dotClassName({ currentIndex, index })} />
        </li>
      ))}
    </ul>
  );
};

DotsNavigation.propTypes = {
  index: PropTypes.number.isRequired,
  shapes: PropTypes.arrayOf(PropTypes.string).isRequired,
  theme: PropTypes.string,
};

DotsNavigation.defaultProps = {
  index: 0,
  shapes: [],
};

module.exports = DotsNavigation;
exports = module.exports;
exports.getShapes = getShapes;
