const {
  trackPage,
  trackEvent,
  trackQuantityEvent,
  trackEventWithCustomField,
  trackEventWithCustomFields,
  trackEventOnComponentPrint,
} = require('@vpp-frontend-components/common');

module.exports = {
  trackPage,
  trackEvent,
  trackQuantityEvent,
  trackEventWithCustomField,
  trackEventWithCustomFields,
  trackEventOnComponentPrint,
};
