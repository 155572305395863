const React = require('react');
const { string, bool, node, oneOf } = require('prop-types');
const { Message: AndesMessage } = require('@andes/message');
const classnames = require('classnames');
const { validateSnackbarColor } = require('../../utils/validators');

const namespace = 'ui-pdp-message';
const namecolorspace = 'ui-pdp-background-color';

const Message = ({
  type,
  title,
  hierarchy,
  closeable,
  className,
  children,
  text,
  actionsPosition,
  actions,
  bgColor,
}) => (
  <AndesMessage
    className={classnames(namespace, className, {
      [`${namecolorspace}--${bgColor}`]: bgColor,
      [`${namespace}--closeable`]: closeable,
    })}
    color={validateSnackbarColor(type)}
    hierarchy={hierarchy}
    closable={closeable}
    title={title}
    actionsPosition={actionsPosition}
    actions={actions}
  >
    {text || children}
  </AndesMessage>
);

Message.propTypes = {
  className: string,
  text: string,
  type: oneOf(['neutral', 'success', 'warning', 'error']),
  title: string,
  hierarchy: string,
  closeable: bool,
  children: node,
  actionsPosition: string,
  actions: node,
  bgColor: string,
};

Message.defaultProps = {
  className: null,
  title: null,
  text: null,
  type: 'neutral',
  hierarchy: 'quiet',
  closeable: false,
  actions: null,
  actionsPosition: 'bottom',
  bgColor: null,
};

module.exports = Message;
