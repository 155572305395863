const compose = require('../../../../lib/compose');
const connect = require('../../../../store/connect');
const withFeatureFlag = require('../../../../components/feature-flag/with-feature-flag');
const withRow = require('../../../../components/grid/row/with-row');
const Integrations = require('../../../../components/integrations');

const FEATURE_NAME = 'third_party_first_section';

const mapStateToProps = ({ components: { [FEATURE_NAME]: integrations } }) => integrations;

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  withRow(FEATURE_NAME),
  connect(mapStateToProps),
);

module.exports = enhance(Integrations);
