const compose = require('../../../../lib/compose');
const connect = require('../../../../store/connect');
const withFeatureFlag = require('../../../../components/feature-flag/with-feature-flag');
const withRow = require('../../../../components/grid/row/with-row');
const Breadcrumb = require('../../../../components/breadcrumb');

const FEATURE_NAME = 'breadcrumb';

const mapStateToProps = ({ components: { [FEATURE_NAME]: props } }, { mode }) => ({ ...props, mode });

const enhance = compose(
  withFeatureFlag(FEATURE_NAME, { loader: false }),
  withRow(FEATURE_NAME),
  connect(mapStateToProps),
);

module.exports = enhance(Breadcrumb);
