const React = require('react');

const Grid = require('../../../../../../components/grid');

const HighlightedSpecsFeatures = require('../../../../features/highlighted-specs/highlighted-specs-features.mobile');
const HighlightedSpecsAttrs = require('../../../../features/highlighted-specs/highlighted-specs-attrs.mobile');

const HighlightedSpecsChunk = () => (
  <>
    <HighlightedSpecsFeatures />
    <Grid.Row className="ui-pdp-html-description ui-pdp-with--separator">
      <HighlightedSpecsAttrs />
    </Grid.Row>
  </>
);

module.exports = HighlightedSpecsChunk;
