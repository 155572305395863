const compose = require('../../../../lib/compose');
const connect = require('../../../../store/connect');
const withFeatureFlag = require('../../../../components/feature-flag/with-feature-flag');

const FEATURE_NAME = 'header_cpg';

const mapStateToProps = ({ components: { [FEATURE_NAME]: header_cpg } }) => ({ ...header_cpg });

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(mapStateToProps),
);

module.exports = CPGComponent => enhance(CPGComponent);
