const React = require('react');
const { number, node, string, shape } = require('prop-types');
const get = require('lodash/get');

const trackNewrelic = require('../services/client/new-relic.client');
const history = require('../services/history').getHistory();
const SnackBarError = require('../components/snackbar');
const { messagesErrorScreen } = require('../lib/get-message-error');

class GlobalErrorBoundary extends React.Component {
  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.error && get(nextProps, 'error.response.status') !== get(prevState, 'error.response.status')) {
      return { hasError: true, error: nextProps.error.response };
    }
    return null;
  }

  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  componentDidMount() {
    this.unlisten = history.listen((location, action) => {
      /* if current action is POP we reload the page */
      if (action === 'POP') {
        /*
         * if previous stored pathname/search is not equal to the current pathname/search trigger history change,
         * search may be different if quantity changes
         */
        if (this.location.pathname !== location.pathname || this.location.search !== location.search) {
          /*
           * ideally we should get id from state, if isn't available in the state fallback to pathname
           * e.g /p/MLA9652755?quantity=1, /p/MLA9652755/s?quantity=1
           * for now we reload the page in a future we should be able to cancel the current action's request
           * and trigger a new action without reloading the page
           * */
          window.location.reload();
        }
      }
      this.location = location;
    });
    /* save initial location */
    this.location = history.location;
  }

  componentDidCatch(error, info) {
    // log error to new relic if it's an ui error or diff from 404 || 500
    trackNewrelic({ error, info });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  render() {
    const { children, siteId, error } = this.props;
    const { hasError } = this.state;
    const { title, description } = messagesErrorScreen(siteId);
    if (error) {
      return (
        <>
          {children}
          <SnackBarError show message={`${title} - ${description}`} />
        </>
      );
    }
    if (hasError) {
      return <SnackBarError show message={`${title} - ${description}`} />;
    }
    return children;
  }
}

GlobalErrorBoundary.propTypes = {
  children: node.isRequired,
  error: shape({
    status: number,
    statusText: string,
  }),
  siteId: string,
};

GlobalErrorBoundary.defaultProps = {
  error: null,
  siteId: null,
};

module.exports = GlobalErrorBoundary;
