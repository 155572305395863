const arrayToObjectById = data =>
  data.reduce((acc, item) => {
    if (item.state === 'VISIBLE') {
      acc[item.id] = item;
    }
    return acc;
  }, {});

module.exports = {
  arrayToObjectById,
};
