const React = require('react');

const ComponentFactoryFrom = sources => {
  const ComponentFactory = data => {
    const { id } = data;
    const source = sources[id];
    if (!source) {
      return null;
    }
    const props = data;
    return React.createElement(source, props);
  };

  return ComponentFactory;
};

module.exports = ComponentFactoryFrom;
