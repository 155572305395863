const { loadable } = require('nordic/lazy');
const compose = require('../../../../lib/compose');
const connect = require('../../../../store/connect');
const withFeatureFlag = require('../../../../components/feature-flag/with-feature-flag');

const FullscreenAd = loadable(() => import('../../../../components/advertising'));

const FEATURE_NAME = 'fullscreen_ad';
const className = 'ui-fullscreen-ad-pdp';

const mapStateToProps = (
  {
    components: {
      [FEATURE_NAME]: {
        slot_id: slotId,
        google_ad: googleAd,
        caption,
        segmentation,
        viewport_margin: viewportMargin,
        personal_data_ads_denied,
        track: { melidata_event } = {},
      },
    },
    userConsentCookie,
  },
  ownProps,
) => ({
  slotId,
  viewportMargin,
  personalDataAdsDenied: personal_data_ads_denied,
  googleAd: {
    ...googleAd,
  },
  segmentation: {
    custID: segmentation.cust_id,
    Posiciones: segmentation.position,
    platform: segmentation.platform,
  },
  className,
  caption,
  melidataEvent: melidata_event,
  userConsentCookie,
  ...ownProps,
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(mapStateToProps),
);

module.exports = enhance(FullscreenAd);
