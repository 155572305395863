const React = require('react');
const { useState } = require('react');
const { shape, string, node, bool } = require('prop-types');
const classNames = require('classnames');
const StyledLabel = require('../styled-label');
const TooltipLegal = require('../tooltip-legal/tooltip-legal.mobile');

const IconFactory = require('../icons/factory-builder')();
const colornames = require('../../lib/colornames');

const namespace = 'ui-pdp-moq-information';

const MoqInformation = ({ className, collapsible_tooltip, subtitle }) => {
  const [showMOQTooltip, setShowMOQTooltip] = useState(false);
  const handleShowTooltip = e => {
    e.preventDefault();
    if (!showMOQTooltip) {
      setShowMOQTooltip(true);
    }
  };

  return (
    <div className={classNames(`${namespace}`, className)}>
      <div className={`${namespace}__container`}>
        {subtitle && <StyledLabel className={`${namespace}__subtitle`} {...subtitle} />}
        {collapsible_tooltip && (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events
          <span
            data-testid="info-icon"
            className={`${namespace}__container__info-icon`}
            onClick={e => handleShowTooltip(e)}
            role="button"
            tabIndex="-1"
          >
            {IconFactory(collapsible_tooltip.icon.id, colornames(collapsible_tooltip.icon.color))}
          </span>
        )}
      </div>
      {collapsible_tooltip && (
        <TooltipLegal
          collapsible_tooltip={collapsible_tooltip}
          showMOQTooltip={showMOQTooltip}
          setShowMOQTooltip={setShowMOQTooltip}
        />
      )}
    </div>
  );
};

MoqInformation.propTypes = {
  className: string,
  collapsible_tooltip: shape({
    icon: shape({
      id: string,
      color: string,
      size: string,
    }),
    opened: bool,
    tooltip: shape({
      arrow_type: string,
      background_color: string,
      change_color: bool,
      close_color: string,
      closeable: bool,
      closed: bool,
      has_shadow: bool,
      state: string,
      storable: bool,
      subtitles: shape([
        {
          color: string,
          font_family: string,
          font_size: string,
          text: string,
        },
      ]),
      title: shape({
        color: string,
        font_family: string,
        font_size: string,
        text: string,
      }),
      type: string,
    }),
  }),
  subtitle: node.isRequired,
};

MoqInformation.defaultProps = {
  className: null,
  collapsible_tooltip: null,
  subtitle: null,
};

module.exports = MoqInformation;
