const compose = require('../../../../lib/compose');
const connect = require('../../../../store/connect');
const withFeatureFlag = require('../../../../components/feature-flag/with-feature-flag');
const GalleryMobile = require('../../../../components/gallery/gallery.mobile');
const jsonToProps = require('../../../../components/gallery/json-to-props');
const actions = require('../../../../actions');

const FEATURE_NAME = 'gallery';
const BOOKMARK = 'bookmark';
const WISHLIST_SAVE_BUTTON = 'wishlist_save_button';

const mapStateToProps = states => {
  const {
    id,
    components: {
      [FEATURE_NAME]: gallery,
      [BOOKMARK]: bookmark,
      [WISHLIST_SAVE_BUTTON]: wishlist,
      header: { title },
      share,
    },
  } = states;

  const { csrfToken } = states;

  return {
    ...jsonToProps({ ...gallery, csrfToken, titleForAlt: title, bookmark, share, wishlist }),
    id, // This is the product/item id an overrides the component id, we aren't using the component id
    title,
  };
};
/* istanbul ignore next */
const mapDispatchToProps = dispatch => ({
  removeElementFromList: element => dispatch(actions.removeElementFromList(element)),
  addElementToList: element => dispatch(actions.addElementToList(element)),
  onClickBookmark: e => {
    e.preventDefault();
    return dispatch(actions.toggleBookmark());
  },
  showSnackbar: props => dispatch(actions.showSnackbar({ ...props })),
  openWishlistModalBS: () => dispatch(actions.openWishlistModalBS()),
  closeWishlistModalBS: isBookmarked => dispatch(actions.closeWishlistModalBS(isBookmarked)),
  updateGiftRegistry: check => dispatch(actions.fetchUpdatedWishlists(check)),
  fetchBookmark: () => dispatch(actions.fetchBookmark()),
  toggleGiftRegistryCheckbox: check => dispatch(actions.toggleGiftRegistryCheckbox(check)),
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

module.exports = enhance(GalleryMobile);
