const React = require('react');
const Grid = require('../../../../../../components/grid');
const FullscreenAd = require('../../../../features/fullscreen-ad');

const AdsChunk = () => (
  <Grid.Container>
    <FullscreenAd />
  </Grid.Container>
);

module.exports = AdsChunk;
