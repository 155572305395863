const React = require('react');
const Grid = require('../../../../../../components/grid');

const Returns = require('../../../../features/returns/returns.mobile');
const Benefits = require('../../../../features/benefits');
const OtherProducts = require('../../../../features/other-products/other-products.mobile');

const ReturnChunk = () => (
  <>
    <Grid.Row>
      <Returns className="mt-24 pl-16 pr-16 mb-24" />
      <Benefits className="mt-20 pl-16 pr-16 mb-12" />
    </Grid.Row>
    <Grid.Row>
      <OtherProducts className="mt-20 pl-16 pr-16 mb-12" />
    </Grid.Row>
  </>
);

module.exports = ReturnChunk;
