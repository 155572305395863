const { loadable } = require('nordic/lazy');
const compose = require('../../../../lib/compose');
const connect = require('../../../../store/connect');
const withFeatureFlag = require('../../../../components/feature-flag/with-feature-flag');
const actions = require('../../../../actions');

const TradeIn = loadable(() => import('../../../../components/trade-in'));

const FEATURE_NAME = 'trade_in';

const mapStateToProps = (
  {
    components: {
      [FEATURE_NAME]: { tradeIn, ...rest },
    },
    id: itemId,
  },
  ownProps,
) => ({
  tradeIn: {
    ...tradeIn,
    isFetching: false,
  },
  itemId,
  ...rest,
  ...ownProps,
});

const mapDispatchToProps = dispatch => ({
  onUpdateTradeIn: (e, trade_in_id) => {
    e.preventDefault();
    return dispatch(actions.updateTradeIn(trade_in_id));
  },
  onRemoveTradeIn: (e, trade_in_id) => {
    e.preventDefault();
    return dispatch(actions.removeTradeIn(trade_in_id));
  },
  showSnackbar: trade_snackbar => dispatch(actions.showSnackbar(trade_snackbar)),
  redirectToLogin: (itemId, loginType = 'TradeIn') =>
    dispatch(actions.redirectToLogin({ itemId, featureName: FEATURE_NAME, loginType })),
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

module.exports = enhance(TradeIn);
