const compose = require('../../../../lib/compose');
const connect = require('../../../../store/connect');
const withFeatureFlag = require('../../../../components/feature-flag/with-feature-flag');

const FEATURE_NAME = 'carousel_to';

const mapStateToProps = ({ components: { [FEATURE_NAME]: props } }) => props;

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(mapStateToProps),
);

module.exports = OfficialStores => enhance(OfficialStores);
