const updateArray = require('./update-array');

const updateArrayComponents = (state = {}, payload = {}) => {
  const result = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(state)) {
    if (Array.isArray(value) && Array.isArray(payload[key])) {
      result[key] = updateArray(value, payload[key]);
    }
  }
  return result;
};

module.exports = updateArrayComponents;
