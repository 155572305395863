const { loadable } = require('nordic/lazy');
const ComponentFactoryFrom = require('./utils/component-factory');

const sources = {};

const SELLER_BANNER = 'seller_banner';
const SELLER_HEADER = 'seller_header';
const SELLER_STATUS = 'seller_status';
const SELLER_RECOS = 'seller_recos';
const SELLER_FOOTER = 'seller_footer';

sources[SELLER_BANNER] = loadable(() => import('./components/banner'));
sources[SELLER_HEADER] = loadable(() => import('./components/header'));
sources[SELLER_STATUS] = loadable(() => import('./components/status'));
sources[SELLER_RECOS] = loadable(() => import('./components/recommendations'));
sources[SELLER_FOOTER] = loadable(() => import('./components/footer'));

const ComponentFactory = ComponentFactoryFrom(sources);

module.exports = ComponentFactory;
