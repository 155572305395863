const compose = require('../../../../lib/compose');
const connect = require('../../../../store/connect');
const withFeatureFlag = require('../../../../components/feature-flag/with-feature-flag');
const Reputation = require('../../../../components/seller-reputation-info/index');

const FEATURE_NAME = 'reputation';

const mapStateToProps = ({ components: { [FEATURE_NAME]: reputationseller, id } }) => ({
  ...reputationseller,
  id,
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(mapStateToProps),
);

module.exports = enhance(Reputation);
