const { useEffect, useState } = require('react');
const { shape, number, string } = require('prop-types');

const { trackPage } = require('../../lib/tracking');

const TrackPageVariation = ({ melidata_event, analytics_event, variation_id, id }) => {
  const setId = variation_id || id;
  const [current_id, setCurrentId] = useState(() => setId);

  useEffect(() => {
    if (setId !== current_id) {
      setCurrentId(setId);
      trackPage({ melidata_event, analytics_event });
    }
  }, [melidata_event, analytics_event, current_id, setId]);
  return null;
};

TrackPageVariation.propTypes = {
  analytics_event: shape({}),
  melidata_event: shape({}),
  variation_id: number,
  id: string,
};

TrackPageVariation.defaultProps = {
  analytics_event: null,
  melidata_event: null,
};

module.exports = TrackPageVariation;
