const DEFAULT_GALLERY_LIMIT = 8;
const DEFAULT_GALLERY_SELECTED = 0;
const DEFAULT_LIGHTBOX_SETTINGS = {
  mobile: {
    thumbnailClassname: '.slick-slide',
    arrowEl: false,
    bgOpacity: 0.95,
    barsSize: {
      top: 0,
      bottom: 0,
    },
    loop: false,
  },
  desktop: namespace => ({
    thumbnailClassname: `.${namespace}__figure img`,
    showAnimationDuration: 1,
    showHideOpacity: true,
    barsSize: {
      top: 66,
      bottom: 66,
    },
  }),
};
const ID_CONTAINER = {
  FULL_SCREEN: 'player-fullscreen',
  GALLERY_SCREEN: 'video-container',
};
const GALLERY_MODE = {
  DEFAULT: 'default',
  FULL_SCREEN: 'fullscreen',
};
const GALLERY_TABS = {
  MULTIMEDIA: 'multimedia',
  PICTURE: 'picture',
};

const BLANK_IMAGE = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
const SNACKBARS_ID = { OWN_ERROR: 'OWN_ERROR', CONNECTION_ERROR: 'CONNECTION_ERROR' };

module.exports = {
  BLANK_IMAGE,
  DEFAULT_GALLERY_LIMIT,
  DEFAULT_GALLERY_SELECTED,
  DEFAULT_LIGHTBOX_SETTINGS,
  GALLERY_MODE,
  GALLERY_TABS,
  ID_CONTAINER,
  SNACKBARS_ID,
};
