const React = require('react');
const { string, shape } = require('prop-types');
const { LazyHydrate: Hydrator } = require('nordic/lazy');

/* Base Page */
const PdP = require('./main');

/* Components */
const Grid = require('../../../../../components/grid');

const CPG = require('../../../features/cpg/cpg.mobile');
const Header = require('../../../features/header/header.mobile');
const HeaderTopBrand = require('../../../features/header-top-brand/header-top-brand.mobile');
const Highlights = require('../../../features/highlights');
const Promotions = require('../../../features/promotions');
const PromotionBottom = require('../../../features/promotion-bottom');

const Gallery = require('../../../features/gallery/gallery.mobile');

const VariationsChunk = require('./chunk/variations.mobile');
const VariationsBoxChunk = require('./chunk/variations-box.mobile');
const BuyBoxChunk = require('./chunk/buybox.mobile');
const ReturnChunk = require('./chunk/return.mobile');
const AboveRecommendationsChunk = require('./chunk/above-recommendations.mobile');
const HighlightedSpecsChunk = require('./chunk/highlighted-specs.mobile');
const HighlightedSpecsSwapChunk = require('./chunk/highlighted-specs-swap.mobile');
const HighlightedSpecsThirdSwapChunk = require('./chunk/highlighted-specs-third-swap.mobile');
const { RecommendationsTopChunk } = require('./chunk/recommendations.mobile');
const RecommendationsMiddleChunk = require('./chunk/recommendations-middle.mobile');
const SellerInfoChunk = require('./chunk/seller-info.mobile');
const SellerDataChunk = require('./chunk/seller-data.mobile');
const PaymentsChunk = require('./chunk/payments.mobile');
const QuestionsChunk = require('./chunk/questions.mobile');
const AdsChunk = require('./chunk/ads.mobile');
const RelatedSearches = require('../../../features/related-searches');
const BreadcrumbAndRelatedReviewChunk = require('./chunk/breadcrumb-related-reviews.mobile');
const OnboardingChunk = require('./chunk/onboarding.mobile');
const ApparelSizeSpec = require('./chunk/apparel-size-spec.mobile');
const BillboardAdn = require('../../../../vip/features/billboard-adn');
const OfficialStores = require('./chunk/official-stores.mobile');
const TradeInChunk = require('./chunk/trade-in.mobile');
const DynamicComponentWrapper = require('../../../../vip/features/dynamic-component-wrapper');
const HeaderOfficialStore = require('../../../features/header-official-store/header-official-store.mobile');
// OnePayForAll
const OnePayForAllModalChunk = require('./chunk/onepayforall.modal.mobile');
const SnackbarChunk = require('./chunk/snackbar');
const Reputation = require('../../../features/reputation-seller');
const SellerExperiment = require('../../../features/seller-info/seller-experiment.mobile');
const PriceChunk = require('./chunk/price.mobile');
const BuyBoxOffersChunk = require('../../../features/buy-box-offers');
const WarningPromotionsChunk = require('./chunk/warning-promotions.mobile');
const RecommendationsCarouselByType = require('../../../../vip/features/recommendations/carousel-byType');
const RecommendationsCarouselFreeHorizontal = require('../../../features/recommendations/carousel-free-horizontal');
const RecommendationsCarouselFree = require('../../../../vip/features/recommendations/carousel-free');
const IFrameModal = require('../../../features/iframe-modal/iframemodal');
const Denounce = require('../../../features/denounce');
const DescriptionChunk = require('./chunk/description.mobile');
const ApparelSizeSpecPreviewChunk = require('./chunk/apparel-size-spec-preview.mobile');
const VerticalGalleryChunk = require('./chunk/vertical-gallery.mobile');

const PdPMobile = ({ baseURL, siteId, initialState, ...rest }) => (
  <PdP baseURL={baseURL} siteId={siteId} initialState={initialState} {...rest}>
    <div className="ui-pdp">
      <IFrameModal />
      <Hydrator whenIdle>
        <HeaderOfficialStore />
      </Hydrator>
      <Hydrator whenIdle>
        <OnePayForAllModalChunk />
      </Hydrator>
      <Grid.Container className="ui-pdp-container--pdp">
        <Hydrator whenVisible>
          <OnboardingChunk />
        </Hydrator>
        <Hydrator whenIdle>
          <CPG />
        </Hydrator>
        <Hydrator whenIdle>
          <RecommendationsCarouselFreeHorizontal
            featureName="carousel_pom_top"
            className="ui-pdp-carousel-cheaper"
            lazy={false}
            preload
            fetchPriority="high"
            criticalImages={1}
          />
        </Hydrator>
        <Grid.Row>
          <Hydrator whenIdle>
            <HeaderTopBrand />
          </Hydrator>
          <Hydrator whenIdle>
            <Header>
              <Highlights className="ui-pdp-highlights" mobile />
            </Header>
          </Hydrator>
        </Grid.Row>
        <Grid.Row className="mt-16">
          <Gallery />
        </Grid.Row>
        <Hydrator whenVisible>
          <VariationsBoxChunk />
        </Hydrator>
        <Hydrator whenVisible>
          <ApparelSizeSpec />
        </Hydrator>
        <Hydrator whenVisible>
          <VariationsChunk />
        </Hydrator>
        <Hydrator whenVisible>
          <TradeInChunk />
        </Hydrator>
        <Hydrator whenVisible>
          <WarningPromotionsChunk />
        </Hydrator>
        <Hydrator whenVisible>
          <PriceChunk />
        </Hydrator>
        <Hydrator whenVisible>
          <BuyBoxOffersChunk className="mt-24 ml-16 mr-16" />
        </Hydrator>
        <Hydrator whenVisible>
          <PromotionBottom className="ml-16" mobile />
        </Hydrator>
        <Hydrator whenVisible>
          <Promotions className="ml-16 mt-12" mobile />
        </Hydrator>
        <Hydrator whenVisible>
          <BuyBoxChunk baseURL={baseURL} />
        </Hydrator>
        <Hydrator whenVisible>
          <Reputation />
        </Hydrator>
        <Hydrator whenVisible>
          <SellerExperiment className="mt-24 ml-16 mr-16" />
        </Hydrator>
        <Hydrator whenVisible>
          <ReturnChunk />
        </Hydrator>
        <Hydrator whenVisible>
          <AboveRecommendationsChunk />
        </Hydrator>
        <Hydrator whenVisible>
          <HighlightedSpecsChunk /> {/* FT con highlights (formato actual) */}
        </Hydrator>
        <Hydrator whenIdle>
          <RecommendationsTopChunk />
        </Hydrator>
        <Hydrator whenVisible>
          <SellerInfoChunk />
        </Hydrator>
        <SellerDataChunk />
        <Hydrator whenVisible>
          <ApparelSizeSpecPreviewChunk />
        </Hydrator>
        <Hydrator whenVisible>
          <HighlightedSpecsSwapChunk /> {/* Swap entre la FT a deprecar y la del formato actual <<experimento>> */}
        </Hydrator>
        <Hydrator whenVisible>
          <VerticalGalleryChunk />
        </Hydrator>
        <Hydrator whenVisible>
          <HighlightedSpecsThirdSwapChunk /> {/* Only show in Fashion */}
        </Hydrator>
        <Hydrator whenVisible>
          <DescriptionChunk />
        </Hydrator>
        <Hydrator whenVisible>
          <RecommendationsMiddleChunk />
        </Hydrator>
        <Hydrator whenVisible>
          <PaymentsChunk />
        </Hydrator>
        <Hydrator whenVisible>
          <QuestionsChunk components={initialState.components} />
        </Hydrator>
        <Hydrator whenVisible>
          <OfficialStores />
        </Hydrator>
      </Grid.Container>
      {/* Content Bottom */}
      <div className="ui-pdp-container--bottom">
        <Hydrator whenVisible>
          <Denounce />
        </Hydrator>
        <Grid.Container className="ui-recommendations pdp-mobile-recos-ads">
          <Hydrator whenIdle>
            <DynamicComponentWrapper
              renderDynamicComponent={featureName => (
                <RecommendationsCarouselFree className="mb-64" featureName={featureName} />
              )}
              spot="carousel_bottom"
            />
          </Hydrator>
        </Grid.Container>
        <Hydrator whenIdle>
          <AdsChunk />
        </Hydrator>
        <Hydrator ssrOnly>
          <BillboardAdn />
        </Hydrator>
        <Hydrator whenIdle>
          <RecommendationsCarouselByType featureName="grid_v2p" className="m-16" />
        </Hydrator>
        <Hydrator whenVisible>
          <RelatedSearches />
        </Hydrator>
        <Hydrator whenVisible>
          <BreadcrumbAndRelatedReviewChunk />
        </Hydrator>
        <Hydrator whenIdle>
          <SnackbarChunk />
        </Hydrator>
      </div>
    </div>
  </PdP>
);

PdPMobile.propTypes = {
  baseURL: string.isRequired,
  initialState: shape({}).isRequired,
  siteId: string.isRequired,
};

module.exports = PdPMobile;
