const compose = require('../../lib/compose');
const connect = require('../../store/connect');
const withFeatureFlag = require('../feature-flag/with-feature-flag');
const withRow = require('../grid/row/with-row');

const FEATURE_NAME = 'reviews_capability_v3';

const mapStateToProps = ({ components: { [FEATURE_NAME]: Reviews }, id, app, history }) => ({
  ...Reviews,
  id: history && history.parent_product_id ? history.parent_product_id : id,
  is_item: app !== 'pdp',
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  withRow(FEATURE_NAME),
  connect(mapStateToProps),
);

module.exports = Reviews => enhance(Reviews);
