const React = require('react');
const { arrayOf, shape, string, number } = require('prop-types');

const serialize = require('serialize-javascript');
const { Script } = require('nordic/script');
const { generateKeyToComponent } = require('../../utils/validators');

const Schema = ({ schemaData }) => {
  if (schemaData) {
    return schemaData.map(schema => (
      <Script key={generateKeyToComponent(schema)} type="application/ld+json">
        {serialize(schema, { isJSON: true })}
      </Script>
    ));
  }
  return null;
};

Schema.propTypes = {
  schemaData: arrayOf(
    shape({
      schema: string,
      i: number,
    }),
  ),
};

Schema.defaultProps = {
  schemaData: null,
};

module.exports = Schema;
