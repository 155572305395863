const React = require('react');

const RecommendationsCarouselFree = require('../../../../../vip/features/recommendations/carousel-free');
const RecommendationsCarouselByType = require('../../../../../vip/features/recommendations/carousel-byType');
const RecommendationsCombos = require('../../../../../../components/combos/combos.mobile');
const RecommendationsListDouble = require('../../../../../vip/features/recommendations/list-double');

const RecommendationsTopChunk = () => (
  <>
    <RecommendationsCombos />
    <RecommendationsCarouselByType featureName="carousel_above" className="ui-pdp-with--separator" />
    <RecommendationsListDouble featureName="carousel_above_seller" className="ui-pdp-with--separator list-double-vpp" />
    <RecommendationsCarouselFree featureName="carousel_up" className="ui-pdp-with--separator" />
  </>
);

module.exports = RecommendationsTopChunk;
