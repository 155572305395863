const React = require('react');
const { shape, string } = require('prop-types');
const classnames = require('classnames');
const colornames = require('../../../lib/colornames');
const { setLabel, namespace } = require('../utils');
const IconFactory = require('../../icons/factory-builder')();

const ErrorMessage = ({ error, pickerId }) => {
  const errorMessageRef = React.useRef(null);

  React.useEffect(() => {
    if (errorMessageRef.current) {
      errorMessageRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, []);

  setLabel(pickerId, null);
  return (
    <span
      ref={errorMessageRef}
      data-testid="picker-error-message"
      aria-live="assertive"
      className={classnames(`${namespace}__alert`, colornames(error.color))}
    >
      {error.text_icon && IconFactory(error.text_icon.id, colornames(error.text_icon.color))}
      <span className={`${namespace}__alert-text`}>{error.text}</span>
    </span>
  );
};

ErrorMessage.propTypes = {
  error: shape({
    color: string,
    text_icon: shape({
      id: string,
      color: string,
    }),
    text: string,
  }).isRequired,
  pickerId: string,
};

module.exports = ErrorMessage;
