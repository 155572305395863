const compose = require('../../../../lib/compose');
const baseConnect = require('../../../../store/base-connect');
const withFeatureFlag = require('../../../../components/feature-flag/with-feature-flag');

const FEATURE_NAME = 'reviews';

const mapStateToProps = ({ components: { [FEATURE_NAME]: reviews }, id }, ownProps) => ({
  className: ownProps.className,
  key: id,
  title: reviews.title,
  rating: {
    average: reviews.rating,
    label: reviews.subtitle,
    levels: reviews.rating_levels,
    stars: reviews.stars,
  },
  comments: {
    tabs: reviews.tabs,
    label: reviews.all_reviews.label,
  },
  allReviews: reviews.all_reviews,
  newReview: reviews.new_review,
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  baseConnect(mapStateToProps),
);

module.exports = Component => enhance(Component);
