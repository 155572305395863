const { loadable } = require('nordic/lazy');
const { withBoxComponent } = require('../../../../components/box-component/with-box-component.mobile');

const ApparelSizeSpecsPreview = loadable(() =>
  import('../../../../components/apparel-size-specs-preview/apparel-size-specs-preview.mobile'),
);

const ApparelSizeSpecsPreviewBox = withBoxComponent()(ApparelSizeSpecsPreview);

module.exports = require('./apparel-size-specs-preview')(ApparelSizeSpecsPreviewBox);
