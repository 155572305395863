const compose = require('../../../../lib/compose');
const connect = require('../../../../store/connect');
const withFeatureFlag = require('../../../../components/feature-flag/with-feature-flag');
const actions = require('../../../../actions');

const FEATURE_NAME = 'html_description';

const mapStateToProps = ({ components: { [FEATURE_NAME]: description }, pathName }, ownProps) => ({
  ...description,
  maxPages: description.max_pages,
  ...ownProps,
  pathName,
});

const mapDispatchToProps = dispatch => ({
  onFetchDeferredComponent: component => dispatch(actions.fetchDeferredComponent(component)),
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

module.exports = Component => enhance(Component);
