const React = require('react');
const Grid = require('../../../../../../components/grid');

const ApparelSizeSpecPreview = require('../../../../features/apparel-size-spec-preview/apparel-size-specs-preview.mobile');

const ApparelSizeSpecPreviewChunk = () => (
  <Grid.Row className="ui-pdp-with--separator">
    <ApparelSizeSpecPreview />
  </Grid.Row>
);

module.exports = ApparelSizeSpecPreviewChunk;
