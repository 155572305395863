const React = require('react');
const { string, shape } = require('prop-types');

const StyledLabel = require('../styled-label');
const MediaAction = require('../media/media-action');
const componentEnhance = require('../../lib/component-enhance');

const namespace = 'ui-vpp-denounce';

const Denounce = ({ item_info, action, label: codeInternalLabel }) => (
  <div className={namespace}>
    {codeInternalLabel && (
      <StyledLabel
        key={codeInternalLabel.text}
        as="p"
        className={`${namespace}__code-internal`}
        {...codeInternalLabel}
      />
    )}
    <StyledLabel
      as="p"
      color={item_info.color}
      className={`${namespace}__info`}
      text={componentEnhance.jsx(item_info.text, item_info.values)}
    />
    {action && (
      <MediaAction
        target={action.target}
        htmlTarget="_blank"
        track={action.track}
        className={`${namespace}__action`}
        label={{ text: action.label.text }}
        accessibilityText={action.accessibility_text}
      />
    )}
  </div>
);

Denounce.propTypes = {
  item_info: shape({
    text: string.isRequired,
    color: string,
    values: shape({}).isRequired,
  }).isRequired,
  label: shape({
    text: string,
    color: string,
  }),
  action: shape({
    target: string.isRequired,
    accessibility_text: string,
    track: shape({}),
    label: shape({
      text: string,
    }).isRequired,
  }),
};

Denounce.defaultProps = {
  action: null,
};

module.exports = Denounce;
