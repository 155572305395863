const React = require('react');
const { shape, string, node } = require('prop-types');
const classnames = require('classnames');
const ActionModal = require('../action-modal');
const ActionRow = require('../action-row');

const namespace = 'ui-pdp-collapsable';

const ActionCollapsableModal = ({ action, children, modalClassName, modalHash, modalTitle }) => {
  const WrappedComponent = React.cloneElement(children, { showTitle: false });
  const modalLocation = modalHash ? `#${modalHash}` : `#${action.target}`;
  const buttonWithModal = (
    <ActionModal
      className="ui-pdp-see-more__action"
      deviceType="mobile"
      componentAsLabel={<ActionRow label={action.label.text} modifier="secondary" />}
      modalClassName={classnames(`${namespace}__modal`, modalClassName)}
      modalTitle={modalTitle}
      url={action.target}
      modalUrl={modalLocation}
      track={action.track}
      closeModalLabel={action.close_modal_label}
    >
      {WrappedComponent}
    </ActionModal>
  );

  return buttonWithModal;
};

ActionCollapsableModal.propTypes = {
  action: shape({
    label: shape({
      text: string.isRequired,
      color: string,
    }).isRequired,
    target: string,
  }).isRequired,
  children: node,
  modalClassName: string,
  modalHash: string,
  modalTitle: string,
};
ActionCollapsableModal.defaultProps = {
  children: null,
  modalClassName: null,
  modalHash: null,
  modalTitle: null,
};

module.exports = ActionCollapsableModal;
