const compose = require('../../lib/compose');
const connect = require('../../store/connect');
const withFeatureFlag = require('../feature-flag/with-feature-flag');
const withRow = require('../grid/row/with-row');

const FEATURE_NAME = 'cbt_summary';

const CBT_PROPS = {
  id: FEATURE_NAME,
};

const mapStateToProps = ({ components: { [FEATURE_NAME]: cbtSummary } }, ownProps) => ({
  ...cbtSummary,
  ...ownProps,
  ...CBT_PROPS,
});

const serializeOwnProps = (state, ownProps) => ({
  ...ownProps,
  ...CBT_PROPS,
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  withRow(FEATURE_NAME),
  connect(mapStateToProps),
);

const noFeatureFlagConnect = connect(serializeOwnProps);

module.exports = CbtSummary => enhance(CbtSummary);
module.exports.noFeatureFlag = CbtSummary => noFeatureFlagConnect(CbtSummary);
