/**
 * generateUUIDv1
 * ________________
 * Genera un string que se asemeja a un UUID versión 1.
 *
 */
const generateUUIDv1 = () => {
  const timestamp = Date.now();
  const timestampHex = timestamp.toString(16);

  // Simulamos algunos componentes de un UUID versión 1
  // "time_low", "time_mid", y "time_hi_and_version"
  const timeLow = timestampHex.slice(-8);
  const timeMid = timestampHex.slice(4, 8);
  const timeHiAndVersion = `1${timestampHex.slice(0, 3)}`;

  // Generamos "clock_seq" y "node" de forma aleatoria usando getRandomValues
  const clockSeqArray = new Uint16Array(1);
  const nodeArray = new Uint8Array(6);

  window.crypto.getRandomValues(clockSeqArray);
  window.crypto.getRandomValues(nodeArray);

  const clockSeq = ((clockSeqArray[0] && 0x3fff) || 0x8000).toString(16);
  const node = Array.from(nodeArray, byte => `0${byte.toString(16)}`.slice(-2)).join('');

  return `${timeLow}-${timeMid}-${timeHiAndVersion}-${clockSeq}-${node}`;
};

module.exports = generateUUIDv1;
