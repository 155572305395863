const React = require('react');
const { arrayOf, string, shape, oneOfType } = require('prop-types');
const classNames = require('classnames');
const IconFactory = require('../icons/factory-builder')();
const StyledLabel = require('../styled-label');

const namespace = 'ui-pdp-returns';

const Returns = ({ action, className, icon, subtitles, title }) => (
  <div className={classNames(namespace, className)}>
    <div className={`${namespace}__card`}>
      {icon && IconFactory(icon)}
      <div className={classNames(`${namespace}__card__main`)}>
        <StyledLabel as="h3" className={`${namespace}__card__main__title`} {...title} />
        {subtitles.map(subtitle => (
          <StyledLabel key={subtitle.text} className={`${namespace}__card__main__subtitle`} {...subtitle} />
        ))}
        {action && (
          <a className={classNames(`${namespace}__card__main__link`)} href={`${action.target}&modal=true`}>
            {action.label.text}
          </a>
        )}
      </div>
    </div>
  </div>
);

Returns.propTypes = {
  action: oneOfType([
    shape({
      label: shape({
        text: string.isRequired,
        color: string,
      }).isRequired,
      target: string.isRequired,
    }),
  ]).isRequired,
  className: string,
  icon: shape({
    id: string.isRequired,
    color: string,
  }).isRequired,
  subtitles: arrayOf(
    shape({
      text: string,
      color: string,
    }),
  ),
  title: shape({
    text: string.isRequired,
    color: string,
  }).isRequired,
};

Returns.defaultProps = {
  className: null,
  subtitles: null,
};

module.exports = Returns;
