/**
 * Module dependencies
 */
const React = require('react');
const { StoreConsumer } = require('./context');
const ErrorBoundary = require('./error');
const getDisplayName = require('../lib/get-display-name');

const noop = () => {};

/**
 * base-connect
 */

// internal state component, without history or global actions to dispatch
const baseConnect = (mapStateToProps = noop) => WrappedComponent => {
  const ConnectedComponent = ownProps => (
    <StoreConsumer>
      {({ state }) => (
        <ErrorBoundary>
          <WrappedComponent {...mapStateToProps(state, ownProps)} />
        </ErrorBoundary>
      )}
    </StoreConsumer>
  );
  ConnectedComponent.displayName = `Connect(${getDisplayName(WrappedComponent)})`;

  return ConnectedComponent;
};

/**
 * Expose connect
 */

module.exports = baseConnect;
