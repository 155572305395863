const { loadable } = require('nordic/lazy');
const compose = require('../../../../lib/compose');
const connect = require('../../../../store/connect');
const withFeatureFlag = require('../../../../components/feature-flag/with-feature-flag');
const actions = require('../../../../actions');

const Variations = loadable(() => import('../../../../components/variations/variations.mobile'));

const FEATURE_NAME = 'variations';
const FEATURE_DETAILS_NAME = 'variations_details';
const FEATURE_AVAILABLE_QUANTITY_NAME = 'available_quantity';
const FEATURE_MAIN_ACTIONS = 'main_actions';
const FEATURE_BUY_BOX_OFFERS = 'buy_box_offers';
const FEATURE_PROMOTIONS = 'promotions';

const mapStateToProps = (
  {
    id,
    app,
    filters,
    isFetching,
    components: {
      [FEATURE_NAME]: variations,
      [FEATURE_DETAILS_NAME]: variationsDetails,
      [FEATURE_AVAILABLE_QUANTITY_NAME]: availableQuantity,
      [FEATURE_MAIN_ACTIONS]: main_actions,
      [FEATURE_BUY_BOX_OFFERS]: buyBoxOffers,
      [FEATURE_PROMOTIONS]: promotions,
    },
  },
  ownProps,
) => ({
  ...ownProps,
  ...variations,
  isFetching,
  id,
  app,
  filters,
  selectedQuantity:
    availableQuantity && availableQuantity.state !== 'HIDDEN'
      ? (availableQuantity.picker && availableQuantity.picker.selected) || 1
      : 1,
  variationsDetails,
  buyNowAction: main_actions ? main_actions.form : null,
  buyBoxOffers: buyBoxOffers && buyBoxOffers.state === 'VISIBLE',
  promotions: promotions && promotions.state === 'VISIBLE' && promotions.type === 'pill_information',
});

const mapDispatchToProps = dispatch => ({
  onSelectVariation: (id, params) => dispatch(actions.fetchComponents(id, params)),
  fetchVariationsDetails: ids => dispatch(actions.fetchVariationDetailsByIds(ids)),
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

module.exports = enhance(Variations);
