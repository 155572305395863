const React = require('react');

const { useEffect, useState } = React;
const { bool, func, node, number, oneOf, shape, string } = require('prop-types');
const classNames = require('classnames');
const get = require('lodash/get');

const Button = require('@andes/button');
const ProgressIndicatorCircular = require('@andes/progress-indicator-circular');
const { VisuallyHidden } = require('@andes/common');

const IconAdd = require('@andes/icons/Add20');
const IconSubstract = require('@andes/icons/Substract20');

const namespace = 'counter-button';

const ARIA_LABEL = 'aria-label';

const defaultActionButtonProps = {
  hierarchy: 'transparent',
};

const defaultSpinnerProps = {
  modifier: 'inline',
  size: 'small',
};

const icons = {
  add: () => <IconAdd />,
  substract: () => <IconSubstract />,
};

const ActionButton = props => {
  const { icon } = props;
  const IconButton = get(icons, icon);
  return (
    <Button {...props}>
      <IconButton />
    </Button>
  );
};

ActionButton.propTypes = {
  icon: oneOf(['add', 'substract']),
};

ActionButton.defaultProps = {
  icon: 'add',
};

const CounterButton = ({
  className,
  value,
  isFetching,
  label,
  maxValue,
  size,
  spinner,
  [ARIA_LABEL]: ariaLabel,
  decrementProps,
  incrementProps,
}) => {
  const [hadFetchingStateBefore, setHadFetchingStateBefore] = useState(false);

  useEffect(() => {
    if (isFetching) {
      setHadFetchingStateBefore(true);
    }
  }, [isFetching]);

  const classes = classNames(namespace, className, {
    [`${namespace}--${size}`]: size,
    [`${namespace}--fade`]: spinner && isFetching,
  });

  if (size === 'small') {
    defaultSpinnerProps.size = 'xsmall';
  }

  return (
    <div className={classes}>
      <div
        className={classNames(`${namespace}__selector`, {
          [`${namespace}__selector--animate`]: hadFetchingStateBefore,
        })}
      >
        <ActionButton
          className={classNames(`${namespace}__selector__button`, `${namespace}__selector__button--decrement`, {
            [`${namespace}__selector__button--${size}`]: size,
          })}
          disabled={value <= 0 || isFetching}
          icon="substract"
          data-testid="action-button-decrement"
          {...decrementProps}
          {...defaultActionButtonProps}
        />
        <div className={`${namespace}__selector__label`}>
          <span aria-hidden={!!ariaLabel}>{label}</span>
          {ariaLabel && (
            <VisuallyHidden>
              <span aria-live="polite">{ariaLabel}</span>
            </VisuallyHidden>
          )}
        </div>
        <ActionButton
          className={classNames(`${namespace}__selector__button`, `${namespace}__selector__button--increment`, {
            [`${namespace}__selector__button--${size}`]: size,
          })}
          disabled={value >= maxValue || isFetching}
          icon="add"
          data-testid="action-button-increment"
          {...incrementProps}
          {...defaultActionButtonProps}
        />
      </div>
      {spinner && (isFetching || hadFetchingStateBefore) && (
        <div className={`${namespace}__spinner-container`}>
          <ProgressIndicatorCircular className={`${namespace}__spinner-container__spinner`} {...defaultSpinnerProps} />
        </div>
      )}
    </div>
  );
};

CounterButton.propTypes = {
  className: string,
  value: number,
  isFetching: bool,
  label: node,
  maxValue: number,
  size: oneOf(['small', 'large']),
  spinner: bool,
  [ARIA_LABEL]: string,
  decrementProps: shape({
    [ARIA_LABEL]: string,
    onClick: func,
  }),
  incrementProps: shape({
    [ARIA_LABEL]: string,
    onClick: func,
  }),
};

CounterButton.defaultProps = {
  className: '',
  value: 0,
  isFetching: false,
  label: null,
  maxValue: 0,
  size: 'large',
  spinner: false,
  [ARIA_LABEL]: null,
  decrementProps: null,
  incrementProps: null,
};

module.exports = CounterButton;
