const { loadable } = require('nordic/lazy');
const compose = require('../../../../lib/compose');
const connect = require('../../../../store/connect');
const withFeatureFlag = require('../../../../components/feature-flag/with-feature-flag');

const CardTooltip = loadable(() => import('../../../../components/card-tooltip'));

const FEATURE_NAME = 'paypal_tooltip';

const mapStateToProps = ({ components: { [FEATURE_NAME]: paypalTooltip } }, ownProps) => ({
  ...paypalTooltip,
  ...ownProps,
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(mapStateToProps),
);

module.exports = enhance(CardTooltip);
