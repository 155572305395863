const React = require('react');
const classNames = require('classnames');

const RecommendationsCarouselFree = require('../pages/vip/features/recommendations/carousel-free');
const RecommendationsCarouselSnapped = require('../pages/vip/features/recommendations/carousel-snapped');

const spotMiddleMobileRender = ({ firstChildClassName, lastChildClassName, middleChildClassName, ...rest }) => (
  featureName,
  index,
  arrayLength,
) => {
  let className = middleChildClassName;
  if (arrayLength === 1) {
    className = classNames({
      [`${firstChildClassName}`]: firstChildClassName,
      [`${lastChildClassName}`]: lastChildClassName,
    });
  } else if (index === 0) {
    className = firstChildClassName;
  } else if (index === arrayLength - 1) {
    className = lastChildClassName;
  }
  return <RecommendationsCarouselFree className={className} featureName={featureName} {...rest} />;
};

const spotMiddleDesktopRender = ({ firstChildClassName, lastChildClassName, middleChildClassName, ...rest }) => (
  featureName,
  index,
  arrayLength,
) => {
  let className = middleChildClassName;
  if (arrayLength === 1) {
    className = classNames({
      [`${firstChildClassName}`]: firstChildClassName,
      [`${lastChildClassName}`]: lastChildClassName,
    });
  } else if (index === 0) {
    className = firstChildClassName;
  } else if (index === arrayLength - 1) {
    className = lastChildClassName;
  }
  return <RecommendationsCarouselSnapped className={className} featureName={featureName} {...rest} />;
};

module.exports = {
  spotMiddleDesktopRender,
  spotMiddleMobileRender,
};
