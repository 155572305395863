const React = require('react');

const Grid = require('../../../../../../components/grid');

const HighlightedSpecsFeaturesThirdSwap = require('../../../../features/highlighted-specs-third-swap/highlighted-specs-features.mobile');
const HighlightedSpecsAttrsThirdSwap = require('../../../../features/highlighted-specs-third-swap/highlighted-specs-attrs.mobile');

const HighlightedSpecsThirdSwapChunk = () => (
  <>
    <HighlightedSpecsFeaturesThirdSwap />
    <Grid.Row className="ui-pdp-html-description ui-pdp-with--separator">
      <HighlightedSpecsAttrsThirdSwap />
    </Grid.Row>
  </>
);

module.exports = HighlightedSpecsThirdSwapChunk;
