const React = require('react');
const Grid = require('../../../../../../components/grid');

const Variations = require('../../../../features/variations/variations.mobile');

const VariationChunk = () => (
  <>
    <Grid.Row>
      <Variations className="mt-15 pl-16 pr-16" />
    </Grid.Row>
  </>
);

module.exports = VariationChunk;
