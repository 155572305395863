/**
 * Module dependencies
 */
const React = require('react');
const { UserConsumer } = require('./user');
const getDisplayName = require('../../../lib/get-display-name');

/**
 * withUser
 */
const withUser = Component => {
  const WithUser = props => <UserConsumer>{user => <Component {...props} currentUser={user} />}</UserConsumer>;
  WithUser.displayName = `WithUser(${getDisplayName(Component)})`;
  return WithUser;
};

/**
 * Expose withUser
 */
module.exports = withUser;
