const React = require('react');
const { bool, func, node, string, oneOfType } = require('prop-types');
const { Modal } = require('@andes/modal');
const classNames = require('classnames');

const namespace = 'ui-pdp-backdrop-modal';

const BackdropModal = ({ children, className, scrollLock, url, visible, onOpen, onClose, closeModalLabel, title }) => (
  <Modal
    title={title}
    className={classNames(namespace, className)}
    onOpen={onOpen}
    onClose={onClose}
    type="card"
    open={visible}
    url={url}
    scrollLock={scrollLock}
    closeButtonSrLabel={closeModalLabel}
    data-testid="backdrop-modal"
  >
    {children}
  </Modal>
);

BackdropModal.propTypes = {
  title: oneOfType([string, node]),
  children: node.isRequired,
  className: string,
  closeModalLabel: string,
  scrollLock: bool,
  url: string,
  visible: bool.isRequired,
  onOpen: func,
  onClose: func.isRequired,
};

BackdropModal.defaultProps = {
  title: null,
  url: null,
  className: null,
  scrollLock: true,
  onOpen: () => {},
};

module.exports = BackdropModal;
