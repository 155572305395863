const React = require('react');
const BaseImage = require('nordic/image');
const { shape, node } = require('prop-types');
const { PriceInline } = require('@vpp-frontend-components/price');
const { StyledLabel } = require('@vpp-frontend-components/styled-label');
const { Iframe } = require('@vpp-frontend-components/iframe');
const { ActionModal } = require('@vpp-frontend-components/action-modal');
const { Icon } = require('@vpp-frontend-components/icon');
const { TooltipWrapper } = require('@vpp-frontend-components/tooltip');
const { VppProvider: Provider } = require('@vpp-frontend-components/vpp-context');
const LoadableIcon = require('../../icons');
const APIService = require('../../../services/api');
const HistoryService = require('../../../services/history');

const deps = {
  StyledLabel,
  Iframe,
  PriceInline,
  ActionModal,
  Icon,
  TooltipWrapper,
  BaseImage,
  APIService,
  HistoryService,
};

const VppProvider = ({ children, ...props }) => (
  <Provider {...props} dependencies={{ ...deps, ...(props?.dependencies || {}) }} loadableIcon={LoadableIcon}>
    {children}
  </Provider>
);

VppProvider.propTypes = {
  children: node.isRequired,
  dependencies: shape({}),
  icons: shape({}),
  staticProps: shape({}),
};

module.exports = { VppProvider };
