const compose = require('../../../../../lib/compose');
const connect = require('../../../../../store/connect');
const withFeatureFlag = require('../../../../../components/feature-flag/with-feature-flag');
const withRow = require('../../../../../components/grid/row/with-row');
const Promotions = require('../../../../../components/promotions');

const FEATURE_NAME = 'meliplus_label_summary';

const MELIPLUS_LABEL_PROPS = {
  id: FEATURE_NAME,
};

const mapStateToProps = ({ components: { [FEATURE_NAME]: meliplusLabel } }, ownProps) => ({
  ...meliplusLabel,
  ...ownProps,
  ...MELIPLUS_LABEL_PROPS,
});

const serializeOwnProps = (state, ownProps) => ({
  ...ownProps,
  ...MELIPLUS_LABEL_PROPS,
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  withRow(FEATURE_NAME),
  connect(mapStateToProps),
);

const noFeatureFlagConnect = connect(serializeOwnProps);

module.exports = enhance(Promotions);
module.exports.noFeatureFlag = noFeatureFlagConnect(Promotions);
