const { loadable } = require('nordic/lazy');
const compose = require('../../../../lib/compose');
const connect = require('../../../../store/connect');
const withFeatureFlag = require('../../../../components/feature-flag/with-feature-flag');
const { withBoxComponent, MODAL } = require('../../../../components/box-component/with-box-component.mobile');

const PaymentMethods = loadable(() => import('../../../../components/payment-methods/payment-methods.mobile'));

const FEATURE_NAME = 'payment_methods';

const mapStateToProps = ({ components: { [FEATURE_NAME]: payment_methods } }, ...rest) => ({
  ...rest,
  ...payment_methods,
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(mapStateToProps),
  withBoxComponent(MODAL),
);

module.exports = enhance(PaymentMethods);
