const React = require('react');
const { shape, string, arrayOf, bool } = require('prop-types');
const { Thumbnail } = require('@andes/thumbnail');
const classnames = require('classnames');
const IconFactory = require('../icons/factory-builder')();
const Image = require('../image');
const StyledLabel = require('../styled-label');
const componentEnhance = require('../../lib/component-enhance');
const { jsx } = require('../../lib/component-enhance');
const { generateKeyToComponent } = require('../../utils/validators');

const verifyTitleTwoLines = ({ label, nameSeller, titleTwoLines }) => {
  if (titleTwoLines) {
    const namesArray = nameSeller.split(' ');
    const namesArraySize = namesArray.length;
    let textContent = `<span class='reputation-span'>${label}</span>${
      titleTwoLines ? "<span class='repupation-span-seller-name'>" : ''
    }`;
    namesArray.forEach((name, index) => {
      textContent += ` ${name}${namesArraySize === index + 1 && titleTwoLines ? '</span>' : ''}`;
    });

    return textContent;
  }
  return nameSeller;
};

const namespace = 'ui-pdp-reputation';
// eslint-disable-next-line complexity
const ReputationSeller = ({
  id,
  title,
  brand_title,
  seller_link,
  sales,
  subtitles,
  logo,
  action,
  title_value,
  title_two_lines,
}) => {
  const paramsToValidateTwoLines = {
    label: title,
    nameSeller: jsx(seller_link.label.text),
    titleTwoLines: title_two_lines,
  };
  const classpdp = seller_link.label.color ? `ui-pdp-color--${seller_link.label.color}` : '';
  return (
    <div className={classnames(`${namespace}__seller`)} aria-expanded="false" id={id}>
      {logo ? (
        <div className={classnames(`${namespace}-seller-info`)}>
          <Thumbnail
            modifier="square"
            size="40"
            className={classnames(`ui-pdp-seller-logo-reputation`)}
            aria-hidden="true"
          >
            <Image src={logo} alt={title} key={logo} lazyload="on" />
          </Thumbnail>

          <div
            className={classnames(
              `${namespace}-main-info-seller`,
              `${logo ? `${namespace}-main-info-seller-logo` : ''}`,
            )}
          >
            {brand_title ? (
              <StyledLabel
                {...brand_title}
                as="div"
                className={`${namespace}-title-link`}
                text={jsx(brand_title.text, brand_title.values)}
                aria-label={`${brand_title.text} ${brand_title.values}`}
              />
            ) : (
              ''
            )}
            <div className={classnames(`${namespace}-seller`)}>
              <div className={classnames(`${namespace}-name`, `${logo ? `${namespace}-main-info-seller-logo` : ''}`)}>
                {!title_two_lines && <span>{title}</span>}
                {action ? (
                  <StyledLabel
                    {...seller_link}
                    withDangerous
                    as="a"
                    className={`${namespace}-title-link non-selectable ${title_two_lines ? 'non-margin-left' : ''}`}
                    href={action ? action.target : ''}
                    target={action ? action.html_target : ''}
                    text={verifyTitleTwoLines(paramsToValidateTwoLines)}
                  />
                ) : (
                  <span className={`${namespace}-title-link non-selectable ${classpdp}`}>
                    {title_value || seller_link.label.text}
                  </span>
                )}
              </div>
              {sales && (
                <div>
                  <span className={classnames(`ui-pdp-color--${sales.color} ${namespace}-ventas`)} aria-hidden="true">
                    {componentEnhance.jsx(sales.text)}
                  </span>
                  <span className={classnames(`andes-visually-hidden`)}>
                    {' '}
                    {componentEnhance.jsx(sales.accessibility_text)}
                  </span>
                </div>
              )}
            </div>
            {subtitles.map(extraInfo => (
              <div
                key={generateKeyToComponent(extraInfo)}
                className={classnames(`${namespace}-seller-info ${namespace}-mercado-lider`)}
              >
                {extraInfo.text_icon ? (
                  <figure aria-hidden="true">
                    {IconFactory(
                      extraInfo.text_icon.id,
                      `ui-pdp-color--${extraInfo.text_icon.color} ui-pdp-size--${extraInfo.text_icon.size}`,
                    )}
                  </figure>
                ) : (
                  ''
                )}
                <StyledLabel
                  {...extraInfo}
                  as="span"
                  className={`${namespace}__title`}
                  text={jsx(extraInfo.text, extraInfo.values)}
                />
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div
          className={classnames(`${namespace}-main-info-seller`, `${logo ? `${namespace}-main-info-seller-logo` : ''}`)}
        >
          {brand_title ? (
            <StyledLabel
              {...brand_title}
              as="div"
              className={`${namespace}-title-link non-selectable`}
              text={jsx(brand_title.text, brand_title.values)}
              aria-label={`${brand_title.text} ${brand_title.values}`}
            />
          ) : (
            ''
          )}
          <div className={classnames(`${namespace}-seller`)}>
            <div className={classnames(`${namespace}-name`, `${logo ? `${namespace}-main-info-seller-logo` : ''}`)}>
              {!title_two_lines && <span>{title}</span>}
              {action ? (
                <StyledLabel
                  {...seller_link}
                  as="a"
                  id="seller_id"
                  className={`${namespace}-title-link non-selectable ${title_two_lines ? 'non-margin-left' : ''}`}
                  href={action ? action.target : ''}
                  target={action ? action.html_target : ''}
                  withDangerous
                  text={verifyTitleTwoLines(paramsToValidateTwoLines)}
                  aria-label={`${seller_link.label.text} ${action.accessibility_text}`}
                />
              ) : (
                <span className={`${namespace}-title-link non-selectable`}>{title_value}</span>
              )}
            </div>
          </div>
          {sales && (
            <div>
              <span className={classnames(`ui-pdp-color--${sales.color} ${namespace}-ventas`)} aria-hidden="true">
                {componentEnhance.jsx(sales.text)}
              </span>
              <span className={classnames(`andes-visually-hidden`)}>
                {' '}
                {componentEnhance.jsx(sales.accessibility_text)}
              </span>
            </div>
          )}
          {subtitles.map(extraInfo => (
            <div
              key={generateKeyToComponent(extraInfo)}
              className={classnames(`${namespace}-seller-info ${namespace}-mercado-lider`)}
            >
              {extraInfo.text_icon ? (
                <figure aria-hidden="true">
                  {IconFactory(
                    extraInfo.text_icon.id,
                    `ui-pdp-color--${extraInfo.text_icon.color} ui-pdp-size--${extraInfo.text_icon.size}`,
                  )}
                </figure>
              ) : (
                ''
              )}
              <StyledLabel
                {...extraInfo}
                as="span"
                className={`${namespace}__title`}
                text={jsx(extraInfo.text, extraInfo.values)}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

ReputationSeller.propTypes = {
  id: string,
  title: string,
  title_value: string,
  brand_title: shape({
    text: string,
    color: string,
    font_size: string,
    font_family: string,
    values: shape({
      bold_text: shape({
        text: string,
        font_family: string,
      }),
    }),
  }),
  seller_link: shape({
    label: shape({
      text: string,
      color: string,
    }),
  }),
  sales: shape({
    text: string,
    color: string,
    font_size: string,
    font_family: string,
    accessibility_text: string,
  }),
  title_two_lines: bool,
  subtitles: arrayOf(
    shape({
      text_icon: shape({
        id: string,
        color: string,
        size: string,
      }),
      text: string,
      color: string,
      font_size: string,
      font_family: string,
      values: shape({
        mercadoLider: shape({
          text: string,
          color: string,
          font_size: string,
          font_family: string,
        }),
      }),
    }),
    shape({ text: string, color: string, font_size: string }),
  ),
  logo: string,
  action: shape({
    target: string,
    html_target: string,
  }),
};

ReputationSeller.defaultProps = {
  brand_title: null,
  logo: null,
  action: null,
};

module.exports = ReputationSeller;
