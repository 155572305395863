const { trackEvent, trackPage } = require('../../../lib/tracking');

const tracking = ({ track, callback }) => {
  const {
    melidata_event: { event_data, path, type },
  } = track;

  switch (type) {
    case 'view':
      trackPage(track);
      break;
    case 'event':
      trackEvent(track);
      break;
    default:
      break;
  }

  if (window.MobileWebKit) {
    window.MobileWebKit.executeNative({
      method: 'tracking_bottomsheet',
      args: { track, event_data, path, type },
    });
  }

  if (callback) {
    callback();
  }
};

module.exports = { tracking };
