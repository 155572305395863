const get = require('lodash/get');
const APIService = require('../services/api');
const { trackEvent, trackEventWithCustomField, trackEventWithCustomFields, trackPage } = require('../lib/tracking');
const { processRequest, requestQueue } = require('./utils/addToCartRequestQueue');
const { feStatsdHelper } = require('../utils/frontendStatsHelper');
const {
  VPP_SEQUENCER_DATADOG_KEY_PREFIX,
  VPP_SEQUENCER_DATADOG_KEY_CASES,
} = require('../services/frontend-statsd/config/allowed-keys');

const FEEDBACK_STYLE_ERROR = 'ERROR';

const {
  CREATE_QUESTION_START,
  CREATE_QUESTION_COMPLETE,
  CREATE_QUESTION_FROM_AI_COMPLETE,
  CREATE_QUESTION_ERROR,
  DISLIKE_REVIEW_OPTIMISTICALLY,
  DISLIKE_REVIEW_ROLLBACK,
  FETCH_START,
  FETCH_COMPLETE,
  FETCH_ERROR,
  FETCH_ERROR_SNACK,
  FETCH_ERROR_SNACK_HIDE,
  FETCH_ON_SET_BOOKMARK_COMPLETE,
  FETCH_ON_SET_QUANTITY_START,
  FETCH_ON_SET_QUANTITY_COMPLETE,
  FETCH_PRODUCT_VARIATIONS_DETAILS,
  LIKE_REVIEW_OPTIMISTICALLY,
  LIKE_REVIEW_ROLLBACK,
  FETCH_SHIPPING_PROMISE_START,
  FETCH_SHIPPING_PROMISE_COMPLETE,
  QUANTITY_SHOW_INPUT_CHANGE,
  ADD_TO_CART_UPDATE_START,
  ADD_TO_CART_UPDATE_COMPLETE,
  ADD_TO_CART_UPDATE_ERROR,
  TOGGLE_BOOKMARKED,
  TOGGLE_BOOKMARK_OPTIMISTICALLY,
  SET_PICKERS_COLLAPSED,
  SET_RECOMMENDATIONS_CONTEXT,
  FETCH_DEFERRED_START,
  FETCH_DEFERRED_SUCCESS,
  FETCH_DEFERRED_ERROR,
  SHOW_QUANTITY_ERROR,
  SHOW_VARIATIONS_ERROR_DESKTOP,
  SHOW_VARIATIONS_ERROR_MOBILE,
  FETCH_ON_COUPON_UPDATE_START,
  FETCH_ON_COUPON_UPDATE_COMPLETE,
  FETCH_ON_COUPON_UPDATE_ERROR,
  ON_COUPON_BUYBOX_OFFERS_UPDATE,
  BOTTOMSHEET_MODAL_UPDATE,
  BOTTOMSHEET_MODAL,
  BOTTOMSHEET_MODAL_CLOSE,
  FETCH_BOTTOMSHEET_MODAL,
  FETCH_ON_MELIPLUS_UPDATE_START,
  FETCH_ON_MELIPLUS_UPDATE_COMPLETE,
  FETCH_ON_CHANGE_TRADE_IN_START,
  FETCH_ON_CHANGE_TRADE_IN_COMPLETE,
  SHOW_SNACKBAR,
  HIDE_SNACKBAR,
  FETCH_ONE_PAY_FOR_ALL,
  WISHLIST_SHOW_BOTTOMSHEET_MODAL,
  WISHLIST_CLOSE_BOTTOMSHEET_MODAL,
  UPDATE_GIFT_REGISTRY,
  UPDATE_GIFT_REGISTRY_CHECKBOX,
  ONE_PAY_FOR_ALL_IFRAME_LOADED,
  ONE_PAY_FOR_ALL_SHOW_MODAL,
  TOGGLE_FOLLOW_OPTIMISTICALLY,
  FETCH_ON_SET_FOLLOW_COMPLETE,
  FETCH_QUESTION_AI_START,
  FETCH_QUESTION_AI_COMPLETE,
  FETCH_QUESTION_AI_ERROR,
} = require('./actions');

const {
  newPayloadFetchOnePayForAll,
  updateOnePayForAllTrack,
  redirectOrShowSnackbar,
  ACTIONS_SEQUENCER,
  ACTIONS_SEQUENCER_TRACK,
} = require('./utils/onePayForAllUtils');
const { getDefaultErrorMessage } = require('./utils/getDefaultErrorMessage');
const { arrayToObjectById } = require('../reducers/helpers/arrayToObjectById');
const { REASON_TAG_VALUES, BUTTON_TYPES_TAG_VALUES } = require('../services/frontend-statsd/config/allowed-tags');
const applyCouponSummaryToSelectedOffers = require('./utils/applyCouponSummaryToSelectedOffers');
const { tracking } = require('../pages/questions-ai/events/tracking');
const { execAddElementToList, execRemoveElementFromList, execFetchUpdateWishlist } = require('../utils/giftRegistry');

const getSelectedAttributes = components => {
  let attributes = get(components, 'variations.selected_attributes', null);

  if (!attributes) {
    attributes = get(components, 'outside_variations.selected_attributes', null);
  }
  if (attributes) {
    attributes = JSON.stringify(attributes).replace(/[{()}]/g, '');
    attributes = attributes.replace(/"/g, '');
  }
  return attributes;
};

const addElementToList = element => (dispatch, getState) => {
  const {
    components: { wishlist_save_button: wishlist },
  } = getState();

  execAddElementToList(element, dispatch, wishlist, SHOW_SNACKBAR, HIDE_SNACKBAR, FETCH_ERROR);
};

const removeElementFromList = element => dispatch => {
  execRemoveElementFromList(element, dispatch, TOGGLE_BOOKMARKED, SHOW_SNACKBAR, FETCH_ERROR);
};

const selectPaymentMethod = offer_type => (dispatch, getState) => {
  const {
    id,
    filters: pdp_filters,
    applied_product_filters,
    components: {
      available_quantity: {
        picker: { selected: quantity },
      },
    },
    components,
  } = getState();

  const attributes = getSelectedAttributes(components);

  dispatch({
    type: FETCH_START,
    id,
    params: { pdp_filters, applied_product_filters, attributes, quantity },
  });

  APIService.getProduct(id, { offer_type, pdp_filters, attributes, applied_product_filters, quantity })
    .then(payload => {
      dispatch({ type: FETCH_COMPLETE, payload: { id, ...payload } });
      dispatch({ type: SET_RECOMMENDATIONS_CONTEXT, payload: { ...payload } });
    })
    .catch(e => {
      dispatch({ type: FETCH_ERROR, error: e });
    });
};

const fetchComponents = (id, params) => (dispatch, getState) => {
  const { filters: pdp_filters, applied_product_filters, app } = getState();
  dispatch({ type: FETCH_START, id, params });
  APIService.getProduct(id, { ...params, app, pdp_filters, applied_product_filters })
    .then(payload => {
      dispatch({ type: FETCH_COMPLETE, payload: { id, ...payload } });
      dispatch({ type: SET_RECOMMENDATIONS_CONTEXT, payload: { ...payload } });
    })
    .catch(e => {
      dispatch({ type: FETCH_ERROR, error: e });
    });
};

const fetchUpdatedComponents = (id, params, updateVariationsBox, attrId) => (dispatch, getState) => {
  const { filters: pdp_filters, applied_product_filters, app } = getState();
  dispatch({ type: FETCH_START, id, params });
  APIService.getProductUpdateVariation(id, { ...params, app, pdp_filters, applied_product_filters })
    .then(payload => {
      updateVariationsBox(id, attrId, payload);
      dispatch({ type: FETCH_COMPLETE, payload: { id, ...payload } });
      dispatch({ type: SET_RECOMMENDATIONS_CONTEXT, payload: { ...payload } });
    })
    .catch(e => {
      dispatch({ type: FETCH_ERROR, error: e });
    });
};

const fetchUpdatedCouponBuyBoxOffers = () => (dispatch, getState) => {
  const {
    id,
    app,
    selected_offer_type: offer_type,
    components: { buy_box_offers },
  } = getState();
  const newbuyBoxOffers = applyCouponSummaryToSelectedOffers(buy_box_offers, { isFetching: true });

  dispatch({ type: ON_COUPON_BUYBOX_OFFERS_UPDATE, id, payload: { buy_box_offers: newbuyBoxOffers } });
  APIService.getProductUpdateCoupon(id, { app, offer_type })
    .then(payload => {
      const newbuy_box_offers = applyCouponSummaryToSelectedOffers(payload.components.buy_box_offers, {
        isFetching: false,
      });
      dispatch({ type: ON_COUPON_BUYBOX_OFFERS_UPDATE, payload: { buy_box_offers: newbuy_box_offers } });
    })
    .catch(e => {
      dispatch({ type: FETCH_ERROR, error: e });
    });
};

const fetchUpdatedCoupon = () => (dispatch, getState) => {
  const { id, app, selected_offer_type: offer_type } = getState();

  dispatch({ type: FETCH_ON_COUPON_UPDATE_START, id });
  APIService.getProductUpdateCoupon(id, { app, offer_type })
    .then(payload => {
      dispatch({ type: FETCH_ON_COUPON_UPDATE_COMPLETE, payload });
    })
    .catch(e => {
      dispatch({ type: FETCH_ERROR, error: e });
    });
};

const hasDoubleAlternatives = components => components?.buy_box_offers?.state === 'VISIBLE';

const mobileUpdateCoupon = () => (dispatch, getState) => {
  const { components } = getState();
  if (hasDoubleAlternatives(components)) {
    return dispatch(fetchUpdatedCouponBuyBoxOffers());
  }
  return dispatch(fetchUpdatedCoupon());
};

const trackIfNecessary = track => {
  if (track) {
    trackEvent(track);
  }
};

const handlePostCouponActivateError = (dispatch, getState, error) => {
  const { siteId } = getState();
  dispatch({
    type: SHOW_SNACKBAR,
    params: {
      message: error?.response?.data?.display_message ?? getDefaultErrorMessage(siteId),
      type: 'error',
      delay: 6000,
      called_from: 'coupon_summary',
    },
  });
};

const postCouponActivate = ({ track, ...params }) => (dispatch, getState) => {
  const {
    app,
    components: {
      available_quantity: {
        picker: { selected: quantity },
      },
      main_actions: {
        form: { item_id },
      },
    },
  } = getState();

  trackIfNecessary(track);

  dispatch({ type: FETCH_ON_COUPON_UPDATE_START, id: item_id });

  APIService.postProductCouponActivate(item_id, { app, quantity, ...params })
    .then(payload => {
      trackIfNecessary(payload?.components?.coupon_summary?.awareness?.track);
      dispatch({ type: FETCH_ON_COUPON_UPDATE_COMPLETE, payload });
    })
    .catch(e => {
      dispatch({ type: FETCH_ON_COUPON_UPDATE_ERROR });
      handlePostCouponActivateError(dispatch, getState, e);
    });
};

const postCouponActivateBuyBoxOffers = ({ track, ...params }) => (dispatch, getState) => {
  const {
    app,
    components: {
      buy_box_offers,
      selected_offer_type: offer_type,
      available_quantity: {
        picker: { selected: quantity },
      },
      main_actions: {
        form: { item_id },
      },
    },
  } = getState();

  trackIfNecessary(track);

  const updatedBuyBoxOffers = applyCouponSummaryToSelectedOffers(buy_box_offers, { isFetching: true });
  dispatch({ type: ON_COUPON_BUYBOX_OFFERS_UPDATE, payload: { buy_box_offers: updatedBuyBoxOffers } });

  APIService.postProductCouponActivate(item_id, { app, quantity, offer_type, ...params })
    .then(payload => {
      trackIfNecessary(payload?.components?.coupon_summary?.awareness?.track);
      const newCouponSummaryUpdate = { ...payload.components.coupon_summary, isFetching: false };
      const newbuy_box_offers = applyCouponSummaryToSelectedOffers(buy_box_offers, newCouponSummaryUpdate);
      dispatch({
        type: ON_COUPON_BUYBOX_OFFERS_UPDATE,
        payload: { buy_box_offers: newbuy_box_offers },
      });
    })
    .catch(e => {
      const { components } = getState();
      const newbuyBoxOffers = applyCouponSummaryToSelectedOffers(components.buy_box_offers, { isFetching: false });
      dispatch({
        type: ON_COUPON_BUYBOX_OFFERS_UPDATE,
        payload: { buy_box_offers: newbuyBoxOffers },
      });

      handlePostCouponActivateError(dispatch, getState, e);
    });
};

const mobileActivateCoupon = params => (dispatch, getState) => {
  const { components } = getState();
  if (hasDoubleAlternatives(components)) {
    return dispatch(postCouponActivateBuyBoxOffers(params));
  }
  return dispatch(postCouponActivate(params));
};

const fetchDeferredComponent = component => (dispatch, getState) => {
  const { app, id } = getState();
  dispatch({ type: FETCH_DEFERRED_START, id, component });
  APIService.getDeferredComponent({
    id,
    app,
    component_ids: component,
  })
    .then(payload => {
      dispatch({ type: FETCH_DEFERRED_SUCCESS, payload: { id, ...payload } });
    })
    .catch(e => {
      dispatch({ type: FETCH_DEFERRED_ERROR, error: e });
    });
};

const setPickersCollapse = pickerId => dispatch => {
  dispatch({
    type: SET_PICKERS_COLLAPSED,
    payload: {
      pickerId,
    },
  });
};

const fetchErrorSnackHide = () => dispatch => {
  dispatch({ type: FETCH_ERROR_SNACK_HIDE });
};

const fetchVariationDetailsByIds = ids => (dispatch, getState) => {
  const { id, filters: pdp_filters, components } = getState();
  const attributes = getSelectedAttributes(components);
  APIService.getProductVariationsDetails(id, { pdp_filters, attributes, ids })
    .then(payload => dispatch({ type: FETCH_PRODUCT_VARIATIONS_DETAILS, payload }))
    .catch(e => dispatch({ type: FETCH_ERROR, error: e }));
};

const fetchOnSetQuantity = quantity => (dispatch, getState) => {
  const { id, applied_product_filters, filters: pdp_filters, components, selected_offer_type: offer_type } = getState();
  const attributes = getSelectedAttributes(components);

  dispatch({
    type: FETCH_ON_SET_QUANTITY_START,
    payload: {
      id,
      quantity,
    },
  });

  APIService.getProductOnSetQuantity(id, quantity, { pdp_filters, attributes, applied_product_filters, offer_type })
    .then(payload => {
      dispatch({ type: FETCH_ON_SET_QUANTITY_COMPLETE, payload });
    })
    .catch(e => dispatch({ type: FETCH_ERROR, error: e }));
};

const fetchShippingPromise = () => (dispatch, getState) => {
  const state = getState();
  const { id, filters: pdp_filters, selected_offer_type: offer_type } = state;
  const quantity = get(state, 'components.available_quantity.picker.selected', 1);

  dispatch({
    type: FETCH_SHIPPING_PROMISE_START,
    payload: {
      id,
      quantity,
    },
  });

  APIService.getProductDetailsOnShippingPromiseChange(id, quantity, { pdp_filters, offer_type })
    .then(payload => {
      dispatch({ type: FETCH_SHIPPING_PROMISE_COMPLETE, payload });
    })
    .catch(e => dispatch({ type: FETCH_ERROR, error: e }));
};

const fetchProductOnMeliplusUpdate = updateAction => (dispatch, getState) => {
  const state = getState();
  const { id, platform, app, filters: pdp_filters, vip_filters, selected_offer_type: offer_type, attributes } = state;
  const quantity = get(state, 'components.available_quantity.picker.selected', 1);
  dispatch({
    type: FETCH_ON_MELIPLUS_UPDATE_START,
    payload: {
      id,
      quantity,
    },
  });
  APIService.getProductUpdateMeliplus(id, {
    id,
    attributes,
    platform,
    app,
    pdp_filters,
    vip_filters,
    quantity,
    update_action: updateAction,
    offer_type,
  })
    .then(payload => {
      dispatch({ type: FETCH_ON_MELIPLUS_UPDATE_COMPLETE, payload });
    })
    .catch(e => dispatch({ type: FETCH_ERROR, error: e }));
};

const onQuantityShowInput = show => dispatch => {
  dispatch({
    type: QUANTITY_SHOW_INPUT_CHANGE,
    payload: {
      show,
    },
  });
};

const onCreateQuestion = ({ itemId, text, track }) => (dispatch, getState) => {
  const { app } = getState();
  dispatch({ type: CREATE_QUESTION_START, itemId });
  APIService.createQuestion(itemId, text, { app })
    .then(payload => {
      if (payload.stock_track) {
        trackEvent(payload.stock_track);
      }

      if (!payload.stock_modal) {
        const extraFields = payload.extra_post_event_data || {};
        extraFields.failed = !payload.success;
        trackEventWithCustomFields(track, extraFields);
      }

      dispatch({ type: CREATE_QUESTION_COMPLETE, payload });
    })
    .catch(e => {
      trackEventWithCustomField('failed', track, true);
      dispatch({ type: CREATE_QUESTION_ERROR, error: e.response.data.displayMessage });
    });
};

const onCreateQuestionFromAi = ({ itemId, text, snackbar_message, suggestionTrack }) => (dispatch, getState) => {
  const { app, components } = getState();
  const track = get(components, 'questions.track', null);

  if (suggestionTrack) {
    const {
      melidata_event: {
        event_data: { origin },
      },
    } = suggestionTrack;

    switch (origin) {
      case 'feedback':
        trackEvent(suggestionTrack);
        break;

      default:
        trackPage(suggestionTrack);
        break;
    }
  }

  dispatch({ type: CREATE_QUESTION_START });
  APIService.createQuestion(itemId, text, { app })
    .then(payload => {
      if (payload.stock_track) {
        trackEvent(payload.stock_track);
      }

      if (!payload.stock_modal) {
        const extraFields = payload.extra_post_event_data || {};
        extraFields.failed = !payload.success;
        trackEventWithCustomFields(track, extraFields);
      }

      dispatch({ type: CREATE_QUESTION_FROM_AI_COMPLETE, payload });
      dispatch({
        type: SHOW_SNACKBAR,
        params: { message: snackbar_message, type: 'success', delay: 3000, called_from: 'questions_ai' },
      });
    })
    .catch(e => {
      trackEventWithCustomField('failed', track, true);
      dispatch({ type: CREATE_QUESTION_ERROR, error: e.response.data.displayMessage });
    });
};

const fetchUpdatedWishlists = (forceChecked = null) => (dispatch, getState) => {
  const { id, app } = getState();
  execFetchUpdateWishlist(dispatch, id, app, UPDATE_GIFT_REGISTRY, forceChecked, FETCH_ERROR);
};

const toggleGiftRegistryCheckbox = check => dispatch => {
  dispatch({
    type: UPDATE_GIFT_REGISTRY_CHECKBOX,
    payload: {
      check,
    },
  });
};

const toggleBookmark = () => (dispatch, getState) => {
  const STATUS_OK = 'ok';
  const STATUS_ERROR = 'error';
  const SUCCESS = 'success';
  const TYPE = 'neutral';
  const CALLED_FROM = 'wishlist_save_button';
  const {
    components: {
      bookmark: { is_bookmarked: isBookmarked, item_id: itemId },
      wishlist_save_button,
    },
    csrfToken,
  } = getState();
  dispatch({ type: TOGGLE_BOOKMARK_OPTIMISTICALLY });

  if (isBookmarked) {
    APIService.removeBookmark(itemId)
      .then(({ snack_bar = null, status: responseStatus = null }) => {
        const snackbar_status = snack_bar?.message_type === TYPE ? STATUS_OK : STATUS_ERROR;
        if (snackbar_status === STATUS_OK) {
          dispatch({
            type: UPDATE_GIFT_REGISTRY_CHECKBOX,
            payload: {
              check: false,
            },
          });
        }
        const status = responseStatus || snackbar_status;
        dispatch({ type: FETCH_ON_SET_BOOKMARK_COMPLETE, payload: { status } });
        if (snack_bar) {
          dispatch({
            type: SHOW_SNACKBAR,
            params: {
              message: snack_bar?.message,
              type: snack_bar?.message_type,
              delay: snack_bar?.delay?.duration || 3000,
              called_from: CALLED_FROM,
              className: 'snackbar--remove-bookmark',
            },
          });
        }
      })
      .catch(e => dispatch({ type: FETCH_ERROR, error: e }));
  } else {
    APIService.addBookmark(itemId, csrfToken, wishlist_save_button?.gift_registry?.id)
      .then(({ snack_bar, status: responseStatus = null }) => {
        const snackbar_status = snack_bar?.message_type === SUCCESS ? STATUS_OK : STATUS_ERROR;
        const status = responseStatus || snackbar_status;
        const newDynamicTarget = snack_bar?.action?.target || null;

        dispatch({ type: FETCH_ON_SET_BOOKMARK_COMPLETE, payload: { status } });

        if (snack_bar) {
          dispatch({
            type: SHOW_SNACKBAR,
            params: {
              message: snack_bar?.message,
              type: snack_bar.message_type,
              delay: snack_bar?.delay?.duration || 6000,
              called_from: CALLED_FROM,
              className: 'snackbar--add-bookmark',
              action: {
                text: snack_bar?.action?.label?.text,
                onClick: () => {
                  dispatch({
                    type: WISHLIST_SHOW_BOTTOMSHEET_MODAL,
                    payload: {
                      targetDynamic: newDynamicTarget,
                    },
                  });
                  dispatch({ type: HIDE_SNACKBAR, payload: {} });
                },
              },
            },
          });
        }
      })
      .catch(e => dispatch({ type: FETCH_ERROR, error: e }));
  }
};

const fetchBookmark = () => dispatch => {
  dispatch({
    type: TOGGLE_BOOKMARKED,
  });
};

const showVariationsError = (deviceType, actionKey) => dispatch => {
  if (deviceType === 'desktop') {
    dispatch({ type: SHOW_VARIATIONS_ERROR_DESKTOP });
  } else {
    dispatch({ type: SHOW_VARIATIONS_ERROR_MOBILE, payload: { actionKey } });
  }
};

const showQuantityError = () => dispatch => {
  dispatch({ type: SHOW_QUANTITY_ERROR });
};

const likeReview = reviewId => dispatch => {
  dispatch({
    type: LIKE_REVIEW_OPTIMISTICALLY,
    payload: {
      reviewId,
    },
  });

  APIService.likeReview(reviewId)
    .then(payload => payload)
    .catch(e => {
      try {
        dispatch({ type: FETCH_ERROR_SNACK, error: { message: e.response.data.displayMessage } });
      } catch (eMessage) {
        dispatch({ type: FETCH_ERROR_SNACK, error: { message: 'Algo salió mal' } });
      }
      dispatch({ type: LIKE_REVIEW_ROLLBACK, payload: { reviewId } });
      dispatch({ type: FETCH_ERROR_SNACK_HIDE });
    });
};

const dislikeReview = reviewId => dispatch => {
  dispatch({
    type: DISLIKE_REVIEW_OPTIMISTICALLY,
    payload: {
      reviewId,
    },
  });

  APIService.dislikeReview(reviewId)
    .then(payload => payload)
    .catch(e => {
      try {
        dispatch({ type: FETCH_ERROR_SNACK, error: { message: e.response.data.displayMessage } });
      } catch (eMessage) {
        dispatch({ type: FETCH_ERROR_SNACK, error: { message: 'Algo salió mal' } });
      }
      dispatch({ type: DISLIKE_REVIEW_ROLLBACK, payload: { reviewId } });
      dispatch({ type: FETCH_ERROR_SNACK_HIDE });
    });
};

const addToCartUpdate = ({ action, quantity, target, onSuccess, onError }) => (dispatch, getState) => {
  const { app, filters: pdp_filters } = getState();

  const data = {
    action,
    quantity,
    ...target,
  };

  const params = { app, pdp_filters };

  const request = () => {
    dispatch({ type: ADD_TO_CART_UPDATE_START });
    requestQueue.isRequestInProgress = true;

    APIService.addToCartUpdate(data, params)
      .then(payload => {
        const callback = () => {
          dispatch({ type: ADD_TO_CART_UPDATE_COMPLETE, payload });
          if (payload.feedback && payload.feedback.style === FEEDBACK_STYLE_ERROR) {
            onError();
          } else {
            onSuccess();
          }
        };
        processRequest(callback);
      })
      .catch(e => {
        const callback = () => {
          dispatch({ type: ADD_TO_CART_UPDATE_ERROR, error: e });
          if (onError) {
            onError();
          }
        };
        processRequest(callback);
      });
  };

  if (requestQueue.isRequestInProgress) {
    requestQueue.pendingRequest = request;
  } else {
    request();
  }
};

const showSnackbar = ({ message, type, delay, called_from, action, className }) => (dispatch, getState) => {
  const { siteId } = getState();
  const DEFAULT_ERROR_MESSAGE = getDefaultErrorMessage(siteId);
  dispatch({
    type: SHOW_SNACKBAR,
    params: {
      message: message || DEFAULT_ERROR_MESSAGE,
      type,
      delay: delay || 3000,
      called_from: called_from || 'pdp',
      action,
      className,
    },
  });
};

const hideSnackbar = () => dispatch => {
  dispatch({ type: HIDE_SNACKBAR });
};

const updateTradeIn = () => (dispatch, getState) => {
  const { id } = getState();
  dispatch({ type: FETCH_ON_CHANGE_TRADE_IN_START, id });
  APIService.updateTradeIn(id)
    .then(payload => {
      dispatch({ type: FETCH_ON_CHANGE_TRADE_IN_COMPLETE, payload: { id, ...payload } });
    })
    .catch(e => dispatch({ type: FETCH_ERROR, error: e }));
};

const removeTradeIn = tradeInId => (dispatch, getState) => {
  const { id } = getState();
  dispatch({ type: FETCH_ON_CHANGE_TRADE_IN_START, id });
  APIService.removeTradeIn(id, tradeInId)
    .then(payload => {
      dispatch({ type: FETCH_ON_CHANGE_TRADE_IN_COMPLETE, payload: { id, ...payload } });
    })
    .catch(e => dispatch({ type: FETCH_ERROR, error: e }));
};

const showAddToCartModal = ({ deviceType, itemId, labelText }) => (dispatch, getState) => {
  const {
    components: {
      available_quantity: { picker: { selected: quantity } = { selected: null } },
      main_actions: { form },
    },
  } = getState();
  const data = {
    ...form,
    quantity,
    item_id: itemId,
  };
  dispatch({ type: FETCH_BOTTOMSHEET_MODAL, params: { loading: true, labelText, isFetching: false } });
  APIService.addToCartModal(data, itemId)
    .then(response => {
      dispatch({ type: FETCH_BOTTOMSHEET_MODAL, params: { loading: false, labelText, isFetching: false } });
      if (response?.data?.shouldOpenModal) {
        dispatch({
          type: BOTTOMSHEET_MODAL,
          params: {
            show: response.data.shouldOpenModal,
            src: response.data.target,
            deviceType,
            isDismissible: response.data.is_dismissible,
          },
        });
      } else if (response?.data?.target) {
        window.location.href = response.data.target;
      } else if (response?.data?.error_message) {
        dispatch({ type: FETCH_ERROR, error: response.data.error_message });
      }
    })
    .catch(e => {
      dispatch({ type: FETCH_BOTTOMSHEET_MODAL, params: { loading: false, labelText, isFetching: false } });
      dispatch({ type: FETCH_ERROR, error: e });
    });
};

const showQuestionsAiModal = ({ target, deviceType, isDismissible }) => dispatch => {
  dispatch({
    type: BOTTOMSHEET_MODAL,
    params: { show: true, src: target, deviceType, isDismissible },
  });
};

const showPaymentsSplitModal = ({ target, deviceType, isDismissible }) => dispatch => {
  dispatch({
    type: BOTTOMSHEET_MODAL,
    params: { show: true, src: target, deviceType, isClosable: isDismissible },
  });
};

const updateIframeModal = params => dispatch => {
  dispatch({
    type: BOTTOMSHEET_MODAL,
    params,
  });
};

const bottomSheetClose = () => dispatch => {
  dispatch({ type: BOTTOMSHEET_MODAL_CLOSE });
};

const updateComponentsBottomSheet = () => (dispatch, getState) => {
  const {
    id,
    applied_product_filters,
    filters: pdp_filters,
    selected_offer_type: offer_type,
    components,
    components: {
      available_quantity: { picker: { selected: quantity } = { selected: null } },
    },
  } = getState();
  const attributes = getSelectedAttributes(components);
  APIService.getProductOnSetQuantity(id, quantity, {
    pdp_filters,
    attributes,
    applied_product_filters,
    offer_type,
    a2c_update_freeshiping_bar: true,
  })
    .then(payload => {
      dispatch({ type: BOTTOMSHEET_MODAL_UPDATE, payload });
    })
    .catch(e => {
      dispatch({ type: FETCH_ERROR, error: e });
    });
};

const fetchOnePayForAll = actionKey => (dispatch, getState) => {
  const {
    components: {
      available_quantity: { picker: { selected: quantity } = { selected: null } },
      main_actions: { form, actions },
    },
    siteId,
  } = getState();

  dispatch({
    type: FETCH_ONE_PAY_FOR_ALL,
    payload: newPayloadFetchOnePayForAll(actions, true, actionKey),
  });

  APIService.buyNowOnePayForAll({ ...form, quantity })
    .then(response => {
      window.location.href = response.target;
    })
    .catch(e => {
      dispatch({
        type: FETCH_ONE_PAY_FOR_ALL,
        payload: newPayloadFetchOnePayForAll(actions, false, actionKey),
      });

      dispatch({
        type: SHOW_SNACKBAR,
        params: {
          message: e.response.data.displayMessage ?? getDefaultErrorMessage(siteId),
          type: 'error',
          delay: 3000,
          called_from: 'pdp',
        },
      });
    });
};

const triggerModalOnePayForAll = (actionKey, deviceType) => (dispatch, getState) => {
  const {
    app,
    components: {
      main_actions: { actions },
    },
    preload,
    siteId,
  } = getState();

  dispatch({
    type: FETCH_ONE_PAY_FOR_ALL,
    payload: newPayloadFetchOnePayForAll(actions, true, actionKey),
  });

  const action = actions.find(act => actionKey === act.label.text);

  // TODO-1pay4all: Levantar el track desde acá para usar al momento de usar trackEvent
  // const { fallback, target, type, disabled, track } = action;
  const { fallback, target, type, disabled } = action;

  let fallbackUrl = fallback;

  // Datadog config
  const statsdConfig = {
    keyPrefix: VPP_SEQUENCER_DATADOG_KEY_PREFIX,
    baseTags: {
      button_type: type === 'primary' ? BUTTON_TYPES_TAG_VALUES.BUY_NOW : BUTTON_TYPES_TAG_VALUES.ADD_TO_CART,
      referer_app: app,
      is_disabled: Boolean(disabled),
    },
  };

  // DATADOG - action click
  feStatsdHelper({
    key: VPP_SEQUENCER_DATADOG_KEY_CASES.ACTION_CLICK,
    aditionalTags: {
      main_action_target: Boolean(target),
      main_action_fallback: Boolean(fallback),
      main_action_empty: !target && !fallback,
    },
    aditionalInfo: { action },
    shouldLogMessage: !target || !fallback,
    statsdConfig,
  });

  if (!target) {
    dispatch({
      type: FETCH_ONE_PAY_FOR_ALL,
      payload: newPayloadFetchOnePayForAll(actions, false, actionKey),
    });
    redirectOrShowSnackbar({
      dispatch,
      fallbackToRedirect: fallbackUrl,
      reason: REASON_TAG_VALUES.NO_MAIN_ACTION_TARGET,
      siteId,
      // TODO-1pay4all: Dejamos esto comentado porque de momento no queremos enviar tracks (hasta validarlos)
      // trackData: track,
    });
    return;
  }

  const params = Object.fromEntries(new URLSearchParams(target.split('?')[1]));

  APIService.getSequencerOnePayForAll(params)
    .then(response => {
      const knownCases = [
        ACTIONS_SEQUENCER.BOTTOM_SHEET_WITH_PRELOAD,
        ACTIONS_SEQUENCER.BOTTOM_SHEET,
        ACTIONS_SEQUENCER.SNACKBAR,
        ACTIONS_SEQUENCER.NAVIGATE,
      ];
      const actionType = response?.actions?.action_type ?? '';
      const targetUrl = response?.actions?.target;
      const responseFallback = response?.actions?.fallback;
      if (responseFallback) {
        fallbackUrl = responseFallback;
      }
      const fallbackConfig = {
        fallbackUrl,
        fallbackMessage: response?.actions?.fallback_message,
        timeoutInitialLoad: response?.actions?.timeout,
        timeoutWebviewMessage: response?.actions?.timeout_webview_message,
      };
      const isDismissible = response?.actions?.bottom_sheet?.is_dismissible;
      const snackbarMessage = response?.actions?.snackbar?.message;
      const snackbarType = response?.actions?.snackbar?.type;
      const sequencerTrack = response?.actions?.sequencer_track;

      dispatch({
        type: FETCH_ONE_PAY_FOR_ALL,
        payload: newPayloadFetchOnePayForAll(actions, false, actionKey),
      });

      if (sequencerTrack) {
        // TODO-1pay4all: Validar path original vpp/sequencer y accion "response"
        trackEvent(updateOnePayForAllTrack(sequencerTrack, ACTIONS_SEQUENCER_TRACK.RESPONSE));
      }

      // DATADOG - get response
      feStatsdHelper({
        key: VPP_SEQUENCER_DATADOG_KEY_CASES.GET_RESPONSE,
        statsdConfig,
        aditionalTags: {
          response_target: Boolean(targetUrl),
          response_fallback: Boolean(responseFallback),
          response_empty: !targetUrl && !responseFallback,
          response_case: actionType,
        },
        aditionalInfo: { response, params },
        shouldLogMessage: !targetUrl || !responseFallback || !knownCases.includes(actionType),
      });

      switch (actionType) {
        case ACTIONS_SEQUENCER.BOTTOM_SHEET_WITH_PRELOAD:
          if (!preload?.isIframeLoaded) {
            redirectOrShowSnackbar({
              dispatch,
              siteId,
              fallbackToRedirect: fallbackUrl,
              reason: REASON_TAG_VALUES.NO_WEBVIEW_PRELOADED,
              message: fallbackConfig.fallbackMessage,
              // TODO-1pay4all: Dejamos esto comentado porque de momento no queremos enviar tracks (hasta validarlos)
              // trackData: sequencerTrack,
            });
            break;
          }
          feStatsdHelper({
            key: VPP_SEQUENCER_DATADOG_KEY_CASES.FLOW_END,
            statsdConfig,
            aditionalTags: {
              end_case: actionType,
            },
          });
          dispatch({
            type: ONE_PAY_FOR_ALL_SHOW_MODAL,
            params: {
              show: true,
              fallback: fallbackUrl,
            },
          });
          break;
        case ACTIONS_SEQUENCER.BOTTOM_SHEET:
          if (!targetUrl) {
            redirectOrShowSnackbar({
              dispatch,
              siteId,
              fallbackToRedirect: fallbackUrl,
              reason: REASON_TAG_VALUES.NO_BOTTOMSHEET_TARGET,
              message: fallbackConfig.fallbackMessage,
              // TODO-1pay4all: Dejamos esto comentado porque de momento no queremos enviar tracks (hasta validarlos)
              // trackData: sequencerTrack,
            });
            break;
          }
          if (sequencerTrack) {
            // TODO-1pay4all: Validar path original vpp/sequencer y accion "loading"
            trackEvent(updateOnePayForAllTrack(sequencerTrack, ACTIONS_SEQUENCER_TRACK.LOADING));
          }
          dispatch({
            type: BOTTOMSHEET_MODAL,
            params: {
              show: true,
              src: targetUrl,
              deviceType,
              fallbackConfig,
              statsdConfig,
              isDismissible,
              sequencerTrack,
            },
          });
          break;
        case ACTIONS_SEQUENCER.SNACKBAR:
          feStatsdHelper({
            key: VPP_SEQUENCER_DATADOG_KEY_CASES.FLOW_END,
            statsdConfig,
            aditionalTags: {
              end_case: actionType,
              snackbar_type: snackbarType ?? 'error',
            },
          });
          dispatch({
            type: SHOW_SNACKBAR,
            params: {
              message: snackbarMessage ?? getDefaultErrorMessage(siteId),
              type: snackbarType ?? 'error',
              delay: 3000,
              called_from: 'pdp',
            },
          });
          break;
        case ACTIONS_SEQUENCER.NAVIGATE:
          if (!targetUrl) {
            redirectOrShowSnackbar({
              dispatch,
              siteId,
              fallbackToRedirect: fallbackUrl,
              reason: REASON_TAG_VALUES.NO_NAVIGATE_TARGET,
              message: fallbackConfig.fallbackMessage,
              // TODO-1pay4all: Dejamos esto comentado porque de momento no queremos enviar tracks (hasta validarlos)
              // trackData: sequencerTrack,
            });
            break;
          }
          feStatsdHelper({
            key: VPP_SEQUENCER_DATADOG_KEY_CASES.FLOW_END,
            statsdConfig,
            aditionalTags: {
              end_case: actionType,
            },
          });
          window.location.href = targetUrl;
          break;
        default:
          redirectOrShowSnackbar({
            dispatch,
            siteId,
            fallbackToRedirect: null,
            reason: REASON_TAG_VALUES.UNKNOW_CASE,
            message: fallbackConfig.fallbackMessage,
            // TODO-1pay4all: Dejamos esto comentado porque de momento no queremos enviar tracks (hasta validarlos)
            // trackData: sequencerTrack,
          });
          break;
      }
    })
    .catch(error => {
      dispatch({
        type: FETCH_ONE_PAY_FOR_ALL,
        payload: newPayloadFetchOnePayForAll(actions, false, actionKey),
      });
      redirectOrShowSnackbar({
        dispatch,
        fallbackToRedirect: fallbackUrl,
        reason: REASON_TAG_VALUES.CATCH_SEQUENCER,
        aditionalInfo: { error, params },
        shouldLogMessage: true,
        siteId,
        // TODO-1pay4all: Dejamos esto comentado porque de momento no queremos enviar tracks (hasta validarlos)
        // trackData: track,
      });
    });
};

const onIframeLoadedOnePayForAll = () => dispatch => {
  dispatch({ type: ONE_PAY_FOR_ALL_IFRAME_LOADED });
};

const onePayForAllShowModal = (show, fallback = '') => dispatch => {
  dispatch({ type: ONE_PAY_FOR_ALL_SHOW_MODAL, params: { show, fallback } });
};

const toggleFollowSeller = () => (dispatch, getState) => {
  const {
    app,
    components: { seller_data: sellerData },
    siteId,
  } = getState();
  const { followers } = sellerData.components.find(el => el.id === 'seller_header');

  dispatch({ type: TOGGLE_FOLLOW_OPTIMISTICALLY });
  if (followers.cta_status === 'do_follow') {
    trackEvent(followers.cta_do_follow.track_event);
    APIService.followSeller(followers.seller_id, app, siteId)
      .then(payload => {
        if (payload.status === 'ERROR') {
          dispatch({
            type: SHOW_SNACKBAR,
            params: {
              message: payload.snackbar?.message,
              type: payload.snackbar?.color,
              delay: payload.snackbar?.delay || 3000,
              called_from: 'follow_button',
            },
          });
        }
        dispatch({ type: FETCH_ON_SET_FOLLOW_COMPLETE, payload });
      })
      .catch(e => dispatch({ type: SHOW_SNACKBAR, error: e }));
  } else {
    trackEvent(followers.cta_stop_follow.track_event);
    APIService.unfollowSeller(followers.seller_id, app, siteId)
      .then(payload => {
        if (payload.status === 'ERROR') {
          dispatch({
            type: SHOW_SNACKBAR,
            params: {
              message: payload.snackbar?.message,
              type: payload.snackbar?.color,
              delay: payload.snackbar?.delay || 3000,
              called_from: 'follow_button',
            },
          });
        }
        dispatch({ type: FETCH_ON_SET_FOLLOW_COMPLETE, payload });
      })
      .catch(e => dispatch({ type: SHOW_SNACKBAR, error: e }));
  }
};

const openWishlistModalBS = () => dispatch => {
  dispatch({ type: HIDE_SNACKBAR });
  dispatch({ type: WISHLIST_SHOW_BOTTOMSHEET_MODAL });
};

const closeWishlistModalBS = isBookmarked => dispatch => {
  dispatch({ type: WISHLIST_CLOSE_BOTTOMSHEET_MODAL, payload: { isBookmarked } });
};

const redirectToLogin = ({ itemId, loginType, featureName }) => () => {
  try {
    APIService.redirectToLogin(itemId, loginType, featureName);
  } catch (error) {
    throw new Error(error);
  }
};

const saveFrontendStatsd = data => (dispatch, getState) => {
  const { app: referer_app } = getState();

  if (!data) {
    return;
  }

  data.tags.referer_app = referer_app;

  APIService.saveFrontendStatsd(data);
};

const postQuestionFromAi = ({ productId, itemId, text, track, attributes, quantity }) => (dispatch, getState) => {
  const { app } = getState();

  dispatch({ type: FETCH_QUESTION_AI_START });

  if (track) {
    tracking({ track });
  }

  APIService.postQuestion(itemId, { productId, text, app, attributes, quantity })
    .then(payload => {
      if (payload) {
        if (payload.track) {
          tracking({ track: payload.track });
        }

        if (payload.make_action) {
          dispatch(
            onCreateQuestionFromAi({
              itemId,
              text: payload.make_action.config.user_question,
              snackbar_message: payload.make_action.snackbar_message,
            }),
          );
        } else {
          dispatch({ type: FETCH_QUESTION_AI_COMPLETE, payload: arrayToObjectById(payload.components) });
        }
      }
    })
    .catch(e => {
      dispatch({ type: FETCH_QUESTION_AI_ERROR, error: e });
    });
};

const isUseful = ({ itemId, useful, id, response, track }) => (dispatch, getState) => {
  const { app } = getState();

  if (track) {
    tracking({ track });
  }

  APIService.likeDislikeQuestionsAi(itemId, { useful, id, response, app })
    .then(() => {})
    .catch(e => {
      dispatch({ type: FETCH_ERROR, error: e });
    });
};

module.exports = {
  dislikeReview,
  fetchErrorSnackHide,
  fetchComponents,
  fetchUpdatedCoupon,
  mobileUpdateCoupon,
  fetchUpdatedCouponBuyBoxOffers,
  postCouponActivate,
  postCouponActivateBuyBoxOffers,
  mobileActivateCoupon,
  fetchUpdatedComponents,
  selectPaymentMethod,
  setPickersCollapse,
  fetchShippingPromise,
  fetchProductOnMeliplusUpdate,
  fetchVariationDetailsByIds,
  fetchOnSetQuantity,
  likeReview,
  onCreateQuestion,
  onQuantityShowInput,
  toggleBookmark,
  fetchBookmark,
  fetchDeferredComponent,
  showQuantityError,
  showVariationsError,
  addToCartUpdate,
  showSnackbar,
  hideSnackbar,
  updateTradeIn,
  removeTradeIn,
  showAddToCartModal,
  updateIframeModal,
  bottomSheetClose,
  showQuestionsAiModal,
  showPaymentsSplitModal,
  onCreateQuestionFromAi,
  fetchOnePayForAll,
  triggerModalOnePayForAll,
  onePayForAllShowModal,
  openWishlistModalBS,
  closeWishlistModalBS,
  redirectToLogin,
  updateComponentsBottomSheet,
  onIframeLoadedOnePayForAll,
  addElementToList,
  removeElementFromList,
  fetchUpdatedWishlists,
  toggleFollowSeller,
  saveFrontendStatsd,
  postQuestionFromAi,
  isUseful,
  toggleGiftRegistryCheckbox,
};
