const { loadable } = require('nordic/lazy');
const compose = require('../../../../lib/compose');
const connect = require('../../../../store/connect');
const withFeatureFlag = require('../../../../components/feature-flag/with-feature-flag');

const Tooltip = loadable(() => import('../../../../components/card-tooltip'));

const FEATURE_NAME = 'credits_tooltip';

const mapStateToProps = ({ components: { [FEATURE_NAME]: creditsTooltip } }, ownProps) => ({
  ...creditsTooltip,
  ...ownProps,
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(mapStateToProps),
);

module.exports = enhance(Tooltip);
