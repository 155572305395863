const { useState, useEffect } = require('react');

const receiveMessage = (message, setReviewsVisible) => {
  if (message.data === 'close=') {
    // cerrar andes modal
    setReviewsVisible(false);
    return true;
  }
  return false;
};

const usePostClose = initState => {
  const [componentVisible, setComponentVisible] = useState(initState);

  useEffect(() => {
    window.addEventListener('message', message => receiveMessage(message, setComponentVisible));

    return () => {
      window.removeEventListener('message', receiveMessage);
    };
  });

  return [componentVisible, setComponentVisible];
};

module.exports = usePostClose;
