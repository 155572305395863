const React = require('react');
const { useState, useContext } = require('react');
const { arrayOf, bool, shape, string } = require('prop-types');
const classNames = require('classnames');
const ShareContent = require('./share-content.mobile');
const BackdropModal = require('../backdrop-modal');
const { supportsWebShareApi } = require('../../lib/dom-utils');
const StaticPropsContext = require('../context/static-props');
const IconFactory = require('../icons/factory-builder')();

const namespace = 'ui-pdp-share';

// global document navigator
const Share = ({
  action: {
    label: { text },
    icon,
  },
  modifier,
  iconVariation,
  title,
  shareActions,
  showLabel,
  closeModalLabel,
}) => {
  const [isOpenModal, setOpenModal] = useState(false);
  const { lowEnd } = useContext(StaticPropsContext);
  const hasSupportsWebShareApi = supportsWebShareApi();

  if (lowEnd) {
    return (
      <div className={namespace}>
        <label htmlFor={`share-mobile-${modifier}`} className={`${namespace}__link`}>
          <input
            className={classNames(`${namespace}__input`, `${namespace}__input--${modifier}`)}
            type="checkbox"
            id={`share-mobile-${modifier}`}
          />
          <div className={classNames(`${namespace}__tooltip`, `${namespace}__tooltip--${modifier}`)}>
            <ShareContent modifier="just-icons" showIconLabel={false} shareActions={shareActions} />
          </div>
          <span className={`${namespace}__link`}>
            {IconFactory({ id: `${icon.id}${iconVariation}` }, `${namespace}__link__icon`)}
            {showLabel && <span className={`${namespace}__link__label`}>{text}</span>}
          </span>
        </label>
      </div>
    );
  }

  const onActionRowClick = e => {
    e.preventDefault();
    if (hasSupportsWebShareApi) {
      navigator.share({
        text: document.title,
        url: document.location.href,
      });
    } else {
      setOpenModal(true);
    }
  };

  return (
    <div className={namespace}>
      <a className={`${namespace}__link`} title={text} href="/share" onClick={onActionRowClick}>
        {IconFactory({ id: `${icon.id}${iconVariation}` })}
        {showLabel && <span className={`${namespace}__link__label`}>{text}</span>}
      </a>
      {!hasSupportsWebShareApi && (
        <BackdropModal
          url={iconVariation ? `#share-${iconVariation}` : '#share'}
          visible={isOpenModal}
          onClose={() => setOpenModal(false)}
          closeModalLabel={closeModalLabel}
        >
          <ShareContent title={title.text} shareActions={shareActions} />
        </BackdropModal>
      )}
    </div>
  );
};

Share.propTypes = {
  action: shape({
    label: shape({
      text: string.isRequired,
    }).isRequired,
    icon: shape({
      id: string.isRequired,
    }),
  }).isRequired,
  closeModalLabel: string,
  iconVariation: string,
  modifier: string,
  shareActions: arrayOf(
    shape({
      target: string,
      icon: shape({
        id: string,
      }),
      label: shape({
        text: string,
      }),
    }),
  ).isRequired,
  showLabel: bool,
  title: shape({
    text: string,
    color: string,
  }),
};

Share.defaultProps = {
  modifier: 'body',
  iconVariation: '',
  title: null,
  showLabel: true,
};

module.exports = Share;
