const React = require('react');
const { useContext } = require('react');
const { bool, string, shape, number } = require('prop-types');
const classnames = require('classnames');
const StaticPropsContext = require('../context/static-props');
const Message = require('../message');
const getAction = require('../../utils/getActionModal');

const namespace = 'ui-pdp-shipping-message';
const ShippingMessage = ({ className, body, message_type, closeable, hierarchy, primary_action }) => {
  const { deviceType } = useContext(StaticPropsContext);

  return (
    <Message
      className={classnames(namespace, className)}
      actions={primary_action && getAction(primary_action, deviceType, namespace)}
      type={message_type}
      closeable={closeable}
      hierarchy={hierarchy}
    >
      <div className={`${namespace}__text`}>{body.text}</div>
    </Message>
  );
};

ShippingMessage.propTypes = {
  className: string,
  body: shape({
    text: string.isRequired,
  }).isRequired,
  message_type: string,
  closeable: bool,
  hierarchy: string,
  primary_action: shape({
    target: string.isRequired,
    timeout: number,
    duration: number,
    label: shape({
      text: string,
      color: string,
    }),
    icon: shape({
      id: string,
      color: string,
    }),
  }),
};

ShippingMessage.defaultProps = {
  className: '',
  message_type: 'warning',
  closeable: false,
  hierarchy: 'quiet',
  primary_action: null,
};

module.exports = ShippingMessage;
