const React = require('react');
const { arrayOf, shape, string } = require('prop-types');
const classnames = require('classnames');

const colornames = require('../../lib/colornames');

const IconFactory = require('../icons/factory-builder')();

const Media = require('../media');

const namespace = 'ui-pdp-benefits';

const Benefits = ({ benefits, className }) => (
  <ul className={classnames(namespace, className)}>
    {benefits.map(benefit => (
      <li className={`${namespace}__item`} key={benefit.text}>
        <Media className={classnames(colornames(benefit.icon))} figure={IconFactory(benefit.icon)} title={benefit} />
      </li>
    ))}
  </ul>
);

Benefits.propTypes = {
  benefits: arrayOf(
    shape({
      text: string.isRequired,
      icon: shape({
        id: string.isRequired,
        color: string,
      }),
      color: string,
      values: shape(),
    }),
  ).isRequired,
  className: string,
};

Benefits.defaultProps = {
  className: null,
};

module.exports = React.memo(Benefits);
