const React = require('react');
const Grid = require('../../../../../../components/grid');

const PaymentMethods = require('../../../../features/payment-methods/payment-methods.mobile');

const PaymentsChunk = () => (
  <Grid.Row className="ui-pdp-with--separator">
    <PaymentMethods className="pt-24" />
  </Grid.Row>
);

module.exports = PaymentsChunk;
