const React = require('react');
const { func, number, string, shape, arrayOf } = require('prop-types');
const { loadable } = require('nordic/lazy');
const { PROVIDER, MULTIMEDIA_PROVIDERS } = require('../../../figure/constants');
const CarouselSnapped = require('../../../carousel-snapped');

const Picture = require('./picture');

const Clip = loadable(() => import('./clip'));
const Multimedia = loadable(() => import('./multimedia'));

const namespace = 'ui-pdp-gallery';

const MOBILE_PROVIDERS_ALLOWED = [PROVIDER.CLIP, PROVIDER.IMAGE, ...MULTIMEDIA_PROVIDERS];
const filterFiguresForMobile = figures => figures.filter(item => MOBILE_PROVIDERS_ALLOWED.includes(item.provider));

const GalleryCarousel = ({
  id: galleryId,
  figures,
  clipsConfig,
  galleryType,
  minHeight,
  maxHeight,
  shapes,
  accessibility_text,
  counter,
  setCounter,
  onTapFigure,
  app,
  overlayAlpha,
}) => (
  <CarouselSnapped
    key={galleryId}
    accessibility_text={accessibility_text}
    counter={counter}
    setCounter={setCounter}
    shapes={shapes}
    overlayAlpha={overlayAlpha}
    app={app}
  >
    {filterFiguresForMobile(figures).map((item, index) => {
      const { provider, id, ...restItem } = item;
      const onClick = e => onTapFigure(item, e);

      if (MULTIMEDIA_PROVIDERS.includes(provider)) {
        return (
          <Multimedia
            key={String(id || index)}
            id={id}
            item={restItem}
            onClick={onClick}
            namespace={namespace}
            galleryType={galleryType}
            provider={provider}
          />
        );
      }

      if (provider === PROVIDER.CLIP) {
        return (
          <Clip
            key={String(id || index)}
            id={id}
            item={restItem}
            onClick={onClick}
            namespace={namespace}
            clipsConfig={clipsConfig}
          />
        );
      }

      if (provider === PROVIDER.IMAGE) {
        return (
          <Picture
            key={String(id || index)}
            id={id}
            item={restItem}
            namespace={namespace}
            index={index}
            galleryType={galleryType}
            maxHeight={maxHeight}
            minHeight={minHeight}
            onClick={onClick}
          />
        );
      }

      return null;
    })}
  </CarouselSnapped>
);

GalleryCarousel.propTypes = {
  id: string.isRequired,
  figures: arrayOf(shape({})).isRequired,
  accessibility_text: string.isRequired,
  counter: number.isRequired,
  setCounter: func.isRequired,
  shapes: arrayOf(shape({})).isRequired,
  onTapFigure: func.isRequired,
  clipsConfig: shape({}),
  galleryType: string.isRequired,
  maxHeight: string,
  minHeight: string,
  app: string,
  overlayAlpha: string,
};
module.exports = GalleryCarousel;
