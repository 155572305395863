const { loadable } = require('nordic/lazy');
const compose = require('../../../../lib/compose');
const connect = require('../../../../store/connect');
const withFeatureFlag = require('../../../../components/feature-flag/with-feature-flag');
const withRow = require('../../../../components/grid/row/with-row');

const HeaderOfficialStore = loadable(() => import('../../../../components/header-official-store/header-mobile'));

const FEATURE_NAME = 'header_official_store';

const mapStateToProps = ({ components: { [FEATURE_NAME]: props } }) => ({
  ...props,
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  withRow(FEATURE_NAME),
  connect(mapStateToProps),
);

module.exports = enhance(HeaderOfficialStore);
