const React = require('react');
const { arrayOf, string, shape, number } = require('prop-types');
const { useState, useEffect } = require('react');
const classnames = require('classnames');
const { loadable } = require('nordic/lazy');
const Image = require('../image');
const { trackEvent, trackEventWithCustomFields } = require('@vpp-frontend-components/common');
const useTrackView = require('../../hooks/use-track-view');
const { ActionRow } = require('@vpp-frontend-components/action-row');
const useLightbox = require('../gallery/hooks/use-lightbox');
const DEFAULT_LIGHTBOX_SETTINGS = require('./constants');
const { generateKeyToComponent } = require('../../utils/validators');

const Lightbox = loadable(data => (data.show ? import('../lightbox') : null));

const namespace = 'ui-pdp-vertical-gallery';

const VerticalGalleryCarousel = ({
  title,
  pictures,
  image_screen_ratio,
  max_show_images,
  action,
  open_image_track,
  className,
  viewport_track,
}) => {
  const [windowHeight, setWindowHeight] = useState(0);
  const [windowWidth, setWindowWidth] = useState(0);

  const [index, setIndex] = useLightbox(pictures);
  const [modalVisibility, setModalVisibility] = useState(false);
  const viewRef = useTrackView(viewport_track, { threshold: 0.3 });

  const picturesToShow = pictures.reduce((acc, picture) => {
    if (picture.src) {
      const { zoom, ...restPicture } = picture;
      const updatedPicture = {
        ...restPicture,
        src: (zoom && zoom.src) || picture.src,
        msrc: picture.src,
        thumbnail: picture.src,
      };
      acc.push(updatedPicture);
    }
    return acc;
  }, []);

  const handleButtonTap = e => {
    e.preventDefault();
    if (action.track) {
      trackEvent(action.track);
    }
    setIndex(max_show_images);
    setModalVisibility(true);
  };

  const handleImageTap = (event, imageIndex) => {
    event.preventDefault();
    if (open_image_track) {
      trackEventWithCustomFields(open_image_track, { order: (imageIndex + 1).toString() });
    }
    setIndex(imageIndex);
    setModalVisibility(true);
  };

  useEffect(() => {
    const deviceHeight = window.outerHeight;
    const deviceWidth = window.outerWidth;
    setWindowHeight(deviceHeight);
    setWindowWidth(deviceWidth);
  }, [image_screen_ratio]);

  return (
    <div ref={viewRef} className={classnames(namespace, className)}>
      <h2 className={`${namespace}__title`}>{title.text}</h2>
      <div className={`${namespace}__container`}>
        {pictures.map((picture, i) => {
          if (!picture.src || i > max_show_images - 1) {
            return null;
          }
          const { width, height, thumbnail, zoom, ...restImageProps } = picture;
          const imageClassnames = classnames({
            [`${namespace}--horizontal`]: width >= height,
            [`${namespace}--vertical`]: height > width,
          });
          let heightWithRatio = windowHeight * image_screen_ratio - 32;
          if (width > height) {
            heightWithRatio = ((windowWidth - 32) * height) / width;
          }

          return (
            <div key={generateKeyToComponent(picture)} className={`${namespace}__image`}>
              <a
                data-testid={`gallery-picture-${i}`}
                key={picture.id}
                href={picture.src}
                onClick={event => handleImageTap(event, i)}
                className={`${namespace}--tap`}
              >
                <Image alt="" height={heightWithRatio} {...restImageProps} className={imageClassnames} />
              </a>
            </div>
          );
        })}
      </div>
      {pictures.length > max_show_images && (
        <ActionRow
          className={`${namespace}__action-row`}
          label={action.label.text}
          modifier="secondary"
          onClick={handleButtonTap}
        />
      )}

      {modalVisibility && (
        <Lightbox
          show={modalVisibility}
          pictures={picturesToShow}
          index={index}
          onClose={() => {
            setModalVisibility(false);
            setIndex(0);
          }}
          settings={DEFAULT_LIGHTBOX_SETTINGS}
        />
      )}
    </div>
  );
};

VerticalGalleryCarousel.propTypes = {
  viewport_track: shape({}),
  open_image_track: shape({}),
  action: shape({
    target: string,
    label: shape({
      text: string.isRequired,
      color: string.isRequired,
    }).isRequired,
  }).isRequired,
  max_show_images: number,
  title: shape({
    text: string.isRequired,
    color: string.isRequired,
  }).isRequired,
  pictures: arrayOf(
    shape({
      id: string.isRequired,
      alt: string.isRequired,
      src: string.isRequired,
      src2x: string,
    }),
  ).isRequired,
  className: string,
  image_screen_ratio: number,
};

VerticalGalleryCarousel.defaultProps = {
  open_image_track: null,
  viewport_track: null,
  className: '',
  max_show_images: 0,
  image_screen_ratio: 0,
};

module.exports = VerticalGalleryCarousel;
