const { loadable } = require('nordic/lazy');
const compose = require('../../../../lib/compose');
const connect = require('../../../../store/connect');

const DynamicComponentWrapper = loadable(() => import('../../../../components/dynamic-component-wrapper'));

const mapStateToProps = ({ components }, { spot, renderDynamicComponent, className }) => ({
  components,
  spot,
  renderDynamicComponent,
  className,
});

const enhance = compose(connect(mapStateToProps));

module.exports = enhance(DynamicComponentWrapper);
