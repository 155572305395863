const React = require('react');
const Grid = require('../../../../../../components/grid');

const Specs = require('../../../../features/specs/specs.mobile');
const HighlightedSpecsAttrsSwap = require('../../../../features/highlighted-specs-swap/highlighted-specs-attrs.mobile');

const HighlightedSpecsSwapChunk = () => (
  <>
    <Grid.Row className="ui-pdp-html-description ui-pdp-with--separator">
      <HighlightedSpecsAttrsSwap />
    </Grid.Row>
    <Grid.Row>
      <Specs className="pl-16 pr-16" />
    </Grid.Row>
  </>
);

module.exports = HighlightedSpecsSwapChunk;
