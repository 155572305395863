const React = require('react');
const { useCallback } = require('react');
const { bool, string, number, shape, func, arrayOf } = require('prop-types');
const { Button } = require('@andes/button');
const QuantityInput = require('./quantity-input');
const QuantityList = require('./quantity-list');
const { trackQuantityEvent } = require('../../../lib/tracking');
const StyledLabel = require('../../styled-label');
const componentEnhance = require('../../../lib/component-enhance');
const { generateKeyToComponent } = require('../../../utils/validators');

const namespace = 'ui-pdp-buybox__quantity';

const InputButton = ({ className, disabled, onClick, confirm }) => (
  <Button className={className} onClick={onClick} disabled={disabled}>
    {confirm}
  </Button>
);

InputButton.propTypes = {
  className: string.isRequired,
  confirm: string.isRequired,
  disabled: bool.isRequired,
  onClick: func.isRequired,
};

const QuantitySelector = ({ showInput, selected, setQuantity, quantitySelector, onShowInput }) => {
  const yieldValue = quantitySelector?.selector?.template?.yield;
  const bulkSale = yieldValue != null && yieldValue > 0;

  const onSetQuantity = useCallback(q => {
    setQuantity(q);
    if (bulkSale) {
      trackQuantityEvent(quantitySelector.track, Math.ceil(parseFloat((q / yieldValue).toFixed(2))));
    } else {
      trackQuantityEvent(quantitySelector.track, q);
    }
  }, [quantitySelector, setQuantity, bulkSale, yieldValue]);

  const minimumQuantity = bulkSale ? yieldValue : quantitySelector?.minimum_quantity || 1;

  let title = quantitySelector.selector.title.text;
  if (showInput) {
    title = bulkSale ? quantitySelector.selector.template.title : title;
  }

  return (
    <>
      <h4 className={`${namespace}__title`}>{title}</h4>
      {showInput ? (
        <QuantityInput
          confirm={quantitySelector.input.confirm_button.label.text}
          button={InputButton}
          length={quantitySelector.selector.rows}
          noStockMessage={quantitySelector.input.error_message}
          minErrorMessage={quantitySelector.input.min_error_message}
          quantity={selected}
          subtitles={quantitySelector.input.subtitles}
          setQuantity={onSetQuantity}
          bulkSale={bulkSale}
          min={minimumQuantity}
          max={bulkSale ? quantitySelector.available_quantity * yieldValue : quantitySelector.available_quantity}
          placeholder={quantitySelector.input.placeholder}
          mobile
        />
      ) : (
        <>
          {quantitySelector.selector.subtitles &&
            quantitySelector.selector.subtitles.map(subtitle => (
              <StyledLabel
                key={generateKeyToComponent(subtitle)}
                className={`${namespace}__subtitle`}
                as="p"
                {...subtitle}
                text={componentEnhance.jsx(subtitle.text, subtitle.values)}
              />
            ))}
          <QuantityList
            length={quantitySelector.selector.rows}
            label={quantitySelector.selector.template}
            min={minimumQuantity}
            max={bulkSale ? quantitySelector.available_quantity * yieldValue : quantitySelector.available_quantity}
            yieldValue={bulkSale ? yieldValue : null}
            more={quantitySelector.selector.template.more}
            selected={selected}
            onClick={onSetQuantity}
            onShowInput={onShowInput}
          />
        </>
      )}
    </>
  );
};

QuantitySelector.propTypes = {
  quantitySelector: shape({
    type: string,
    available_quantity: number,
    selector: shape({
      title: shape({
        text: string,
        color: string,
      }),
      subtitles: arrayOf(
        shape({
          text: string,
          color: string,
          font_size: string,
          font_family: string,
        }),
      ),
      template: shape({
        singular: string,
        plural: string,
        more: string,
      }).isRequired,
      rows: number,
    }).isRequired,
    input: shape({
      title: shape({
        text: string,
        color: string,
      }),
      subtitles: arrayOf(
        shape({
          text: string,
          color: string,
          font_size: string,
          font_family: string,
        }),
      ),
      placeholder: string,
      confirm_button: shape({ label: shape({ text: string }) }),
      error_message: shape({
        text: string,
        color: string,
      }),
    }).isRequired,
    track: shape({}),
  }),
  selected: string.isRequired,
  setQuantity: func.isRequired,
  showInput: bool.isRequired,
  onShowInput: func.isRequired,
};

QuantitySelector.defaultProps = {
  quantitySelector: null,
};

module.exports = QuantitySelector;
