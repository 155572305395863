const compose = require('../../../../lib/compose');
const connect = require('../../../../store/connect');
const actions = require('../../../../actions');
const withFeatureFlag = require('../../../../components/feature-flag/with-feature-flag');

const FEATURE_NAME = 'questions';

const mapStateToProps = (
  {
    components: {
      [FEATURE_NAME]: {
        id,
        form,
        question_message,
        quick_access,
        track,
        show_track,
        title,
        messages,
        disabled,
        url,
        stockModal,
        custom_message,
        ...rest
      },
    },
    id: productId,
    attributes,
  },
  ownProps,
) => ({
  id,
  form,
  question_message,
  quick_access,
  title,
  track,
  show_track,
  messages,
  disabled,
  url,
  stockModal,
  custom_message,
  productId,
  attributes,
  ...rest,
  ...ownProps,
});

const mapDispatchToProps = dispatch => ({
  onCreateQuestion: ({ itemId, text, track }) => dispatch(actions.onCreateQuestion({ itemId, text, track })),
  showVariationsError: (deviceType, actionKey) => dispatch(actions.showVariationsError(deviceType, actionKey)),
  showQuestionsAiModal: ({ target, deviceType }) => {
    dispatch(actions.showQuestionsAiModal({ target, deviceType }));
  },
  postQuestionFromAi: ({ productId, itemId, text, track, attributes, quantity }) =>
    dispatch(actions.postQuestionFromAi({ productId, itemId, text, track, attributes, quantity })),
  isUseful: ({ itemId, useful, id, response, track }) =>
    dispatch(actions.isUseful({ itemId, useful, id, response, track })),
  showSnackbar: ({ message, type, delay }) => dispatch(actions.showSnackbar({ message, type, delay })),
  onCreateQuestionFromAi: ({ itemId, text, snackbar_message, suggestionTrack }) => {
    dispatch(actions.onCreateQuestionFromAi({ itemId, text, snackbar_message, suggestionTrack }));
  },
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

module.exports = Component => enhance(Component);
exports = module.exports;
exports.mapDispatchToProps = mapDispatchToProps;
