const APIService = require('../services/api');

const COMPONENT_ID = 'bookmark_snackbar';
const CALLED_FROM = 'wishlist_save_button';
const classNameRemoveBookmark = 'snackbar--remove-bookmark';

const execAddElementToList = (element, dispatch, wishlist, SHOW_SNACKBAR, HIDE_SNACKBAR, FETCH_ERROR) => {
  APIService.addElementToList(element)
    .then(({ component = null }) => {
      if (component?.id === COMPONENT_ID) {
        const newDynamicTarget = component?.action?.target || null;
        const hubUrl = new URL(wishlist?.action?.target);
        hubUrl.pathname = newDynamicTarget;
        dispatch({
          type: SHOW_SNACKBAR,
          params: {
            message: component?.message,
            type: component?.message_type,
            delay: component?.delay?.duration || 3000,
            called_from: CALLED_FROM,
            className: classNameRemoveBookmark,
            action: {
              text: component?.action?.label?.text,
              onClick: () => {
                window.location.assign(hubUrl);
                dispatch({ type: HIDE_SNACKBAR, payload: {} });
              },
            },
          },
        });
      }
    })
    .catch(e => dispatch({ type: FETCH_ERROR, error: e }));
};

const execRemoveElementFromList = (element, dispatch, TOGGLE_BOOKMARKED, SHOW_SNACKBAR, FETCH_ERROR) => {
  APIService.removeElementFromList(element)
    .then(({ component = null, lists_with_element = null }) => {
      if (lists_with_element === 0) {
        dispatch({
          type: TOGGLE_BOOKMARKED,
        });
      }
      if (component?.id === COMPONENT_ID) {
        const { message, message_type, delay } = component;
        dispatch({
          type: SHOW_SNACKBAR,
          params: {
            message,
            type: message_type,
            delay: delay?.duration || 3000,
            called_from: CALLED_FROM,
            className: classNameRemoveBookmark,
          },
        });
      }
    })
    .catch(e => dispatch({ type: FETCH_ERROR, error: e }));
};

const execFetchUpdateWishlist = (dispatch, id, app, UPDATE_GIFT_REGISTRY, forceChecked, FETCH_ERROR) => {
  APIService.getUpdateWishlists(id, { app })
    .then(({ bookmark, components }) => {
      dispatch({
        type: UPDATE_GIFT_REGISTRY,
        payload: {
          bookmark,
          wishlist_save_button: components.wishlist_save_button,
          ...(forceChecked !== null && { check: forceChecked }),
        },
      });
    })
    .catch(e => {
      dispatch({ type: FETCH_ERROR, error: e });
    });
};

module.exports = { execAddElementToList, execRemoveElementFromList, execFetchUpdateWishlist };
