const { getDefaultErrorMessage } = require('./getDefaultErrorMessage');
const { feStatsdHelper } = require('../../utils/frontendStatsHelper');
const { trackEvent } = require('../../lib/tracking');
const { SHOW_SNACKBAR } = require('../actions');

const { VPP_SEQUENCER_DATADOG_KEY_CASES } = require('../../services/frontend-statsd/config/allowed-keys');

const ACTIONS_SEQUENCER = {
  BOTTOM_SHEET_WITH_PRELOAD: 'BOTTOM_SHEET_WITH_PRELOAD',
  BOTTOM_SHEET: 'BOTTOM_SHEET',
  SNACKBAR: 'SNACKBAR',
  NAVIGATE: 'NAVIGATE',
};

const ACTIONS_SEQUENCER_TRACK = {
  FALLBACK: 'fallback',
  SNACKBAR: 'snackbar',
  RESPONSE: 'response',
  LOADING: 'loading',
  LOADED: 'loaded',
};

const newActionLoading = (actions, isLoading, actionKey) =>
  actions.map(actionItem => {
    if (actionKey === actionItem.label.text) {
      return {
        ...actionItem,
        isFetchingData: isLoading,
        isLoadingButton: isLoading,
      };
    }
    return actionItem;
  });

const newPayloadFetchOnePayForAll = (actions, isLoading, actionKey = null) => ({
  actions: newActionLoading(actions, isLoading, actionKey),
  isFetching: isLoading,
});

const updateOnePayForAllTrack = (track, action) => {
  if (action) {
    return {
      ...track,
      melidata_event: {
        ...track.melidata_event,
        action,
      },
    };
  }
  return track;
};

const redirectOrShowSnackbar = ({
  fallbackToRedirect,
  reason,
  aditionalInfo,
  shouldLogMessage = false,
  trackData,
  message,
  dispatch,
  siteId,
}) => {
  if (fallbackToRedirect) {
    if (trackData) {
      trackEvent(updateOnePayForAllTrack(trackData, ACTIONS_SEQUENCER_TRACK.FALLBACK));
    }
    feStatsdHelper({
      key: VPP_SEQUENCER_DATADOG_KEY_CASES.REDIRECT_TO_FALLBACK,
      aditionalTags: {
        reason,
      },
      aditionalInfo,
      shouldLogMessage,
    });
    window.location.href = fallbackToRedirect;
  } else {
    if (trackData) {
      // TO DO: validar path original vpp/sequencer y accion "snackbar"
      trackEvent(updateOnePayForAllTrack(trackData, ACTIONS_SEQUENCER_TRACK.SNACKBAR));
    }
    feStatsdHelper({
      key: VPP_SEQUENCER_DATADOG_KEY_CASES.SHOW_SNACKBAR,
      aditionalTags: {
        reason,
        snackbar_type: 'error',
      },
      aditionalInfo,
      shouldLogMessage,
    });
    dispatch({
      type: SHOW_SNACKBAR,
      params: {
        message: message || getDefaultErrorMessage(siteId),
        type: 'error',
        delay: 3000,
        called_from: 'pdp',
      },
    });
  }
};

module.exports = {
  newActionLoading,
  newPayloadFetchOnePayForAll,
  updateOnePayForAllTrack,
  redirectOrShowSnackbar,
  ACTIONS_SEQUENCER,
  ACTIONS_SEQUENCER_TRACK,
};
