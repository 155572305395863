const compose = require('../../../../lib/compose');
const connect = require('../../../../store/connect');
const withFeatureFlag = require('../../../../components/feature-flag/with-feature-flag');
const mapStateToProps = require('./state-mapper-comparator');

const FEATURE_NAME = 'recommendations_comparator';

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(mapStateToProps(FEATURE_NAME)),
);

module.exports = Component => enhance(Component);
