const React = require('react');

const Grid = require('../../../../../../components/grid');

const SellerData = require('../../../../features/seller-data');

const SellerDataChunk = () => (
  <Grid.Row className="ui-pdp-with--separator ui-pdp-with--separator--medium-top">
    <SellerData className="ui-box-component ui-box-component-pdp__visible--mobile" />
  </Grid.Row>
);

module.exports = SellerDataChunk;
