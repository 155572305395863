const React = require('react');
const { arrayOf, string, number, shape, func } = require('prop-types');
const classnames = require('classnames');
const componentEnhance = require('../../../lib/component-enhance');

const namespace = 'ui-pdp-buybox__quantity';

const QuantityList = ({ label, length, min, max, more, selected, onClick, onShowInput, yieldValue }) => {
  const hasOptions = !!(label.options && label.options.length > 0);
  const minimumQuantity = !min ? 1 : min;
  const final_length = !hasOptions ? length + minimumQuantity - 1 : length;

  const bulkSale = yieldValue != null && yieldValue > 0;
  const items = [];
  const onQuantityClick = (idx, m) => idx <= m && onClick(idx);

  // @TODO replace with interpolation helper
  let labelElement = index => label[index === 1 ? 'singular' : 'plural'].replace('{quantity}', index);

  if (hasOptions) {
    const { options } = label;
    labelElement = index => componentEnhance.jsx(options[index - 1].text, options[index - 1].values);
  }

  const quantityIndex = hasOptions ? 1 : minimumQuantity;
  const offset = minimumQuantity - 1;
  for (let index = quantityIndex; index <= final_length; index += 1) {
    let offsetIndex = index;
    if (hasOptions) {
      offsetIndex = bulkSale ? index * yieldValue : offset + index;
    }

    items.push(
      <li
        key={index}
        className={classnames(`${namespace}__item`, {
          [`${namespace}__item--selected`]: offsetIndex === selected,
          [`${namespace}__item--disabled`]: index > max,
        })}
      >
        <button
          type="button"
          data-testid="quantity-button"
          id={`${namespace}__item-button-${index}`}
          onClick={() => onQuantityClick(offsetIndex, max)}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              onQuantityClick(index, max);
            }
          }}
        >
          {labelElement(index)}
        </button>
      </li>,
    );
  }

  if (more && (bulkSale ? max > length * yieldValue : max > length)) {
    items.push(
      <li key="more" className={`${namespace}__item`}>
        <button
          type="button"
          data-testid="more-button"
          onClick={e => {
            e.preventDefault();
            onShowInput(true);
          }}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              onShowInput(true);
            }
          }}
        >
          {more}
        </button>
      </li>,
    );
  }
  return (
    <>
      <ul className={classnames(`${namespace}__list`, { [`${namespace}__list--options`]: hasOptions })}>{items}</ul>
    </>
  );
};

QuantityList.propTypes = {
  label: shape({
    plural: string,
    singular: string,
    options: arrayOf(
      shape({
        color: string,
        text: string,
        values: shape({}),
      }),
    ),
  }).isRequired,
  length: number.isRequired,
  yieldValue: number,
  min: number.isRequired,
  max: number.isRequired,
  more: string,
  selected: number.isRequired,
  onClick: func.isRequired,
  onShowInput: func.isRequired,
};

QuantityList.defaultProps = {
  more: null,
  yieldValue: null,
};

module.exports = React.memo(QuantityList);
