const compose = require('../../../../lib/compose');
const connect = require('../../../../store/connect');
const withFeatureFlag = require('../../../../components/feature-flag/with-feature-flag');
const Promotion = require('../../../../components/promotions');

const FEATURE_NAME = 'promotions_bottom';

const mapStateToProps = (
  {
    components: {
      [FEATURE_NAME]: { labels, progress, remaining, type },
    },
  },
  ownProps,
) => ({
  labels,
  progress,
  remaining,
  type,
  ...ownProps,
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(mapStateToProps),
);

module.exports = enhance(Promotion);
