const React = require('react');
const { arrayOf, string } = require('prop-types');
const classNames = require('classnames');

const IntegrationsBuilder = ({ integrations, component_id: componentId, className }) => {
  const namespace = 'mshops-3rd-party-integrations';
  const sectionId = classNames({
    [`${namespace}__sidebar`]: componentId === 'third_party_first_section',
    [`${namespace}__main`]: componentId === 'third_party_second_section',
  });

  return (
    <>
      {integrations?.length > 0 && (
        <div className={className}>
          <div id={sectionId}>
            {integrations.map(integration => (
              <div key={integration} id={`${integration}-container`} />
            ))}
          </div>
        </div>
      )}
    </>
  );
};

IntegrationsBuilder.propTypes = {
  integrations: arrayOf(string),
  component_id: string,
  className: string,
};

IntegrationsBuilder.defaultProps = {
  integrations: [],
  className: '',
};

module.exports = IntegrationsBuilder;
