const { loadable } = require('nordic/lazy');
const compose = require('../../../../lib/compose');
const connect = require('../../../../store/connect');
const withFeatureFlag = require('../../../../components/feature-flag/with-feature-flag');

const OtherSellers = loadable(() => import('../../../../components/other-sellers'));

const FEATURE_NAME = 'used_refurbished';

const mapStateToProps = ({ components: { [FEATURE_NAME]: other_sellers }, parentUrl }, ownProps) => ({
  ...ownProps,
  ...other_sellers,
  parentUrl,
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(mapStateToProps),
);

module.exports = enhance(OtherSellers);
