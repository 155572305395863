const compose = require('../../../../lib/compose');
const connect = require('../../../../store/connect');
const withFeatureFlag = require('../../../../components/feature-flag/with-feature-flag');
const Benefits = require('../../../../components/benefits');
const transformActionTarget = require('../../../../utils/transformActionTarget');

const FEATURE_NAME = 'buy_benefits';

const mapStateToProps = ({ components: { [FEATURE_NAME]: benefits } }, ownProps) => {
  benefits.benefits.forEach(benefit => {
    if (benefit.values && benefit.values.action && benefit.values.action.type === 'modal') {
      transformActionTarget(benefit.values.action, { controlled: 'true' });
    }
  });

  return { ...benefits, ...ownProps };
};

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(mapStateToProps),
);

module.exports = enhance(Benefits);
