const compose = require('../../../../lib/compose');
const connect = require('../../../../store/connect');
const withFeatureFlag = require('../../../../components/feature-flag/with-feature-flag');
const SellerInfoMapJsonToProps = require('../../../../components/seller-info-label/json-to-props');

const FEATURE_NAME = 'seller_experiment';

const mapStateToProps = ({ components: { [FEATURE_NAME]: seller }, isFetching }, ownProps) =>
  SellerInfoMapJsonToProps({ ...seller, isFetching, ...ownProps });

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(mapStateToProps),
);

module.exports = Component => enhance(Component);
