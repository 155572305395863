const compose = require('../../../../lib/compose');
const connect = require('../../../../store/connect');
const withFeatureFlag = require('../../../../components/feature-flag/with-feature-flag');
const withRow = require('../../../../components/grid/row/with-row');
const jsonToProps = require('../../../../components/buybox/actions/jsonToProps');
const actions = require('../../../../actions/vip');

const FEATURE_NAME = 'main_actions';
const AVIALABLE_QUANTITY = 'available_quantity';

const mapStateToProps = ({
  components: { [FEATURE_NAME]: mainActions },
  components: { [AVIALABLE_QUANTITY]: available_quantity },
  shops_validator: { shop: { id: shopId } = {} } = {},
}) => jsonToProps({ ...mainActions, available_quantity, shopId });

const mapDispatchToProps = dispatch => ({
  showQuantityError: () => dispatch(actions.showQuantityError()),
  showVariationsError: (deviceType, actionKey) => dispatch(actions.showVariationsError(deviceType, actionKey)),
  showAddToCartModal: ({ itemId, deviceType, labelText }) =>
    dispatch(actions.showAddToCartModal({ itemId, deviceType, labelText })),
  showQuestionsAiModal: ({ target, deviceType }) => dispatch(actions.showQuestionsAiModal({ target, deviceType })),
  fetchOnePayForAll: actionKey => dispatch(actions.fetchOnePayForAll(actionKey)),
  triggerModalOnePayForAll: (buttonKey, deviceType) =>
    dispatch(actions.triggerModalOnePayForAll(buttonKey, deviceType)),
  saveFrontendStatsd: data => dispatch(actions.saveFrontendStatsd(data)),
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  withRow(FEATURE_NAME),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

module.exports = MainActions => enhance(MainActions);
exports = module.exports;
exports.mapStateToProps = mapStateToProps;
exports.mapDispatchToProps = mapDispatchToProps;
