const compose = require('../../../../lib/compose');
const connect = require('../../../../store/connect');
const withFeatureFlag = require('../../../../components/feature-flag/with-feature-flag');

const FEATURE_NAME = 'card_with_thumbnails';

const CARD_WITH_THUMBNAILS_PROPS = {
  id: FEATURE_NAME,
};
const mapStateToProps = ({ components: { [FEATURE_NAME]: props } }, ownProps) => ({ ...props, ...ownProps });

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(mapStateToProps),
);

const serializeOwnProps = (state, ownProps) => ({
  ...ownProps,
  ...CARD_WITH_THUMBNAILS_PROPS,
});

const noFeatureFlagConnect = connect(serializeOwnProps);

module.exports = CardWithThumbnails => enhance(CardWithThumbnails);
module.exports.noFeatureFlag = CardWithThumbnails => noFeatureFlagConnect(CardWithThumbnails);
