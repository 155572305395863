const {
  isOfficialStoreHeaderVisible,
  isReviewsRowVisible,
  isPaymentMethodsVisible,
  isSellerInfoVisible,
  isSellerDataVisible,
} = require('@vpp-frontend-components/common');

module.exports = {
  isOfficialStoreHeaderVisible,
  isReviewsRowVisible,
  isPaymentMethodsVisible,
  isSellerInfoVisible,
  isSellerDataVisible,
};
