const React = require('react');
const { useState, useEffect } = require('react');
const { func, number, shape, string, arrayOf, bool } = require('prop-types');
const { TextField } = require('@andes/textfield');
const { Button } = require('@andes/button');
const StyledLabel = require('../../styled-label');
const { generateKeyToComponent } = require('../../../utils/validators');
const classnames = require('classnames');

const namespace = 'ui-pdp-buybox__quantity';

const QuantityInput = ({
  confirm,
  length,
  min,
  listPickerRef,
  max,
  minErrorMessage,
  noStockMessage,
  setQuantity,
  bulkSale,
  quantity,
  subtitles,
  placeholder,
  title,
  mobile,
}) => {
  const [error, setError] = useState(null);
  const [value, setValue] = useState(() => {
    if (bulkSale) {
      return String(quantity);
    }
    if (quantity > length) {
      return String(quantity);
    }
    return '';
  });
  const onSubmit = e => {
    if (e) {
      e.persist();
    }

    const numericValue = Number(value.replace(',', '.'));
    if (max && numericValue > max) {
      setError({
        modifier: 'error',
        message: bulkSale ? noStockMessage.text.replace('&nbsp;', '\u00A0') : noStockMessage.text,
      });
    } else if (min && numericValue < min) {
      setError({
        modifier: 'error',
        message: bulkSale ? minErrorMessage.text.replace('&nbsp;', '\u00A0') : minErrorMessage.text,
      });
    } else {
      setQuantity(numericValue);
    }
  };

  useEffect(() => {
    if (error?.modifier === 'error' && bulkSale && !mobile && listPickerRef?.current) {
      listPickerRef.current.scrollTop = listPickerRef.current.scrollHeight;
    }
  }, [error, listPickerRef, mobile, bulkSale]);

  const onInputChange = e => {
    setError(null);

    if (bulkSale) {
      // eslint-disable-next-line security/detect-unsafe-regex
      const regex = /^\d+(?:[,.]\d{0,2})?$/;
      if (regex.test(e.target.value) || e.target.value === '') {
        setValue(e.target.value);
      }
    } else {
      setValue(e.target.value.replace(/[^0-9]/g, '').replace(/\b0+/g, ''));
    }
  };

  const ButtonComponent = props => (
    <Button
      data-testid="quantity-input-button"
      className={classnames({ [`${namespace}__button`]: !bulkSale, [`${namespace}__button-bulk`]: bulkSale })}
      onClick={onSubmit}
      disabled={error !== null || value === ''}
      {...props}
    >
      {confirm}
    </Button>
  );

  return (
    <div className={`${namespace}__input`}>
      {subtitles &&
        subtitles.map(subtitle => (
          <StyledLabel
            key={generateKeyToComponent(subtitle)}
            className={classnames(`${namespace}__stock`, {
              [`${namespace}__stock__yield`]: bulkSale,
            })}
            as="p"
            {...subtitle}
          />
        ))}
      <TextField
        inputProps={{ inputMode: bulkSale ? 'decimal' : 'text', autocomplete: 'off' }}
        className={`${namespace}__input-textfield`}
        data-testid="quantity-input"
        autoFocus
        value={value}
        placeholder={placeholder}
        onChange={onInputChange}
        onKeyDown={e => {
          if (e.key === 'Enter' && value !== '') {
            onSubmit();
          }
        }}
        label={title}
        {...error}
      >
        {!mobile && <ButtonComponent size="small" />}
      </TextField>
      {mobile && <ButtonComponent />}
      {}
    </div>
  );
};

QuantityInput.propTypes = {
  confirm: string.isRequired,
  length: number.isRequired,
  listPickerRef: shape({ current: shape({}) }),
  min: number,
  max: number,
  bulkSale: bool,
  minErrorMessage: shape({
    text: string.isRequired,
    color: string.isRequired,
  }).isRequired,
  noStockMessage: shape({
    text: string.isRequired,
    color: string.isRequired,
  }).isRequired,
  quantity: number.isRequired,
  setQuantity: func.isRequired,
  subtitles: arrayOf(shape({ text: string.isRequired })),
  placeholder: string,
  title: string,
  mobile: bool,
};

QuantityInput.defaultProps = {
  max: null,
  title: null,
  listPickerRef: null,
  bulkSale: false,
  placeholder: null,
  subtitles: null,
  mobile: false,
};

module.exports = QuantityInput;
