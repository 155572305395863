const { useEffect, useState } = require('react');

const useLightbox = (pictures, onChange) => {
  const [index, setIndex] = useState(false);
  useEffect(() => {
    setIndex(false);
    if (typeof onChange === 'function') {
      onChange(index);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pictures]);

  return [index, setIndex];
};

module.exports = useLightbox;
