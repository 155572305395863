const React = require('react');
const { bool, string, shape } = require('prop-types');
const GTM = require('nordic/gtm');

const GoogleTagManager = ({ containerId, enabled, dataLayer }) => (
  <GTM id={containerId} dataLayer={[dataLayer]} disabled={!enabled || !containerId} />
);

GoogleTagManager.propTypes = {
  containerId: string,
  enabled: bool,
  dataLayer: shape({}),
};

GoogleTagManager.defaultProps = {
  containerId: null,
  enabled: true,
  dataLayer: null,
};

module.exports = GoogleTagManager;
