const React = require('react');
const Breadcrumb = require('../../../../features/breadcrumb');

const BreadcrumbAndRelatedReviewChunk = () => (
  <>
    <Breadcrumb mode="compact" />
  </>
);

module.exports = BreadcrumbAndRelatedReviewChunk;
