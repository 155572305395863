const compose = require('../../../../../lib/compose');
const connect = require('../../../../../store/connect');
const withFeatureFlag = require('../../../../../components/feature-flag/with-feature-flag');
const BannerMobile = require('../../../../../components/buybox/banner/banner.mobile');
const actions = require('../../../../../actions');
const { reloadPage } = require('./utils');

const FEATURE_NAME = 'generic_banner_installments';

const GENERIC_BANNER_PROPS = {
  id: FEATURE_NAME,
};

const mapStateToProps = ({ isFetching, components: { [FEATURE_NAME]: genericBannerInstallments } }, ownProps) => ({
  isFetching,
  ...genericBannerInstallments,
  ...ownProps,
  ...GENERIC_BANNER_PROPS,
});

const mapDispatchToProps = dispatch => ({
  onCloseModal: () => {
    dispatch(actions.fetchProductOnMeliplusUpdate('close_modal'));
  },
  meliplusUpsellingOnSuscription: () => {
    reloadPage();
  },
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

module.exports = enhance(BannerMobile);
exports = module.exports;
exports.mapDispatchToProps = mapDispatchToProps;
