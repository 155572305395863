const { trackEvent } = require('../../../lib/tracking');

const setTrackEventInfo = (quantity, track) => {
  const { melidata_event, analytics_event } = track;
  if (melidata_event) {
    melidata_event.event_data.quantity = quantity;
  }
  trackEvent({ melidata_event, analytics_event });
};

const refreshCartIcon = () => {
  if (window && window.freya) {
    window.freya.emit('cart:refresh');
  }
};

const findBySameQuantity = quantity => option => option.quantity === quantity;

const getLabelPosition = (options, quantity) => options.findIndex(findBySameQuantity(quantity));

const updateCartCallback = (action, quantity, track) => {
  refreshCartIcon();
  setTrackEventInfo(action, quantity, track);
};

module.exports = {
  getLabelPosition,
  updateCartCallback,
};
