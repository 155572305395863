const React = require('react');
const { string, shape } = require('prop-types');
const classnames = require('classnames');
const colornames = require('../../../lib/colornames');
const Payment = require('../../payment');
const componentEnhance = require('../../../lib/component-enhance');

const namespace = 'ui-pdp-payment--md';

const PaymentSummary = ({ action, className, title: { color, text, values }, ...rest }) => (
  <Payment
    action={action}
    className={classnames(namespace, className, colornames(color))}
    title={componentEnhance.jsx(text, values)}
    {...rest}
  />
);

PaymentSummary.propTypes = {
  action: shape({
    icon: string,
    label: shape({
      text: string.isRequired,
      color: string,
    }),
    target: string.isRequired,
  }),
  className: string,
  icon: shape({
    id: string.isRequired,
    color: string,
  }),
  text: string,
  title: shape({
    text: string.isRequired,
    color: string,
    values: shape(),
  }).isRequired,
};

PaymentSummary.defaultProps = {
  action: null,
  icon: null,
  className: null,
  text: null,
};

module.exports = PaymentSummary;
