const { loadable } = require('nordic/lazy');
const compose = require('../../../../../lib/compose');
const connect = require('../../../../../store/connect');
const withFeatureFlag = require('../../../../../components/feature-flag/with-feature-flag');
const actions = require('../../../../../actions');

const CouponSumary = loadable(() => import('../../../../../components/buybox/coupon-summary/coupon-summary.mobile'));

const FEATURE_NAME = 'coupon_summary';

const COUPON_PROPS = {
  id: FEATURE_NAME,
};

const mapStateToProps = ({ components: { [FEATURE_NAME]: couponSummary } }, ownProps) => ({
  ...couponSummary,
  ...ownProps,
  ...COUPON_PROPS,
});

const serializeOwnProps = (state, ownProps) => ({
  ...ownProps,
  ...COUPON_PROPS,
});

const mapDispatchToProps = dispatch => ({
  onCouponUpdate: () => dispatch(actions.mobileUpdateCoupon()),
  showSnackbar: snackbar => dispatch(actions.showSnackbar(snackbar)),
  onCouponActivate: params => dispatch(actions.mobileActivateCoupon(params)),
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

const noFeatureFlagConnect = connect(
  serializeOwnProps,
  mapDispatchToProps,
);

module.exports = enhance(CouponSumary);
exports = module.exports;
exports.mapDispatchToProps = mapDispatchToProps;
module.exports.noFeatureFlag = noFeatureFlagConnect(CouponSumary);
