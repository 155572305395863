const compose = require('../../../../lib/compose');
const connect = require('../../../../store/connect');
const withFeatureFlag = require('../../../../components/feature-flag/with-feature-flag');
const BuyBox = require('../../../../components/buybox');

const FEATURE_NAME = 'buy_box';

const mapStateToProps = ({ parentUrl }, ownProps) => ({
  parentUrl,
  ...ownProps,
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(mapStateToProps),
);

module.exports = enhance(BuyBox);
