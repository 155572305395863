// DO NOT COMMIT - THIS FILE WAS CHANGED FOR ESBUILD, BEFORE COMMITTING RUN 'npm run esbuild-discard-changes' TO RESTORE THE FILE TO THE ORIGINAL VERSION

const React = require('react');

const { useMemo, useState, useRef, useCallback } = React;
const { LazyHydrate } = require('nordic/lazy');
const classnames = require('classnames');
const { Script } = require('nordic/script');

const { arrayOf, bool, func, number, shape, string } = require('prop-types');
const { DEVICE_TYPE } = require('../../utils/constants');
const { DEFAULT_LIGHTBOX_SETTINGS, GALLERY_TABS } = require('./constants');
const { getShapes } = require('../dots-navigation');
const { trackEvent } = require('../../lib/tracking');
const Bookmark = require('../bookmark');
const Image = require('../image');
const Share = require('../share/share.mobile');
const { loadable } = require('nordic/lazy');
const useGallery = require('./hooks/use-gallery');
const GalleryCarousel = require('./components/mobile/gallery.carousel');
const { PROVIDER, MULTIMEDIA_PROVIDERS } = require('../figure/constants');

const namespace = 'ui-pdp-gallery';

const ImageLoader = () => <Script src="@frontend-performance/image-lazy-loading/src.js" on="now" inline />;
const Lightbox = loadable(() => import('../lightbox'));
const VirtualTourModal = loadable(() => import('../virtual-tour-modal'));
const ClipsWorkerComponent = loadable(() => import('./components/mobile/clips-worker-component-mobile'), {
  resolveComponent: components => components.ClipsWorkerComponent,
});

Lightbox.preload();

/* Pictures should have msrc to avoid photoswipe blink zoom */
const GalleryMobile = props => {
  const {
    accessibility_text,
    bookmark,
    clipsConfig,
    galleryType,
    highlightedMultimedia,
    id,
    app,
    logo,
    figures,
    onClickBookmark,
    openWishlistModalBS,
    updateGiftRegistry,
    closeWishlistModalBS,
    toggleGiftRegistryCheckbox,
    fetchBookmark,
    share,
    showSnackbar,
    track,
    minHeight,
    maxHeight,
    overlayAlpha,
  } = props;

  const {
    position,
    snap,
    isCurrentClips,
    isFullscreen,
    isPreviousClips,
    setCurrentPosition,
    setIsFullscreen,
  } = useGallery({ items: figures, clipsConfig });

  const deviceType = DEVICE_TYPE.MOBILE;
  const [activeModalTab, setActiveModalTab] = useState();
  const [modalVisibility, setModalVisibility] = useState(false);
  const shapes = useMemo(() => getShapes(figures), [figures]);

  const clipsRef = useRef(null);

  const clipsWorkerComponentProps = {
    clipsConfig,
    isCurrentClips,
    isPreviousClips,
    isFullscreen,
    showSnackbar,
    namespace,
    deviceType,
    snap,
    position,
    figures,
    ref: clipsRef,
  };

  const clipHandlerAsync = (...params) =>
    clipsRef.current ? clipsRef.current?.clipHandlerAsync(...params) : Promise.resolve();

  const setHeaderUnclickeable = unclickeable => {
    const header = document.querySelector('.nav-header');
    if (header) {
      if (unclickeable) {
        header.style.pointerEvents = 'none';
      } else {
        header.style.pointerEvents = 'auto';
      }
    }
  };

  const handleTap = (type = 'picture', event = null) => {
    setActiveModalTab(type);
    setModalVisibility(true);
    if (event) {
      trackEvent(event);
    }
  };

  const handlerBeforeOpen = () => {
    setIsFullscreen(true);
  };

  const handlerLightboxOnClose = () => {
    setTimeout(() => setHeaderUnclickeable(false), 200);
    setModalVisibility(false);
    setIsFullscreen(false);
  };

  const handleLightboxOnTouch = (...params) => clipsRef.current && clipsRef.current?.handleTouch(...params);

  const filterImagesAndClips = useCallback(({ provider }) => [PROVIDER.CLIP, PROVIDER.IMAGE].includes(provider), []);

  const onTapFigure = async (figure, e) => {
    const { provider, button } = figure;
    e.preventDefault();

    if (clipsConfig?.hasClips) {
      await clipHandlerAsync(provider);
    }
    if (MULTIMEDIA_PROVIDERS.includes(provider)) {
      handleTap(GALLERY_TABS.MULTIMEDIA, button?.track);
    } else {
      setHeaderUnclickeable(true);
      handleTap(GALLERY_TABS.PICTURE, track);
    }
  };

  return (
    <div className={classnames(namespace, { [`${namespace}-with-logo`]: logo })}>
      {figures.length === 1 && <ImageLoader />}
      {logo && (
        <div className={`${namespace}__logo-container`}>
          <Image
            src={logo.url.src}
            alt={logo.alt}
            lazyload={false}
            width={64}
            height={64}
            className={`${namespace}__logo`}
          />
        </div>
      )}
      <GalleryCarousel
        id={id}
        figures={figures}
        clipsConfig={clipsConfig}
        galleryType={galleryType}
        minHeight={minHeight}
        maxHeight={maxHeight}
        shapes={shapes}
        accessibility_text={accessibility_text}
        counter={position.current}
        setCounter={setCurrentPosition}
        onTapFigure={onTapFigure}
        overlayAlpha={overlayAlpha}
        app={app}
      />
      {clipsConfig?.hasClips && <ClipsWorkerComponent {...clipsWorkerComponentProps} />}

      {highlightedMultimedia && !!activeModalTab && (
        <LazyHydrate whenIdle>
          <VirtualTourModal
            visible={modalVisibility}
            activeTab={activeModalTab}
            highlightedMultimedia={highlightedMultimedia}
            figures={figures.filter(filterImagesAndClips)}
            figureIndex={position.current}
            onChange={newTab => setActiveModalTab(newTab)}
            setIndex={setCurrentPosition}
            onClose={handlerLightboxOnClose}
            deviceType={DEVICE_TYPE.MOBILE}
            hasClips={clipsConfig?.hasClips}
            onBeforeOpen={handlerBeforeOpen}
            onTouch={handleLightboxOnTouch}
          />
        </LazyHydrate>
      )}
      {!highlightedMultimedia && modalVisibility && (
        <Lightbox
          index={position.current}
          setIndex={setCurrentPosition}
          pictures={figures.filter(filterImagesAndClips).map(item => {
            if (item.provider === PROVIDER.CLIP) {
              return item;
            }

            return {
              ...item,
              src: (item.zoom && item.zoom.src) || item.src,
              msrc: item.src,
              thumbnail: item.src,
            };
          })}
          deviceType={DEVICE_TYPE.MOBILE}
          hasClips={clipsConfig?.hasClips}
          onBeforeOpen={handlerBeforeOpen}
          onClose={handlerLightboxOnClose}
          onTouch={handleLightboxOnTouch}
          settings={DEFAULT_LIGHTBOX_SETTINGS.mobile}
          shapes={shapes}
        />
      )}
      {bookmark && (
        <Bookmark
          {...bookmark}
          onClick={onClickBookmark}
          openWishlistModalBS={openWishlistModalBS}
          closeWishlistModalBS={closeWishlistModalBS}
          updateGiftRegistry={updateGiftRegistry}
          showSnackbar={showSnackbar}
          toggleGiftRegistryCheckbox={toggleGiftRegistryCheckbox}
          fetchBookmark={fetchBookmark}
        />
      )}
      {share && <Share modifier="gallery" iconVariation="gallery" showLabel={false} {...share} />}
    </div>
  );
};

GalleryMobile.propTypes = {
  track: shape({}),
  id: string.isRequired,
  galleryType: string,
  accessibility_text: string,
  figures: arrayOf(
    shape({
      id: string.isRequired,
      alt: string.isRequired,
      src: string.isRequired,
      src2x: string,
    }),
  ).isRequired,
  share: shape({
    shareActions: arrayOf(
      shape({
        target: string,
        label: shape({
          text: string,
        }),
        icon: shape({
          id: string,
        }),
      }),
    ),
    permalink: string,
    title: shape({
      text: string,
      color: string,
    }),
    action: shape({
      label: shape({
        text: string,
      }),
      icon: shape({
        id: string,
      }),
    }),
  }),
  highlightedMultimedia: shape({
    id: string.isRequired,
    provider: string.isRequired,
    multimedia_tab: shape({
      icon: shape.isRequired,
      label: shape.isRequired,
    }).isRequired,
    picture_tab: shape({
      icon: shape.isRequired,
      label: shape.isRequired,
    }).isRequired,
  }),
  logo: shape({
    url: shape({}),
    alt: string,
  }),
  clipsConfig: shape({
    autoplay: bool.isRequired,
    hasClips: bool.isRequired,
    size: number.isRequired,
    snackbars: arrayOf(
      shape({
        id: string,
        message: string,
        delay: number,
      }),
    ),
  }),
  onClickBookmark: func,
  openWishlistModalBS: func,
  updateGiftRegistry: func,
  toggleGiftRegistryCheckbox: func,
  closeWishlistModalBS: func,
  fetchBookmark: func,
  bookmark: shape({
    isBookmarked: bool,
    href: string,
    remove: shape({}),
    add: shape({}),
    isFetching: bool,
    themeMode: string,
  }),
  showSnackbar: func.isRequired,
  minHeight: number,
  maxHeight: number,
  app: string,
  overlayAlpha: string,
};

GalleryMobile.defaultProps = {
  accessibility_text: 'carousel',
  bookmark: null,
  clipsConfig: null,
  galleryType: 'default',
  hasClips: false,
  highlightedMultimedia: null,
  logo: null,
  onClickBookmark: () => {},
  openWishlistModalBS: () => {},
  closeWishlistModalBS: () => {},
  fetchBookmark: () => {},
  share: null,
  sizeForBiggerPhoto: null,
  track: null,
  overlayAlpha: null,
};

module.exports = GalleryMobile;
