const React = require('react');
const classnames = require('classnames');
const { bool, string, shape } = require('prop-types');
const { CardTooltipSkeleton } = require('../../placeholder');
const Banner = require('./banner.desktop');

const namespace = 'ui-pdp-banner-mobile';

const BannerMobile = props => {
  const deviceType = 'mobile';
  const { isFetching, picture, className } = props;
  if (isFetching) {
    return (
      <CardTooltipSkeleton
        hasIcon
        icon
        hasProgressBar
        height={picture.height}
        className={`${namespace}-skeleton mt-12`}
      />
    );
  }

  return <Banner {...props} deviceType={deviceType} className={classnames(namespace, className)} />;
};

BannerMobile.propTypes = {
  id: null,
  className: string,
  picture: shape({}),
  accessibility_text: null,
  isFetching: bool,
};

BannerMobile.defaultProps = {
  action: null,
  className: null,
  information_tooltip: null,
  picture: null,
  id: null,
  isFetching: false,
  remaining: null,
  subtitles: null,
  text: null,
  custom_message: null,
  tooltip: null,
};
module.exports = BannerMobile;
