const compose = require('../../../../../lib/compose');
const connect = require('../../../../../store/connect');
const withFeatureFlag = require('../../../../../components/feature-flag/with-feature-flag');
const withRow = require('../../../../../components/grid/row/with-row');
const jsonToProps = require('../generic-summary/pickup-mapper');

const FEATURE_NAME = 'returns_summary';

const RETURNS_PROPS = {
  id: FEATURE_NAME,
};

const mapStateToProps = ({ components: { [FEATURE_NAME]: returnsSummary } }, ownProps) =>
  jsonToProps({ ...returnsSummary, ...ownProps, ...RETURNS_PROPS });

const serializeOwnProps = (state, ownProps) => jsonToProps({ ...ownProps, ...RETURNS_PROPS });

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  withRow(FEATURE_NAME),
  connect(mapStateToProps),
);

const noFeatureFlagConnect = connect(serializeOwnProps);

module.exports = PickUpSummary => enhance(PickUpSummary);
module.exports.noFeatureFlag = PickUpSummary => noFeatureFlagConnect(PickUpSummary);
