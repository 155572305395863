/* eslint-disable jsx-a11y/click-events-have-key-events */
const React = require('react');
const { bool, func, shape, string } = require('prop-types');
const Card = require('@andes/card');
const classNames = require('classnames');
const StyledLabel = require('../styled-label');

const namespace = 'ui-pdp-legal-tooltip';

const TooltipContent = ({ tooltip }) => (
  <div className={`${namespace}__content`}>
    <StyledLabel className={`${namespace}__tooltip__title`} as="span" {...tooltip.title} />
    {tooltip.subtitles.map(subtitle => (
      <StyledLabel
        className={classNames(`${namespace}__subtitle`, `${namespace}__tooltip__subtitle`)}
        key={subtitle.text}
        as="p"
        {...subtitle}
      />
    ))}
  </div>
);

TooltipContent.propTypes = {
  tooltip: shape({
    arrow_type: string,
    background_color: string,
    change_color: bool,
    close_color: string,
    closeable: bool,
    closed: bool,
    has_shadow: bool,
    state: string,
    storable: bool,
    subtitles: shape([
      {
        color: string,
        font_family: string,
        font_size: string,
        text: string,
      },
    ]),
    title: shape({
      color: string,
      font_family: string,
      font_size: string,
      text: string,
    }),
    type: string,
  }),
};

const TooltipCard = ({ tooltip, setShowMOQTooltip }) => {
  const handleCloseTooltip = () => {
    setShowMOQTooltip(false);
  };

  return (
    <Card className={classNames(namespace, 'ui-pdp-card-tooltip__card ui-pdp-card-tooltip__card--arrow-top-left')}>
      <span
        data-testid="action-close-button"
        role="button"
        tabIndex="0"
        className="andes-tooltip-button-close"
        onClick={handleCloseTooltip}
      />
      <TooltipContent tooltip={tooltip} />
    </Card>
  );
};

TooltipCard.propTypes = {
  tooltip: shape({
    arrow_type: string,
    background_color: string,
    change_color: bool,
    close_color: string,
    closeable: bool,
    closed: bool,
    has_shadow: bool,
    state: string,
    storable: bool,
    subtitles: shape([
      {
        color: string,
        font_family: string,
        font_size: string,
        text: string,
      },
    ]),
    title: shape({
      color: string,
      font_family: string,
      font_size: string,
      text: string,
    }),
    type: string,
  }),
  setShowMOQTooltip: func.isRequired,
};

const TooltipLegal = ({ collapsible_tooltip, showMOQTooltip, setShowMOQTooltip }) => (
  <>
    {collapsible_tooltip && showMOQTooltip && (
      <TooltipCard
        tooltip={collapsible_tooltip.tooltip}
        showMOQTooltip={showMOQTooltip}
        setShowMOQTooltip={setShowMOQTooltip}
      />
    )}
  </>
);

TooltipLegal.propTypes = {
  collapsible_tooltip: shape({
    icon: shape({
      id: string,
      color: string,
      size: string,
    }),
    opened: bool,
    tooltip: shape({
      arrow_type: string,
      background_color: string,
      change_color: bool,
      close_color: string,
      closeable: bool,
      closed: bool,
      has_shadow: bool,
      state: string,
      storable: bool,
      subtitles: shape([
        {
          color: string,
          font_family: string,
          font_size: string,
          text: string,
        },
      ]),
      title: shape({
        color: string,
        font_family: string,
        font_size: string,
        text: string,
      }),
      type: string,
    }),
  }),
  showMOQTooltip: func.isRequired,
  setShowMOQTooltip: func.isRequired,
};

TooltipLegal.defaultProps = {
  text: '',
  color: '',
  tooltip: {
    id: '',
    subtitles: [],
  },
  values: {},
  side: 'bottom',
  trigger: 'click',
};

module.exports = TooltipLegal;
