const React = require('react');

const Grid = require('../../../../../../components/grid');

const SellerInfo = require('../../../../features/seller/seller-info.mobile');

const SellerInfoChunk = () => (
  <Grid.Row className="ui-pdp-with--separator ui-pdp-with--separator--medium-top">
    <SellerInfo />
  </Grid.Row>
);

module.exports = SellerInfoChunk;
