const ACTION_TYPES = {
  BUY_NOW_WITH_CREDITS: 'BUY_NOW_WITH_CREDITS',
  CALL: 'CALL',
  CALL_LINK: 'CALL_LINK',
  WHATSAPP: 'WHATSAPP',
  WHATSAPP_INTERVENTION: 'WHATSAPP_INTERVENTION',
  WHATSAPP_LINK: 'WHATSAPP_LINK',
  WHATSAPP_OPS_LINK: 'WHATSAPP_OPS_LINK',
  LINK: 'LINK',
  MODAL_QUESTIONS: 'MODAL_QUESTIONS',
  MODAL_UNREGISTERED_QUESTION: 'MODAL_UNREGISTERED_QUESTION',
  SCROLL_TO: 'SCROLL_TO',
  MSHOPS_BUY_NOW: 'MSHOPS_BUY_NOW',
  MODAL_QUOTE: 'MODAL_QUOTE',
  ADDITIONAL_INFO: 'ADDITIONAL_INFO',
  MODAL_QUOTATION: 'MODAL_QUOTATION',
  MODAL_UNREGISTERED_QUOTATION: 'MODAL_UNREGISTERED_QUOTATION',
  MODAL_CHECK_UNITS: 'MODAL_CHECK_UNITS',
  BUY_PROTECTED: 'BUY_PROTECTED',
  ADD_TO_CART: 'ADD_TO_CART',
  LOYALTY: 'LOYALTY',
  BUY_NOW_SHOPS: 'BUY_NOW_SHOPS',
  BUY_NOW: 'BUY_NOW',
  BUY_NOW_WITH_ONE_PAY_FOR_ALL: 'BUY_NOW_WITH_ONE_PAY_FOR_ALL',
  BOTTOM_SHEET_WITH_PRELOAD: 'BOTTOM_SHEET_WITH_PRELOAD',
  SEQUENCER: 'SEQUENCER',
  ASK_QUESTIONS_AI: 'ASK_QUESTIONS_AI',
  SCHEDULE_VISIT_MOTORS: 'SCHEDULE_VISIT_MOTORS',
  SCHEDULE_VISIT_MOTORS_LINK: 'SCHEDULE_VISIT_MOTORS_LINK',
  VEHICLE_HISTORY_MODAL: 'VEHICLE_HISTORIC',
};

const ACTION_BUYBOX_TYPES = {
  ACTION_TYPE_PRIMARY: 'primary',
  ACTION_TYPE_SECONDARY: 'secondary',
  ACTION_TYPE_TERTIARY: 'tertiary',
  ACTION_TYPE_ACTION_ROW: 'action_row',
  ACTION_HIERARCHY_QUIET: 'quiet',
  ACTION_HIERARCHY_LOUD: 'loud',
  ACTION_HIERARCHY_TRANSPARENT: 'transparent',
  ACTION_HIERARCHY_ROW: 'actionRow',
  ACTION_ADDITIONAL_INFO: 'additional_info',
  ACTION_LOYALTY: 'loyalty',
};

const BUTTON_TYPES = {
  SUBMIT: 'submit',
};

module.exports = { ACTION_TYPES, BUTTON_TYPES, ACTION_BUYBOX_TYPES };
