const withFeatureFlag = require('../../../../components/feature-flag/with-feature-flag');
const compose = require('../../../../lib/compose');
const connect = require('../../../../store/connect');

const FEATURE_NAME = 'highlighted_specs_attrs';

const mapStateToProps = ({ components: { [FEATURE_NAME]: highlightedSpecs }, id }) => ({
  ...highlightedSpecs,
  productId: id,
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(mapStateToProps),
);

module.exports = HighlightedSpecsAttrs => enhance(HighlightedSpecsAttrs);
