const React = require('react');
const { node, shape, func, string } = require('prop-types');
const { Snackbar } = require('@andes/snackbar');
const GlobalErrorBoundary = require('./global-error');
const thunk = require('./middleware/thunk');
const useEnhancedReducer = require('../hooks/use-enhance-reducer');
const { validateSnackbarColor } = require('../utils/validators');

const { Provider, Consumer: StoreConsumer } = React.createContext();

const middlewares = [thunk];

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line n/global-require
  const logger = require('./middleware/logger');
  middlewares.push(logger);
}

const StoreProvider = ({ children, defaultState, reducer, siteId }) => {
  const [state, dispatch] = useEnhancedReducer(reducer, defaultState, middlewares);
  const store = { dispatch, state };
  const { feedback } = state;

  return (
    <GlobalErrorBoundary siteId={siteId} dispatch={dispatch} error={state.error}>
      <Provider value={store}>{children}</Provider>
      {feedback && feedback.style && (
        <Snackbar
          key={feedback.key}
          show
          message={feedback.message}
          delay={feedback.duration}
          color={validateSnackbarColor(feedback.style.toLowerCase())}
        />
      )}
    </GlobalErrorBoundary>
  );
};

StoreProvider.propTypes = {
  children: node.isRequired,
  defaultState: shape(),
  reducer: func.isRequired,
  siteId: string,
};

StoreProvider.defaultProps = {
  defaultState: {},
  siteId: null,
};

module.exports = { StoreProvider, StoreConsumer };
