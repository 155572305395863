const { loadable } = require('nordic/lazy');
const compose = require('../../../../lib/compose');
const connect = require('../../../../store/connect');
const withFeatureFlag = require('../../../../components/feature-flag/with-feature-flag');
const actions = require('../../../../actions');

const VariationsBox = loadable(() => import('../../../../components/variations/variations-box.mobile'));

const FEATURE_NAME = 'outside_variations';
const FEATURE_AVAILABLE_QUANTITY_NAME = 'available_quantity';
const FEATURE_MAIN_ACTIONS = 'main_actions';

const mapStateToProps = (
  {
    id,
    app,
    filters,
    isFetching,
    components: {
      [FEATURE_NAME]: variations,
      [FEATURE_AVAILABLE_QUANTITY_NAME]: availableQuantity,
      [FEATURE_MAIN_ACTIONS]: main_actions,
    },
  },
  ownProps,
) => ({
  ...ownProps,
  ...variations,
  isFetching,
  id,
  app,
  filters,
  selectedQuantity:
    availableQuantity && availableQuantity.state !== 'HIDDEN'
      ? (availableQuantity.picker && availableQuantity.picker.selected) || 1
      : 1,
  buyNowAction: main_actions ? main_actions.form : null,
});

const mapDispatchToProps = dispatch => ({
  onSelectVariationBox: (id, params, updateVariationsBox, attrId) =>
    dispatch(actions.fetchUpdatedComponents(id, params, updateVariationsBox, attrId)),
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

module.exports = enhance(VariationsBox);
