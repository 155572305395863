const compose = require('../../lib/compose');
const connect = require('../../store/connect');
const withFeatureFlag = require('../feature-flag/with-feature-flag');
const vipActions = require('../../actions/vip');

const FEATURE_NAME = 'combos';
const A2C_ACTION = 'ADD_TO_CART';

const mapStateToProps = (
  {
    components: {
      [FEATURE_NAME]: feature,
      main_actions: { actions },
    },
    isWebView,
  },
  ownProps,
) => {
  const a2c = actions && actions.length > 0 ? actions.find(({ action_type }) => action_type === A2C_ACTION) : null;
  return {
    apiResponse: {
      ...feature,
      webview: isWebView,
    },
    should_raise_errors: a2c ? a2c.should_raise_errors : false,
    ...ownProps,
  };
};

const mapDispatchToProps = dispatch => ({
  showVariationsError: (deviceType, actionKey) => dispatch(vipActions.showVariationsError(deviceType, actionKey)),
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

module.exports = Combos => enhance(Combos);
