require('regenerator-runtime/runtime');
/* eslint-env browser */

const React = require('react');
const { hydrate } = require('nordic/hydrate');
const { loadableReady } = require('nordic/lazy');
const I18n = require('nordic/i18n');
const withProviders = require('../components/with-providers');

module.exports = function bootstrap(Component) {
  const preloadedState = window.__PRELOADED_STATE__;

  const i18n = new I18n({
    translations: preloadedState.translations,
  });

  const WithProvidersComponent = withProviders(props => <Component {...props} />, preloadedState.imagePrefix, i18n);

  loadableReady(() => {
    hydrate(<WithProvidersComponent {...preloadedState} />, document.getElementById('root-app'));
  });

  window.newrelic?.setErrorHandler(err => {
    const ignoreStatusMessages = ['Script error.'];
    let response = false;

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < ignoreStatusMessages.length; i++) {
      const element = ignoreStatusMessages[i];
      if (err.message === element) {
        response = true;
      }
    }

    return response;
  });
};
