const compose = require('../../../../lib/compose');
const connect = require('../../../../store/connect');
const withFeatureFlag = require('../../../../components/feature-flag/with-feature-flag');

const FEATURE_NAME = 'apparel_size_specs_preview';

const mapStateToProps = ({ components: { [FEATURE_NAME]: sizechartPreview } }, ownProps) => {
  const { chart_preview_data, size_column_name, is_new_size_chart, ...rest } = sizechartPreview;
  return {
    ...rest,
    chartPreviewData: chart_preview_data,
    sizeColumnName: size_column_name,
    isNewSizeChart: is_new_size_chart,
    ...ownProps,
  };
};

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(mapStateToProps),
);

module.exports = ApparelSizeSpecsPreview => enhance(ApparelSizeSpecsPreview);
