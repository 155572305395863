const compose = require('../../../../lib/compose');
const connect = require('../../../../store/connect');
const withFeatureFlag = require('../../../../components/feature-flag/with-feature-flag');

const FEATURE_NAME = 'apparel_size_specs';

const mapStateToProps = ({ components: { [FEATURE_NAME]: apparelSizeSpec } }) => apparelSizeSpec;

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(mapStateToProps),
);

module.exports = ApparelSizeSpec => enhance(ApparelSizeSpec);
