const OtherProducts = require('../../../../components/other-products/other-products.mobile');
const compose = require('../../../../lib/compose');
const connect = require('../../../../store/connect');
const withFeatureFlag = require('../../../../components/feature-flag/with-feature-flag');

const FEATURE_NAME = 'others_products';
const BBW_ALTERNATIVES_FEATURE_NAME = 'bbw_alternatives';

const mapStateToProps = (
  { components: { [FEATURE_NAME]: otherProducts, [BBW_ALTERNATIVES_FEATURE_NAME]: bbwAlternatives } },
  ownProps,
) => ({
  ...ownProps,
  ...otherProducts,
  isBBWAlternativesVisible: bbwAlternatives && bbwAlternatives.state === 'VISIBLE',
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(mapStateToProps),
);

module.exports = enhance(OtherProducts);
