const compose = require('../../../../../lib/compose');
const connect = require('../../../../../store/connect');
const withFeatureFlag = require('../../../../../components/feature-flag/with-feature-flag');
const Actions = require('../../../../../components/buybox/actions');
const acts = require('../../../../../actions/index');

const FEATURE_NAME = 'main_actions';

const mapStateToProps = ({ components: { [FEATURE_NAME]: actions } }, { onSubmit, parentUrl }) => ({
  ...actions,
  form: {
    ...actions.form,
    parent_url: parentUrl,
  },
  onSubmit,
});

const mapDispatchToProps = dispatch => ({
  showAddToCartModal: ({ deviceType, itemId, labelText }) =>
    dispatch(acts.showAddToCartModal({ deviceType, itemId, labelText })),
  showQuestionsAiModal: ({ target, deviceType }) => dispatch(acts.showQuestionsAiModal({ target, deviceType })),
  showVariationsError: (deviceType, actionKey) => dispatch(acts.showVariationsError(deviceType, actionKey)),
  fetchOnePayForAll: actionKey => dispatch(acts.fetchOnePayForAll(actionKey)),
  triggerModalOnePayForAll: (buttonKey, deviceType) => dispatch(acts.triggerModalOnePayForAll(buttonKey, deviceType)),
  saveFrontendStatsd: data => dispatch(acts.saveFrontendStatsd(data)),
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

module.exports = enhance(Actions);
exports = module.exports;
exports.mapDispatchToProps = mapDispatchToProps;
