const React = require('react');
const { node, shape, string, func, bool } = require('prop-types');
const { useRef, useState } = require('react');
const classnames = require('classnames');
const Button = require('./components/button');
const { trackEvent } = require('@vpp-frontend-components/common');

const namespace = 'ui-pdp-collapsable-mobile';

const ActionCollapsableCssMobile = ({ action, children, className, onSelect, scrollToTop }) => {
  const ref = useRef();
  const [isCollapsed, setCollapsedState] = useState(true);
  const { track } = action;

  const onClick = e => {
    e.preventDefault();
    trackEvent(track);
    if (onSelect) {
      onSelect();
    }
    setCollapsedState(!isCollapsed);
    if (scrollToTop) {
      ref.current.scrollIntoView();
    }
  };

  return (
    <div className={classnames(namespace, className, { [`${namespace}--is-collapsed`]: isCollapsed })}>
      <div
        className={classnames(`${namespace}__container`, { [`${namespace}__container-items-hidden`]: isCollapsed })}
        ref={ref}
      >
        {children}
      </div>
      {isCollapsed && <Button action={action} namespace={namespace} onClick={onClick} />}
    </div>
  );
};

ActionCollapsableCssMobile.propTypes = {
  action: shape({
    label: shape({
      text: string.isRequired,
      color: string,
    }).isRequired,
    target: string,
  }).isRequired,
  children: node,
  onSelect: func,
  className: string,
  scrollToTop: bool,
};

ActionCollapsableCssMobile.defaultProps = {
  children: null,
  className: null,
  onSelect: null,
  scrollToTop: true,
};

module.exports = ActionCollapsableCssMobile;
