const React = require('react');
const Grid = require('../../../../../../components/grid');

const TradeIn = require('../../../../features/trade-in');

const TradeInChunk = () => (
  <>
    <Grid.Row className="pl-16 pr-16">
      <TradeIn />
    </Grid.Row>
  </>
);

module.exports = TradeInChunk;
