const React = require('react');
const { arrayOf, bool, string, shape, func, oneOfType, node, number } = require('prop-types');
const classnames = require('classnames');
const Typography = require('@andes/typography');
const MoneyAmount = require('@andes/money-amount');
const compose = require('../../lib/compose');
const Media = require('../media');
const colornames = require('../../lib/colornames');
const { stringIncludes } = require('../../lib/includes');
const Message = require('../message');
const withTooltip = require('../tooltip/with-tooltip');
const { setTooltipProps } = require('../tooltip/information-tooltip-props');
const { Remaining } = require('../../prop-types');
const adaptSubtitlesToRemainingLogic = require('../../utils/adaptSubtitlesToRemainingLogic');
const ActionModal = require('../action-modal');
const Iframe = require('../iframe');
const transformActionTarget = require('../../utils/transformActionTarget');
const onIframeModalResize = require('../../lib/on-iframe-modal-resize');

const IconFactory = require('../icons/factory-builder')();
const { PriceFontSize } = require('../../lib/num-font-size-enum');
const CardTooltipComponentWrapper = require('../card-tooltip/card-tooltip-wrapper');
const { getCoachCardTooltipsProps } = require('../../utils/coachTooltip');
const BottomSheetWithClose = require('../bottom-sheet-with-close/bottom-sheet-with-close');

const namespace = 'ui-pdp-generic-summary';

const CbtTaxesSummary = ({ map_content }) => {
  if (!map_content) {
    return null;
  }
  return (
    <div className={`${namespace}__map-content`}>
      {map_content.values.map(value => (
        <>
          <Typography component="span">{value.description}</Typography>
          <Typography>
            {value.value && (
              <MoneyAmount
                value={{ fraction: value.value.fraction_value, cents: value.value.cents }}
                centsType="superscript"
                symbol={value.value.currency_symbol}
                size={16}
              />
            )}
          </Typography>
        </>
      ))}
    </div>
  );
};

CbtTaxesSummary.propTypes = {
  map_content: shape({
    values: arrayOf(
      shape({
        description: string,
        value: shape({
          fraction_value: string,
        }),
        color: string,
      }),
    ),
  }),
};

const getAction = (
  action,
  onIframeMessage,
  id,
  onCloseModal,
  meliplusUpsellingOnSuscription,
  meliplusOnDeliveryDayChange,
  noTitle,
) => {
  const isNewVersionModal = stringIncludes(action.target, 'new_version=true');
  const actionTarget = action.target;
  const lowEndUrl = { ...action, target: actionTarget };
  transformActionTarget(lowEndUrl, { modal: false });

  return (
    <>
      {isNewVersionModal ? (
        <ActionModal
          deviceType="mobile"
          label={action.label}
          modalTitle={noTitle ? false : action.modal_title}
          modalClassName={classnames(
            `${namespace}-modal ${action.modal_title ? null : 'modal-close-button-mobile'}`,
            'ui-pdp-iframe-modal',
          )}
          className={`${namespace}__action mt-4`}
          track={action.track}
          closeTrack={action.close_track}
          url={lowEndUrl.target}
          closeModalLabel={action.close_modal_label}
          componentId={id}
          controlModalHeader
          showCloseButton
          onCloseModal={onCloseModal}
          meliplusUpsellingOnSuscription={meliplusUpsellingOnSuscription}
          meliplusOnDeliveryDayChange={meliplusOnDeliveryDayChange}
        >
          <Iframe
            title={action.label ? action.label.text : null}
            src={actionTarget}
            onMessage={onIframeMessage}
            scrolling="no"
            width="100%"
          />
        </ActionModal>
      ) : (
        <div className="ui-pdp-action-modal">
          <a href={actionTarget} target={action.html_target || '_self'}>
            {action.icon && (
              <div className={classnames(`${namespace}__action-icon`, 'ui-pdp-action-modal__icon')}>
                {IconFactory(action.icon, `${namespace}__action-icon`)}
              </div>
            )}
            {action.label.text}
          </a>
        </div>
      )}
    </>
  );
};

const GenericSummary = ({
  action,
  action_tooltip,
  children,
  className,
  icon,
  subtitles,
  title,
  isFetching,
  custom_message,
  updateShippingSummary,
  remaining,
  blackPriceClassName,
  id,
  onCloseModal,
  meliplusUpsellingOnSuscription,
  meliplusOnDeliveryDayChange,
  noTitle,
  map_content,
  banner,
}) => {
  let adaptedSubtitles = null;

  if (subtitles) {
    adaptedSubtitles = subtitles.slice();
  }

  if (remaining && subtitles) {
    adaptSubtitlesToRemainingLogic(remaining, updateShippingSummary, subtitles, adaptedSubtitles);
  }

  const validateCbtTaxes = () => {
    if (map_content) {
      return null;
    }
    return title;
  };

  const cardTooltip = getCoachCardTooltipsProps(action_tooltip);
  const shouldShowCardTooltip = id === 'shipping_summary' && cardTooltip;
  const isImportFeesMobile = title?.values?.info?.is_import_fees_mobile;

  return (
    <>
      <CbtTaxesSummary map_content={map_content} />
      {isImportFeesMobile ? (
        <BottomSheetWithClose
          title={title.values.info.modal_title}
          initialHeight="350px"
          labelText={title.text}
          labelValues={title.values}
          track={title?.values?.info?.track}
        >
          <Iframe src={title.values.info.target} scrolling="no" width="100%" />
        </BottomSheetWithClose>
      ) : (
        <Media
          figure={icon && IconFactory(icon)}
          className={classnames(
            namespace,
            `${namespace}--md`,
            banner && id === 'returns_summary' ? `${namespace}--banner` : '',
            className,
            colornames(title?.color),
            {
              [`${namespace}--remaining`]: remaining,
            },
            { [blackPriceClassName]: blackPriceClassName },
          )}
          title={validateCbtTaxes()}
          subtitles={adaptedSubtitles}
          action={
            action &&
            getAction(
              action,
              onIframeModalResize('vip:modal-content:loaded', 'height', '.ui-pdp-iframe', '.andes-modal__header'),
              id,
              onCloseModal,
              meliplusUpsellingOnSuscription,
              meliplusOnDeliveryDayChange,
              noTitle,
            )
          }
          isFetching={isFetching}
          num_font_size={PriceFontSize.MEDIA_SUBTITLE}
        >
          {children}
        </Media>
      )}
      {shouldShowCardTooltip && <CardTooltipComponentWrapper cardTooltip={cardTooltip} />}
      {custom_message && (
        <Message
          className={`${namespace}__message`}
          {...{
            closable: custom_message.closable,
            hierarchy: custom_message.hierarchy,
            type: custom_message.message_type,
          }}
        >
          <div className={`${namespace}__message__text`}>{custom_message.body.text}</div>
        </Message>
      )}
    </>
  );
};

GenericSummary.propTypes = {
  blackPriceClassName: string,
  action: oneOfType([
    shape({
      label: shape({
        text: string.isRequired,
        color: string,
      }).isRequired,
      target: string.isRequired,
      onClick: func,
    }),
    node,
    func,
  ]),
  action_tooltip: shape({
    id: string.required,
    content: string.required,
    visible: bool.required,
  }),
  children: node,
  className: string,
  icon: shape({
    id: string.isRequired,
    color: string,
  }),
  isFetching: bool,
  remaining: Remaining,
  updateShippingSummary: func,
  subtitles: arrayOf(
    shape({
      text: string,
      color: string,
      values: shape({
        price: shape({
          type: string,
          value: number,
          original_value: number,
          currency_symbol: string,
        }),
      }),
    }),
  ),
  title: shape({}).isRequired,
  custom_message: shape({
    state: string,
    body: shape({ test: string }),
    closeable: bool,
    hierarchy: string,
    message_type: string,
  }),
  id: string.isRequired,
  onCloseModal: func,
  meliplusUpsellingOnSuscription: func,
  meliplusOnDeliveryDayChange: func,
  noTitle: bool,
  map_content: shape({
    values: arrayOf(
      shape({
        description: string,
        value: shape({
          fraction_value: string,
        }),
        color: string,
      }),
    ),
  }),
  banner: shape({}),
};

GenericSummary.defaultProps = {
  blackPriceClassName: null,
  action: null,
  children: null,
  className: null,
  subtitles: null,
  icon: null,
  isFetching: null,
  updateShippingSummary: null,
  custom_message: null,
  remaining: null,
  map_content: null,
};

const enhance = compose(
  setTooltipProps,
  withTooltip,
);

module.exports = React.memo(enhance(GenericSummary));
