const compose = require('../../../../lib/compose');
const connect = require('../../../../store/connect');
const withFeatureFlag = require('../../../../components/feature-flag/with-feature-flag');
const ShippingMessage = require('../../../../components/shipping-message');

const FEATURE_NAME = 'item_status_short_description_message';

const mapStateToProps = ({ components: { [FEATURE_NAME]: props }, ownProps }) => ({ ...props, ...ownProps });

const enhance = compose(
  withFeatureFlag(FEATURE_NAME, { loader: false }),
  connect(mapStateToProps),
);

module.exports = enhance(ShippingMessage);
