const getComponentFromComponents = (id, components) => {
  if (!components) {
    return false;
  }
  if (Object.prototype.hasOwnProperty.call(components, id)) {
    return components[id];
  }
  if (typeof components.find === 'function') {
    return components.find(item => item.id === id);
  }
  return false;
};

const transformRemainingCountdowns = response => {
  const nowDate = Date.now();

  const shippingSummary = getComponentFromComponents('shipping_summary', response.components);
  if (shippingSummary && shippingSummary.remaining) {
    shippingSummary.remaining.initial_time = nowDate;
  }

  const secondShippingSummary = getComponentFromComponents('second_shipping_summary', response.components);
  if (secondShippingSummary && secondShippingSummary.remaining) {
    secondShippingSummary.remaining.initial_time = nowDate;
  }

  const addressShipping = getComponentFromComponents('address_shipping', response.components);
  if (addressShipping && addressShipping.remaining) {
    addressShipping.remaining.initial_time = nowDate;
  }

  const pickupSummary = getComponentFromComponents('pick_up_summary', response.components);
  if (pickupSummary && pickupSummary.remaining) {
    pickupSummary.remaining.initial_time = nowDate;
  }
  return response;
};

module.exports = transformRemainingCountdowns;
