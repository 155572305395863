const React = require('react');

const RecommendationsCarouselFree = require('../../../../../vip/features/recommendations/carousel-free');

const RecommendationsBottomChunk = () => (
  <>
    <RecommendationsCarouselFree featureName="carousel_pads" className="mb-64" />
    <RecommendationsCarouselFree featureName="carousel_bottom" className="mb-64" />
    <RecommendationsCarouselFree featureName="carousel_other_domain" className="mb-64" />
    <RecommendationsCarouselFree featureName="carousel_p2p" className="mb-64" />
  </>
);

module.exports = RecommendationsBottomChunk;
