const React = require('react');
const { useState, useEffect } = require('react');

const { arrayOf, string, shape, number } = require('prop-types');
const classnames = require('classnames');
const Image = require('../image');

const useTrackView = require('../../hooks/use-track-view');
const Wrapper = require('./components/Wrapper');
const { generateKeyToComponent } = require('../../utils/validators');

const namespace = 'ui-pdp-vertical-gallery-collapsible';

const VerticalGalleryCollapsible = ({
  title,
  pictures,
  className,
  viewport_track,
  action,
  max_show_images,
  image_screen_ratio,
}) => {
  const viewRef = useTrackView(viewport_track, { threshold: 0.3 });
  const [containerMaxLen, setContainerMaxLen] = useState('1232px');
  const [picturesHeight, setPicturesHeight] = useState(0);
  const [picturesWidth, setPicturesWidth] = useState(0);
  const isCollapsable = pictures.length > max_show_images;

  useEffect(() => {
    const pictureWidth = window.outerWidth - 40;
    const pictureHeight = pictureWidth;
    const containerLength = pictureHeight * 4 - 48;
    setPicturesHeight(pictureHeight);
    setPicturesWidth(pictureWidth);
    setContainerMaxLen(`${containerLength}px`);
  }, [image_screen_ratio]);

  const onSelect = () => {
    setContainerMaxLen('');
  };

  return (
    <Wrapper isCollapsable={isCollapsable} action={action} onSelect={onSelect} scrollToTop={false}>
      <div ref={viewRef} className={classnames(namespace, className)} id={namespace}>
        <h2 className={`${namespace}__title`}>{title.text}</h2>
        <div className={`${namespace}__container`} style={{ maxHeight: `${containerMaxLen}` }}>
          {pictures.map(picture => {
            const { width, height, thumbnail, zoom, ...restProps } = picture;
            const classNames = classnames({
              [`${namespace}--horizontal`]: width >= height,
              [`${namespace}--vertical`]: height > width,
            });
            return (
              <div
                key={generateKeyToComponent(picture)}
                className={`${namespace}__image`}
                style={{ width: picturesWidth, maxHeight: picturesHeight }}
              >
                <Image alt="" width={picturesWidth} height={picturesHeight} {...restProps} className={classNames} />
              </div>
            );
          })}
        </div>
      </div>
    </Wrapper>
  );
};

VerticalGalleryCollapsible.propTypes = {
  viewport_track: shape({}),
  open_image_track: shape({}),
  action: shape({
    target: string,
    label: shape({
      text: string.isRequired,
      color: string.isRequired,
    }).isRequired,
  }).isRequired,
  max_show_images: number,
  title: shape({
    text: string.isRequired,
    color: string.isRequired,
  }).isRequired,
  pictures: arrayOf(
    shape({
      id: string.isRequired,
      alt: string.isRequired,
      src: string.isRequired,
      src2x: string,
    }),
  ).isRequired,
  className: string,
  image_screen_ratio: number,
};

VerticalGalleryCollapsible.defaultProps = {
  open_image_track: null,
  viewport_track: null,
  className: '',
  max_show_images: 0,
  image_screen_ratio: 0,
};

module.exports = VerticalGalleryCollapsible;
