const React = require('react');

const { arrayOf, string, shape, number, bool } = require('prop-types');
const VerticalGalleryCarousel = require('../vertical-gallery-carousel/vertical-gallery-carousel.mobile');
const VerticalGalleryCollapsible = require('../vertical-gallery-collapsible/vertical-gallery-collapsible.mobile');

const VerticalGallery = ({
  title,
  pictures,
  className,
  viewport_track,
  action,
  open_image_track,
  max_show_images,
  image_screen_ratio,
  is_collapsible,
}) => {
  const showNewCollapsable = is_collapsible === true;

  return (
    <>
      {showNewCollapsable ? (
        <VerticalGalleryCollapsible
          title={title}
          pictures={pictures}
          className={className}
          viewport_track={viewport_track}
          action={action}
          max_show_images={max_show_images}
          image_screen_ratio={image_screen_ratio}
        />
      ) : (
        <VerticalGalleryCarousel
          title={title}
          pictures={pictures}
          image_screen_ratio={image_screen_ratio}
          max_show_images={max_show_images}
          action={action}
          open_image_track={open_image_track}
          className={className}
          viewport_track={viewport_track}
        />
      )}
    </>
  );
};

VerticalGallery.propTypes = {
  viewport_track: shape({}),
  open_image_track: shape({}),
  action: shape({
    target: string,
    label: shape({
      text: string.isRequired,
      color: string.isRequired,
    }).isRequired,
  }).isRequired,
  max_show_images: number,
  title: shape({
    text: string.isRequired,
    color: string.isRequired,
  }).isRequired,
  pictures: arrayOf(
    shape({
      id: string.isRequired,
      alt: string.isRequired,
      src: string.isRequired,
      src2x: string,
    }),
  ).isRequired,
  className: string,
  image_screen_ratio: number,
  is_collapsible: bool,
};

VerticalGallery.defaultProps = {
  open_image_track: null,
  viewport_track: null,
  className: '',
  max_show_images: 0,
  image_screen_ratio: 0,
  is_collapsible: null,
};

module.exports = VerticalGallery;
