const compose = require('../../../../lib/compose');
const connect = require('../../../../store/connect');
const withFeatureFlag = require('../../../../components/feature-flag/with-feature-flag');
const withRow = require('../../../../components/grid/row/with-row');
const Denounce = require('../../../../components/denounce');

const FEATURE_NAME = 'denounce';

const mapStateToProps = ({ components: { [FEATURE_NAME]: denounce } }) => ({ ...denounce });

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  withRow(FEATURE_NAME),
  connect(mapStateToProps),
);

module.exports = enhance(Denounce);
