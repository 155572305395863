const get = require('lodash/get');
const connect = require('../../../../store/connect');
const Metadata = require('../../../../components/metadata');

const FEATURE_NAME = 'metadata';

const mapStateToProps = ({ components }) => {
  const tags = get(components, `${FEATURE_NAME}.tags`, null);
  const title = get(components, `${FEATURE_NAME}.title`, null);
  const url_canonical = get(components, `${FEATURE_NAME}.url_canonical`, null);
  return {
    tags,
    title,
    url_canonical,
  };
};

module.exports = connect(mapStateToProps)(Metadata);
