const { loadable } = require('nordic/lazy');
const compose = require('../../../../lib/compose');
const connect = require('../../../../store/connect');
const withFeatureFlag = require('../../../../components/feature-flag/with-feature-flag');

const Onboarding = loadable(() => import('../../../../components/onboarding/onboarding.mobile'));

const FEATURE_NAME = 'onboarding';

const mapStateToProps = ({ components: { [FEATURE_NAME]: onboarding } }) => ({ ...onboarding });

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(mapStateToProps),
);

module.exports = enhance(Onboarding);
