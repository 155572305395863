const compose = require('../../../../../lib/compose');
const connect = require('../../../../../store/connect');
const withFeatureFlag = require('../../../../../components/feature-flag/with-feature-flag');
const actions = require('../../../../../actions');

const FEATURE_NAME = 'cart_actions';

const mapStateToProps = ({ components: { [FEATURE_NAME]: cartActions } }) => cartActions;

const mapDispatchToProps = dispatch => ({
  onUpdateQuantity: ({ action, quantity, target, callback, onSuccess, onError }) =>
    dispatch(actions.addToCartUpdate({ action, quantity, target, callback, onSuccess, onError })),
  showVariationsError: deviceType => dispatch(actions.showVariationsError(deviceType)),
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

module.exports = CartActions => enhance(CartActions);
