const { CLOSE_MESSAGE } = require('@vpp-frontend-components/action-modal');
const { global: object, trackEvent, targetHostnameValidator } = require('@vpp-frontend-components/common');

const reloadPage = () => setTimeout(() => window.location.reload(true), 0);

const MELIPLUS_SUSCRIPTION_MESSAGE = 'vpp:meliplus:suscription';
const MDD_CHANGE_MESSAGE = 'vpp:mdd:change';

const getActionModalConfig = (props, _, context) => {
  const { dependencies } = context;
  const { HistoryService } = dependencies;

  return {
    onCloseModal: (event, { shouldRefresh }) => {
      const { onCloseModal, modalUrl } = props;

      onCloseModal?.(event);

      if (shouldRefresh) {
        reloadPage();
      }

      if (modalUrl && event?.data !== CLOSE_MESSAGE) {
        HistoryService.back();
      }
    },
    onOpenModal: () => {
      const { track } = props;
      trackEvent(track);
    },
    redirectToTargetHandler: target => {
      if (target && targetHostnameValidator({ target, currentLocation: object.location })) {
        object.location = target;
      }
    },
    customEventMessageHandler: event => {
      const { meliplusUpsellingOnSuscription, meliplusOnDeliveryDayChange } = props;

      const { data } = event;
      const { message } = data;

      // meliplus & MDD
      if (message) {
        switch (message) {
          case MELIPLUS_SUSCRIPTION_MESSAGE:
            meliplusUpsellingOnSuscription?.();

            break;

          case MDD_CHANGE_MESSAGE:
            meliplusOnDeliveryDayChange?.();

            break;

          default:
            break;
        }
      }
    },
  };
};

module.exports = { getActionModalConfig };
