const CardWithThumbnailsComponent = require('@xprod-library/card-with-thumbnails-component').default;
const { string, shape, arrayOf, oneOf, number, func } = require('prop-types');

const CardWithThumbnails = ({ url_web, title_size, thumbnails_container, title, on_click, variant, className }) => (
  <div className={className}>
    <CardWithThumbnailsComponent
      onClick={on_click}
      thumbnailContainer={{
        assets: thumbnails_container.assets,
        overflow: thumbnails_container.overflow,
        type: thumbnails_container.type?.toLowerCase() || 'stacked',
        size: thumbnails_container.size_web,
      }}
      link={{ href: url_web }}
      title={title}
      titleSize={title_size}
      variant={variant}
    />
  </div>
);

module.exports = CardWithThumbnails;

CardWithThumbnails.propTypes = {
  thumbnails_container: shape({
    assets: arrayOf(
      shape({
        id: number.isRequired,
        src: string.isRequired,
        alt: string.isRequired,
      }),
    ).isRequired,
    type: oneOf(['stacked', 'grid']),
    modifier: oneOf(['circle', 'square']),
    overflow: number,
    size_web: oneOf(['24', '32', '40', '48', '56', '64', '72', '80']),
  }).isRequired,
  url_web: string.isRequired,
  title: string,
  on_click: func,
  title_size: oneOf(['xs', 's', 'm', 'l', 'xl']),
  variant: oneOf(['tiny', 'normal']),
  className: string,
};
CardWithThumbnails.defaultProps = {
  title: '',
  on_click: null,
  title_size: 's',
  variant: 'tiny',
};
