const { loadable } = require('nordic/lazy');
const compose = require('../../../../lib/compose');
const connect = require('../../../../store/connect');
const withFeatureFlag = require('../../../../components/feature-flag/with-feature-flag');
const actions = require('../../../../actions');

const OtherSellers = loadable(() => import('../../../../components/other-sellers'));

const FEATURE_NAME = 'bbw_alternatives';

const mapStateToProps = ({ components: { [FEATURE_NAME]: other_sellers }, parentUrl }, ownProps) => ({
  ...ownProps,
  ...other_sellers,
  parentUrl,
});
const mapDispatchToProps = dispatch => ({
  showAddToCartModal: ({ deviceType, itemId, labelText }) => {
    dispatch(actions.showAddToCartModal({ deviceType, itemId, labelText }));
  },
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

module.exports = enhance(OtherSellers);
