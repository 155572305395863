const React = require('react');
const { string, arrayOf, shape } = require('prop-types');
const Head = require('nordic/head');

const getMetaValidId = obj => obj.property || obj.name || (obj.content && obj.content.substring(0, 10));

const Metadata = ({ url_canonical, tags, title, alternate_android, alternate_ios }) => (
  <Head>
    {tags &&
      tags.map(tag => {
        if (tag.content) {
          tag.content = tag.content.replace(/\r?\n|\r/g, '');
          tag.content = tag.content.replace(/&/g, '&amp;');
          tag.content = tag.content.replace(/</g, '&lt;');
          tag.content = tag.content.replace(/"/g, '&quot;');
          tag.content = tag.content.replace(/'/g, '&#039;');
          tag.content = tag.content.replace(/>/g, '&gt;');
        }
        return <meta key={getMetaValidId(tag)} {...tag} />;
      })}

    {title && <title>{title}</title>}
    {url_canonical !== '' && url_canonical && <link rel="canonical" href={url_canonical} />}
    {alternate_ios !== '' && alternate_ios && <link rel="alternate" href={alternate_ios} />}
    {alternate_android !== '' && alternate_android && <link rel="alternate" href={alternate_android} />}
  </Head>
);

const tagShape = shape({
  property: string,
  name: string,
  content: string,
  value: string,
});

Metadata.propTypes = {
  tags: arrayOf(tagShape),
  title: string,
  url_canonical: string,
  alternate_android: string,
  alternate_ios: string,
};

Metadata.defaultProps = {
  url_canonical: null,
  title: null,
  tags: [],
  alternate_android: null,
  alternate_ios: null,
};

module.exports = React.memo(Metadata);
