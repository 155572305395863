const getAttributesParam = (attributes, key, value) => {
  let attrs = {};

  if (attributes) {
    attrs = attributes;
    if (key && value) {
      // The last attribute selected must be the latest in the param
      delete attrs[key];
    }
  }

  if (key && value) {
    attrs[key] = value;
  }

  const attributesKeys = Object.keys(attrs).filter(attrKey => attrs[attrKey] !== undefined);
  if (attributesKeys.length > 0) {
    return attributesKeys.map(k => `${k}:${attrs[k]}`).join(',');
  }

  return null;
};

module.exports = getAttributesParam;
