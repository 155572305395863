const compose = require('../../../../lib/compose');
const connect = require('../../../../store/connect');
const withFeatureFlag = require('../../../../components/feature-flag/with-feature-flag');
const Promotions = require('../../../../components/promotions');

const FEATURE_NAME = 'fulfillment_information';

const mapStateToProps = (
  {
    components: {
      [FEATURE_NAME]: { tag_action, tooltip, description_action, labels, type },
    },
  },
  ownProps,
) => ({
  tag_action,
  tooltip,
  description_action,
  labels,
  type,
  ...ownProps,
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(mapStateToProps),
);

module.exports = enhance(Promotions);
