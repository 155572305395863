const React = require('react');
const Grid = require('../../../../../../components/grid');

const VerticalGallery = require('../../../../features/vertical-gallery/vertical-gallery.mobile');

const VerticalGalleryChunk = () => (
  <Grid.Row className="ui-pdp-with--separator">
    <VerticalGallery className="pl-16 pr-16" />
  </Grid.Row>
);

module.exports = VerticalGalleryChunk;
