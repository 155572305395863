const compose = require('../../../../lib/compose');
const connect = require('../../../../store/connect');
const withFeatureFlag = require('../../../../components/feature-flag/with-feature-flag');

const FEATURE_NAME = 'header_top_brand';

const mapStateToProps = ({ components: { [FEATURE_NAME]: header_top_brand } }, ownProps) => ({
  ...header_top_brand,
  ...ownProps,
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(mapStateToProps),
);

module.exports = Component => enhance(Component);
