const { useEffect } = require('react');
const { shape } = require('prop-types');

const { trackPage } = require('../../lib/tracking');

const TrackPage = ({ melidata_event, analytics_event }) => {
  useEffect(() => {
    trackPage({ melidata_event, analytics_event });
  }, [melidata_event, analytics_event]);
  return null;
};

TrackPage.propTypes = {
  analytics_event: shape({}),
  melidata_event: shape({}),
};

TrackPage.defaultProps = {
  analytics_event: null,
  melidata_event: null,
};

module.exports = TrackPage;
