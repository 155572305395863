const React = require('react');
const { string, shape, node } = require('prop-types');
const { useState } = require('react');

const { StyledLabel } = require('@vpp-frontend-components/styled-label');
const BottomSheet = require('@andes/bottom-sheet');
const { Button } = require('@andes/button');
const CloseIcon = require('../icons/close');

const componentEnhance = require('../../lib/component-enhance');
const { trackEvent } = require('../../lib/tracking');

const namespace = 'ui-pdp-generic-summary';

const BottomSheetWithClose = ({ title, initialHeight, labelText, labelValues, nameKey, children, track }) => {
  const [openBottomSheet, setOpenBottomSheet] = useState(false);
  const values = {
    ...labelValues,
    [nameKey]: {
      ...labelValues[nameKey],
      as: 'a',
      onClick: () => setOpenBottomSheet(true),
    },
  };

  return (
    <>
      <div className={`${namespace}__import-fees`} data-testid="import-fees">
        <StyledLabel text={componentEnhance.jsx(labelText, values)} />
      </div>
      {openBottomSheet && (
        <BottomSheet
          data-testid="bottom-sheet"
          isOpen={openBottomSheet}
          onClose={() => setOpenBottomSheet(false)}
          initialHeight={initialHeight}
          title={title}
          onOpen={() => trackEvent(track)}
        >
          <Button
            data-testid="bs-close-button"
            className={`${namespace}__bs-close-button`}
            hierarchy="transparent"
            onClick={() => setOpenBottomSheet(false)}
          >
            <CloseIcon className="ui-pdp-color--WHITE" />
          </Button>
          {children}
        </BottomSheet>
      )}
    </>
  );
};

BottomSheetWithClose.propTypes = {
  title: string.isRequired,
  initialHeight: string.isRequired,
  labelText: string.isRequired,
  labelValues: shape({}),
  nameKey: string,
  children: node.isRequired,
  track: shape({}),
};

BottomSheetWithClose.defaultProps = {
  initialHeight: 'auto',
  labelValues: null,
  nameKey: 'info',
  track: null,
};

module.exports = BottomSheetWithClose;
