const compose = require('../../../../lib/compose');
const connect = require('../../../../store/connect');
const withFeatureFlag = require('../../../../components/feature-flag/with-feature-flag');
const jsonToProps = require('../../../../components/seller-data/json-to-props');
const actions = require('../../../../actions');
const SellerData = require('../../../../components/seller-data');

const FEATURE_NAME = 'seller_data';

const mapStateToProps = (
  { components: { [FEATURE_NAME]: sellerData }, recommendations_context, ...globalContext },
  ownProps,
) => jsonToProps(sellerData, recommendations_context, globalContext, ownProps);

const mapDispatchToProps = dispatch => ({
  onFollowClick: () => dispatch(actions.toggleFollowSeller()),
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

module.exports = enhance(SellerData);
exports = module.exports;
exports.mapDispatchToProps = mapDispatchToProps;
exports.mapStateToProps = mapStateToProps;
