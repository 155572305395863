const {
  canUseDOM,
  supportsHistory,
  getLocationForHash,
  supportsWebShareApi,
  forEach,
} = require('@vpp-frontend-components/common');

module.exports = {
  canUseDOM,
  supportsHistory,
  getLocationForHash,
  supportsWebShareApi,
  forEach,
};
