const React = require('react');
const { shape, node } = require('prop-types');
const { VppProvider } = require('./vpp-context');

const StaticPropsContext = React.createContext();
const { Provider, Consumer: StaticPropsConsumer } = StaticPropsContext;

const StaticPropsProvider = ({ children, value, icons }) => (
  <VppProvider icons={icons} staticProps={value}>
    <Provider value={value}>{children}</Provider>
  </VppProvider>
);

StaticPropsProvider.propTypes = {
  children: node.isRequired,
  value: shape({}),
  icons: shape({}),
};

module.exports = StaticPropsContext;
exports = module.exports;
exports.StaticPropsProvider = StaticPropsProvider;
exports.StaticPropsConsumer = StaticPropsConsumer;
