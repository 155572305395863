const React = require('react');
const Grid = require('../../../../../../components/grid');

const WarningMessage = require('../../../../features/warning-message');
const PromotionTop = require('../../../../features/promotion-top');

const WarningPromotionsChunk = () => (
  <>
    <Grid.Row>
      <Grid.Col className="ui-pdp-with--separator ui-pdp-with--separator--medium-top">
        <WarningMessage className="mt-24 mb-24 ml-16 mr-16" />
      </Grid.Col>
    </Grid.Row>
    <Grid.Row>
      <PromotionTop className="ml-16 mt-24" />
    </Grid.Row>
  </>
);

module.exports = WarningPromotionsChunk;
