const {
  getFormattedPictures,
  getFormattedBookmarks,
  getFormattedShare,
  getGalleryType,
  getMultimedia,
  getClipsConfig,
  getFormattedClips,
  getFormattedVideos,
  getFigures,
  MULTIMEDIA_CONFIG,
} = require('./helpers/mapper');

const jsonToProps = ({
  default_selected,
  bookmark,
  share,
  show_share,
  highlighted_multimedia,
  multimediaConfigRaw,
  titleForAlt,
  wishlist,
  pictures: picturesRaw,
  picture_config,
  clips: clipsRaw,
  videos: videosRaw,
  gallery_type,
  id,
  csrfToken,
  accessibility_text,
  size_for_photo,
  max_height,
  min_height,
  overlay_alpha,
  ...rest
}) => {
  const pictures = getFormattedPictures({
    pictures: picturesRaw,
    titleForAlt,
    pictureConfig: picture_config,
  });
  const clips = getFormattedClips(clipsRaw);
  const clipsConfig = getClipsConfig(clipsRaw);
  const videos = getFormattedVideos(videosRaw);
  const multimedia = getMultimedia(highlighted_multimedia);
  const multimediaConfig = highlighted_multimedia || videos ? MULTIMEDIA_CONFIG : null;

  const figures = getFigures({
    multimedia,
    pictures,
    clips,
    videos,
    config: { multimedia: highlighted_multimedia },
  });

  return {
    id,
    share: getFormattedShare({ share, show_share }),
    bookmark: getFormattedBookmarks({ bookmark, id, csrfToken, wishlist }),
    galleryType: getGalleryType({ gallery_type, pictures }),
    accessibility_text,
    sizeForBiggerPhoto: size_for_photo,
    defaultSelected: default_selected,
    maxHeight: max_height,
    minHeight: min_height,
    figures,
    highlightedMultimedia: multimedia[0],
    clipsConfig,
    multimediaConfig,
    overlayAlpha: overlay_alpha,
    ...rest,
  };
};

module.exports = jsonToProps;
