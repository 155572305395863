const compose = require('../../../../../lib/compose');
const connect = require('../../../../../store/connect');
const withFeatureFlag = require('../../../../../components/feature-flag/with-feature-flag');
const ShippingSummary = require('../../../../../components/buybox/shipping');
const actions = require('../../../../../actions');

const FEATURE_NAME = 'upselling_meliplus_summary';

const UPSELLING_MELIPLUS_PROPS = {
  noTitle: true,
  id: FEATURE_NAME,
};

const mapStateToProps = ({ components: { [FEATURE_NAME]: meliplusSummary } }, ownProps) => ({
  ...meliplusSummary,
  ...ownProps,
  ...UPSELLING_MELIPLUS_PROPS,
});

const serializeOwnProps = (state, ownProps) => ({
  ...ownProps,
  ...UPSELLING_MELIPLUS_PROPS,
});

const mapDispatchToProps = dispatch => ({
  onCloseModal: () => {
    dispatch(actions.fetchProductOnMeliplusUpdate('close_modal'));
  },
  meliplusUpsellingOnSuscription: () => {
    dispatch(actions.fetchProductOnMeliplusUpdate('suscription'));
  },
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

const noFeatureFlagConnect = connect(
  serializeOwnProps,
  mapDispatchToProps,
);

module.exports = enhance(ShippingSummary);
module.exports.noFeatureFlag = noFeatureFlagConnect(ShippingSummary);
