const mapJsonToProps = ({
  seller_status_info: sellerStatusInfo,
  power_seller_status: powerSellerStatus,
  thermometer,
  title,
  subtitles,
  logo,
  extra_info,
  title_label,
  state,
  header,
  action,
  official_store_icon,
  is_exclusive_official_store: isExclusiveOfficialStore,
  viewport_track,
}) => {
  const { rank, default_info: defaultInfo, info } = thermometer || {};

  return {
    sellerStatusInfo,
    powerSellerStatus,
    ...(thermometer ? { thermometer: { rank, defaultInfo, info } } : null),
    title: title || title_label,
    subtitles,
    logo,
    extra_info,
    showLabelDto: !title,
    state,
    header,
    action,
    icon: official_store_icon,
    isExclusiveOfficialStore,
    viewport_track,
  };
};

module.exports = mapJsonToProps;
