const compose = require('../../../../lib/compose');
const connect = require('../../../../store/connect');
const withFeatureFlag = require('../../../../components/feature-flag/with-feature-flag');
const Price = require('../../../../components/price');
const actions = require('../../../../actions');

const FEATURE_NAME = 'price';

const mapStateToProps = ({ components: { [FEATURE_NAME]: price } }, ownProps) => {
  const {
    price: {
      currency_symbol: currencySymbol,
      original_value: originalValue,
      value,
      price_reference: priceReference,
      range_price: rangePrice,
    },
    discount_label: discountLabel,
    ...restPrice
  } = price;

  return {
    currencySymbol,
    originalValue,
    discountLabel,
    priceReference,
    value,
    rangePrice,
    ...ownProps,
    ...restPrice,
  };
};

const mapDispatchToProps = dispatch => ({
  showPaymentsSplitModal: ({ target, deviceType }) => {
    dispatch(actions.showPaymentsSplitModal({ target, deviceType }));
  },
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

module.exports = enhance(Price);
