const compose = require('../../../../lib/compose');
const connect = require('../../../../store/connect');
const withFeatureFlag = require('../../../../components/feature-flag/with-feature-flag');

const FEATURE_NAME = 'stock_information';

const mapStateToProps = (
  {
    components: {
      [FEATURE_NAME]: { collapsible_tooltip, title, subtitle },
    },
  },
  ownProps,
) => ({
  collapsible_tooltip,
  title,
  subtitle,
  ...ownProps,
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(mapStateToProps),
);

module.exports = StockInformation => enhance(StockInformation);
