const get = require('lodash/get');

const getEmbeddedData = initialState => {
  const user_id = get(initialState, 'components.track.gtm_event.buyerId', undefined);
  const loyalty_level = get(initialState, 'components.track.gtm_event.loyaltyLevel', undefined);
  const category_id = get(initialState, 'components.track.gtm_event.categoryId', undefined);
  const actions = get(initialState, 'components.main_actions.actions', []);
  const action = actions.find(act => {
    const actionFlowType = get(act, 'track.melidata_event.event_data.flow_type');
    return typeof actionFlowType !== 'undefined' && actionFlowType !== '';
  });
  const flow_type = get(action, 'track.melidata_event.event_data.flow_type');

  return {
    user_id: user_id && user_id.toString(),
    is_identifier: !!user_id,
    loyalty_level: loyalty_level !== undefined ? loyalty_level.toString() : undefined,
    category_id,
    flow_type,
  };
};

module.exports = {
  getEmbeddedData,
};
