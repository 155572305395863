const { loadable } = require('nordic/lazy');
const compose = require('../../../../lib/compose');
const connect = require('../../../../store/connect');
const withFeatureFlag = require('../../../../components/feature-flag/with-feature-flag');
const mapStateToProps = require('../../../../components/recommendations/state-mapper-base');

const RecommendationsCarouselFree = loadable(() => import('../../../../components/recommendations/carousel-free'));

const enhance = compose(
  withFeatureFlag(),
  connect(mapStateToProps()),
);

module.exports = enhance(RecommendationsCarouselFree);
