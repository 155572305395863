const React = require('react');
const { string, oneOf } = require('prop-types');
const classnames = require('classnames');
const Message = require('../message');
const { validateSnackbarColor } = require('../../utils/validators');

const namespace = 'ui-pdp-flash-message';

const FlashMessage = ({ type, text, className }) => (
  <Message className={classnames(namespace, className)} color={validateSnackbarColor(type)} closable hierarchy="loud">
    <div className={`${namespace}__text`}>{text}</div>
  </Message>
);

FlashMessage.propTypes = {
  className: string,
  text: string.isRequired,
  type: oneOf(['neutral', 'success', 'warning', 'error']),
};

FlashMessage.defaultProps = {
  className: null,
  type: null,
};

module.exports = FlashMessage;
