const React = require('react');
const Grid = require('../../../../../../components/grid');

const OnePayForAll = require('../../../../features/one-pay-for-all/modal/modal');

const OnePayForAllChunk = () => (
  <Grid.Row>
    <OnePayForAll className="pt-24" />
  </Grid.Row>
);

module.exports = OnePayForAllChunk;
