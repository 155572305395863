const DEFAULT_LIGHTBOX_SETTINGS = {
  thumbnailClassname: '.slick-slide',
  modal: true,
  arrowEl: false,
  bgOpacity: 0.95,
  barsSize: {
    top: 0,
    bottom: 0,
  },
};

module.exports = DEFAULT_LIGHTBOX_SETTINGS;
