const React = require('react');
const { node, shape, string } = require('prop-types');
const { useRef, useState } = require('react');
const classnames = require('classnames');
const Button = require('./components/button');

const namespace = 'ui-pdp-collapsable';

const ActionCollapsableCss = ({ action, children, className }) => {
  const ref = useRef();
  const [isCollapsed, setCollapsedState] = useState(true);

  const onClick = e => {
    e.preventDefault();
    setCollapsedState(!isCollapsed);
    ref.current.scrollIntoView();
  };

  return (
    <div className={classnames(namespace, className, { [`${namespace}--is-collapsed`]: isCollapsed })}>
      <div
        className={classnames(`${namespace}__container`, { [`${namespace}__container-items-hidden`]: isCollapsed })}
        ref={ref}
      >
        {children}
      </div>
      {isCollapsed ? <Button action={action} namespace={namespace} onClick={onClick} /> : null}
    </div>
  );
};

ActionCollapsableCss.propTypes = {
  action: shape({
    label: shape({
      text: string.isRequired,
      color: string,
    }).isRequired,
    target: string,
  }).isRequired,
  children: node,
  className: string,
};

ActionCollapsableCss.defaultProps = {
  children: null,
  className: null,
};

module.exports = ActionCollapsableCss;
