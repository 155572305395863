const compose = require('../../../../lib/compose');
const connect = require('../../../../store/connect');
const withFeatureFlag = require('../../../../components/feature-flag/with-feature-flag');
const VerticalGalleryMobile = require('../../../../components/vertical-gallery/vertical-gallery.mobile');
const jsonToProps = require('../../../../components/vertical-gallery/json-to-props');

const FEATURE_NAME = 'vertical_gallery';

const mapStateToProps = ({ id, components: { [FEATURE_NAME]: vertical_gallery } }, { className }) => ({
  ...jsonToProps({ ...vertical_gallery }),
  id,
  className,
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(mapStateToProps),
);

module.exports = enhance(VerticalGalleryMobile);
