const React = require('react');
const { shape } = require('prop-types');
const classNames = require('classnames');

const Grid = require('../../../../../../components/grid');

const Questions = require('../../../../features/questions/questions.mobile');
const QADB = require('../../../../features/qadb');
const ProductReviews = require('../../../../features/reviews/reviews.mobile');
const ReviewsCapability = require('../../../../../../components/reviews-capability/reviews.mobile');

// Utils
const { isReviewsRowVisible } = require('../../../../../../utils/component-visibility');

// eslint-disable-next-line react/no-object-type-as-default-prop
const QuestionsChunk = ({ components = {} }) => {
  const { reviews, reviews_capability_v3 } = components;

  const hasReviewsRow = isReviewsRowVisible({ reviews, reviews_capability_v3 });
  const questionsContainerClassName = classNames(
    { 'ui-pdp-with--separator': hasReviewsRow },
    { 'mb-40': !hasReviewsRow },
  );

  return (
    <>
      <Grid.Row className={questionsContainerClassName}>
        <Questions className="pl-16 pr-16" />
        <QADB className="pl-16 pr-16" />
      </Grid.Row>
      {reviews && (
        <Grid.Row>
          <ProductReviews />
        </Grid.Row>
      )}
      {reviews_capability_v3 && <ReviewsCapability />}
    </>
  );
};

QuestionsChunk.propTypes = {
  components: shape({}).isRequired,
};

module.exports = QuestionsChunk;
