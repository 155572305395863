const compose = require('../../../../../lib/compose');
const connect = require('../../../../../store/connect');
const withFeatureFlag = require('../../../../../components/feature-flag/with-feature-flag');

const FEATURE_NAME = 'payment_summary';

const mapStateToProps = ({ components: { [FEATURE_NAME]: paymentSummary } }, { className }) => ({
  ...paymentSummary,
  className,
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(mapStateToProps),
);

module.exports = Component => enhance(Component);
