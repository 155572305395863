const React = require('react');
const { bool, shape, string, func } = require('prop-types');
const { ActionCollapsableCssMobile } = require('../../action-collapsable');

const Wrapper = ({ isCollapsable, children, action, onSelect }) => {
  if (isCollapsable) {
    return (
      <ActionCollapsableCssMobile action={action} onSelect={onSelect} scrollToTop={false}>
        {children}
      </ActionCollapsableCssMobile>
    );
  }
  return <>{children}</>;
};

Wrapper.propTypes = {
  isCollapsable: bool,
  onSelect: func,
  children: func,
  action: shape({
    label: shape({
      text: string.isRequired,
      color: string.isRequired,
    }).isRequired,
  }).isRequired,
};

Wrapper.defaultProps = {
  isCollapsable: false,
  onSelect: null,
  children: null,
};

module.exports = Wrapper;
