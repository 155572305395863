const compose = require('../../../../lib/compose');
const connect = require('../../../../store/connect');
const Snackbar = require('../../../../components/snackbar-general');
const actions = require('../../../../actions');
const withFeatureFlag = require('../../../../components/feature-flag/with-feature-flag');

const FEATURE_NAME = 'snackbar';

const mapStateToProps = ({ components: { [FEATURE_NAME]: snackbarProps } }) => ({ ...snackbarProps });

const mapDispatchToProps = dispatch => ({
  hide: () => {
    dispatch(actions.hideSnackbar());
  },
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

module.exports = enhance(Snackbar);
