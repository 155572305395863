const React = require('react');
const { bool } = require('prop-types');

const Grid = require('../../../../../../components/grid');
const GroupedShareBookmark = require('../../../../features/grouped-share-bookmark');
const GiftRegistry = require('../../../../../vip/features/gift-registry');
const OtherSellersNewItems = require('../../../../features/other-sellers-new-items');
const OtherSellersUsedRefurbished = require('../../../../features/other-sellers-used-refurbished');

const MerchEcosystem = require('../../../../features/merch-ecosystem');

const AboveRecommendationsChunk = ({ fromMShops }) => (
  <>
    <Grid.Row>
      {!fromMShops && (
        <Grid.Col
          className=" ui-pdp-with--separator ui-pdp-with--separator--medium-top mt-20"
          id="GroupedShareBookmark"
        >
          <GiftRegistry className="mt-24" />
          <GroupedShareBookmark showOnlyForWishlistVariant />
        </Grid.Col>
      )}
    </Grid.Row>
    <Grid.Row className="ui-pdp-container__col--text-center ui-pdp-with--separator ui-pdp-with--separator--medium-top">
      <OtherSellersNewItems className="mt-24" />
      <OtherSellersUsedRefurbished className="mt-24" />
    </Grid.Row>
    <Grid.Row className="ui-pdp-with--separator">
      <MerchEcosystem />
    </Grid.Row>
  </>
);

AboveRecommendationsChunk.propTypes = {
  fromMShops: bool,
};

AboveRecommendationsChunk.defaultProps = {
  fromMShops: false,
};

module.exports = AboveRecommendationsChunk;
