const jsonToProps = require('./json-to-props');

const mapStateToProps = featureName => ({ components, recommendations_context, ...globalContext }, ownProps) => {
  const name = featureName || ownProps.featureName;
  const { [name]: feature } = components;

  return {
    ...jsonToProps(feature, recommendations_context, globalContext),
    ...ownProps,
  };
};

module.exports = mapStateToProps;
